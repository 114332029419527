import React, { useState, useRef } from "react";
import { Button, ButtonStyleVariations } from "../Button";
import { InitialStep, DetailStep, TermsStep } from ".";
import { Listing } from "../../services/graphql-custom-types";
import {
  CreateOrEditListingInput,
  ListingsDocument,
  Operation,
  useCreateOrEditListingMutation,
  useDeleteListingMutation,
} from "../../graphql/generated-types";

enum Steps {
  InitialStep,
  DetailStep,
  TermsStep,
  Success,
}

function CreateOrEditListing({
  listingToEdit = null,
  operation,
  callback = undefined,
  handleClose = undefined,
}: {
  listingToEdit?: Listing;
  operation: Operation;
  callback?: (listing) => void;
  handleClose?: any;
}) {
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [listing, setListing] = useState<CreateOrEditListingInput>({
    operationId: listingToEdit ? null : operation.id, // Server will throw an error if we pass an opId when editing a listing
    id: listingToEdit?.id || null,
    title: listingToEdit?.title || "",
    description: listingToEdit?.description || "",
    minQty: listingToEdit?.minQty?.toString() || "",
    expirationPeriod: listingToEdit?.expirationPeriod || 20160,
    availability: listingToEdit?.availability || "consumer",
  });

  const [createListing, { loading }] = useCreateOrEditListingMutation({
    onCompleted: (data) => {
      const listing = data.createOrEditListing;
      callback && callback(listing);
    },
    refetchQueries: [
      {
        query: ListingsDocument,
        variables: { operationId: operation.id },
      },
    ],
  });

  const [deleteListing] = useDeleteListingMutation({
    onCompleted: () => {
      handleClose();
      location.reload(); // Hack until we're able to reload parent components when graphQl is updated
    },
    refetchQueries: [
      {
        query: ListingsDocument,
        variables: { operationId: operation.id },
      },
    ],
  });

  const handleDeleteListing = () => {
    deleteListing({
      variables: { id: listingToEdit.id },
    });
  };

  const renderConfirmDeleteListing = () => {
    return (
      <div className="confirm-delete-listing modal__controls">
        <p className="interface-text--body">
          Are you sure you want to delete this listing?
        </p>
        <Button
          name="confirm-modal-alert"
          label="Cancel"
          styleVariation={ButtonStyleVariations.SECONDARY}
          onClick={() => setShowModalAlert(!showModalAlert)}
        />
        <Button
          name="confirm-modal-alert"
          label="Delete"
          styleVariation={ButtonStyleVariations.DESTROY}
          onClick={handleDeleteListing}
        />
      </div>
    );
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newListing = {
      ...listing,
      [name]: value,
    };
    setListing(newListing);
  };

  const formRef = useRef(null);

  // ignore form submit, see validateForm
  const handleFormSubmit = (e) => {
    e.preventDefault();
    return false;
  };

  // call HTML5 validations on form elements
  const validateForm = () => {
    return formRef.current.reportValidity();
  };

  const [currentStep, setCurrentStep] = useState<Steps>(Steps.InitialStep);

  const handleBack = () => {
    if (currentStep == Steps.DetailStep) {
      setCurrentStep(Steps.InitialStep);
    } else if (currentStep == Steps.TermsStep) {
      setCurrentStep(Steps.DetailStep);
    }
  };

  const handleNext = () => {
    if (!validateForm()) {
      return false;
    }
    if (currentStep == Steps.InitialStep) {
      setCurrentStep(Steps.DetailStep);
    } else if (currentStep == Steps.DetailStep) {
      setCurrentStep(Steps.TermsStep);
    } else if (currentStep == Steps.TermsStep) {
      // Expiration period data
      // If the value was changed with chip select (converts it to an object)
      // Then convert the object's key value from weeks to minutes
      // Otherwise use the value (raw number)
      const derivedExpirationPeriod =
        Object.keys(listing.expirationPeriod)?.length > 0
          ? parseInt(Object.keys(listing.expirationPeriod)[0], 10) * 10080
          : listing.expirationPeriod;
      // Availability data
      // If the value was changed with chip select (converts it to an object)
      // Then convert the object's key values to a string
      // Otherwise use the string value
      const derivedAvailability =
        typeof listing.availability === "string"
          ? listing.availability
          : Object.keys(listing.availability).toString();
      // MinQty data requires a number, while HTML number input values are still strings
      // So if our input value is a string we convert it, otherwise pass the number
      // And if it's an empty string we need to convert it to null
      // const derivedMinQty =
      //   listing.minQty && typeof listing.minQty === "string"
      //     ? parseInt(listing.minQty, 10)
      //     : listing.minQty === ""
      //     ? 0
      //     : listing.minQty;

      const saveListing: CreateOrEditListingInput = {
        id: listing.id,
        title: listing.title,
        description: listing.description,
        operationId: listing.operationId,
        availability: derivedAvailability,
        minQty: listing.minQty,
        expirationPeriod: derivedExpirationPeriod,
      };
      createListing({
        variables: saveListing,
      });
    }
  };

  const modalTitle = listingToEdit ? "Edit listing" : "Create listing";
  let nextButtonLabel = "next";
  if (currentStep == Steps.TermsStep) {
    nextButtonLabel = listingToEdit ? "Save" : "Create";
  }

  if (currentStep == Steps.Success) {
    return <h1 className="interface-text--heading">Success!</h1>;
  }

  return (
    <>
      <h1 id="dialog-title" className="interface-text--heading">
        {modalTitle}
      </h1>
      <form ref={formRef} onSubmit={handleFormSubmit}>
        {currentStep == Steps.InitialStep && (
          <InitialStep
            operation={operation}
            listing={listing}
            handleChange={handleChange}
          />
        )}
        {currentStep == Steps.DetailStep && (
          <DetailStep listing={listing} handleChange={handleChange} />
        )}
        {currentStep == Steps.TermsStep && (
          <TermsStep listing={listing} handleChange={handleChange} />
        )}
      </form>
      {showModalAlert ? (
        renderConfirmDeleteListing()
      ) : (
        <div className="create-listing__buttons modal__controls">
          {listingToEdit && (
            <Button
              name="delete-listing"
              label="Delete listing"
              className="modal__controls-force-left"
              styleVariation={ButtonStyleVariations.DESTROY_TEXT}
              onClick={setShowModalAlert}
            />
          )}
          {currentStep != Steps.InitialStep && (
            <Button
              name="back"
              label="Back"
              styleVariation={ButtonStyleVariations.SECONDARY}
              onClick={handleBack}
            />
          )}
          <Button
            name="next"
            label={nextButtonLabel}
            styleVariation={ButtonStyleVariations.PRIMARY}
            onClick={handleNext}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
}

export { CreateOrEditListing };
