import React from "react";
import { FormattedMessage } from "react-intl";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "./Pagination";
import { SearchResultSection } from "./SearchResultSection";
import { NoResultSection } from "./NoResultSection";

export type ListingType = "wanted" | "for-sale" | null;

interface SearchResult {
  operationsData: any;
  operationsHandlersData: any;
  listingsData: any;
  asksData: any;
  query: string;
  viewMode: "grid" | "list";
}

function SearchResult({
  operationsData,
  operationsHandlersData,
  listingsData,
  asksData,
  query,
  viewMode,
}: SearchResult) {
  const [searchParams] = useSearchParams();
  const showType = searchParams.getAll("showType");

  if (!showType.length) {
    return (
      <div className="text-primary-900 font-manrope">Please select at least one filter!</div>
    );
  }

  return (
    <div className="flex flex-col gap-10 font-manrope text-primary-900">
      {showType.includes("certified-organic-farms-handlers") && (
        <SearchResultSection
          title={
            <FormattedMessage
              id="search.operations.results.title.certified_organic_farms_handlers"
              values={{
                count: operationsData?.fulltextSearchOperations?.totalResults,
                query,
              }}
            />
          }
          viewAll={`/search/${query}?showType=certified-organic-farms-handlers`}
          data={operationsData?.fulltextSearchOperations?.operations}
          query={query}
          viewMode={viewMode}
          type="operations"
        />
      )}

      {showType.includes("certified-organic-handlers") && (
        <SearchResultSection
          title={
            <FormattedMessage
              id="search.operations.results.title.certified_organic_handlers"
              values={{
                count:
                  operationsHandlersData?.fulltextSearchOperations
                    ?.totalResults,
                query,
              }}
            />
          }
          viewAll={`/search/${query}?showType=certified-organic-handlers`}
          data={operationsHandlersData?.fulltextSearchOperations?.operations}
          query={query}
          viewMode={viewMode}
          type="operations"
        />
      )}

      {showType.includes("for-sale") && (
        <div>
          {listingsData?.fulltextSearchListings?.totalResults > 0 ? (
            <SearchResultSection
              title={
                <FormattedMessage
                  id="search.operations.results.title.for_sale"
                  values={{
                    count: listingsData?.fulltextSearchListings?.totalResults,
                    query,
                  }}
                />
              }
              viewAll={`/search/${query}?showType=for-sale`}
              data={listingsData?.fulltextSearchListings?.listings}
              query={query}
              viewMode={viewMode}
              type="listings"
            />
          ) : (
            <>
              <h2 className="text-2xl font-semibold mb-4 font-general-sans">
                No Listings Offering Certified Organic "{query}"
              </h2>
              <NoResultSection />
            </>
          )}
        </div>
      )}

      {showType.includes("wanted") && (
        <div>
          {asksData?.fulltextSearchAsks?.totalResults > 0 ? (
            <SearchResultSection
              title={
                <FormattedMessage
                  id="search.operations.results.title.wanted"
                  values={{
                    count: asksData?.fulltextSearchAsks?.totalResults,
                    query,
                  }}
                />
              }
              viewAll={`/search/${query}?showType=wanted`}
              data={asksData?.fulltextSearchAsks?.asks}
              query={query}
              viewMode={viewMode}
              type="asks"
            />
          ) : (
            <>
              <h2 className="text-2xl font-semibold mb-4 font-general-sans">
                No Listings Looking for Certified Organic "{query}"
              </h2>
              <NoResultSection />
            </>
          )}
        </div>
      )}

      <Pagination
        operationsData={operationsData}
        operationsHandlersData={operationsHandlersData}
        listingsData={listingsData}
        asksData={asksData}
        showType={showType}
      />
    </div>
  );
}

export { SearchResult };