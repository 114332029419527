import React, { useState } from "react";
interface InputFieldProps {
  label?: string;
  name?: string;
  type?: "input" | "select" | "textarea" | "photo" | "password" | "email";
  selectOptions?: { name: string; value: string }[];
  required?: boolean;
  placeholder?: string
  value?: string;
  defaultValue?: string;
  error?: string | any;
  register?: any;
  disabled?: boolean;
  labelClass?: string;
  onChange?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void;
}

const InputField = ({
  type = "input",
  label,
  selectOptions,
  required = false,
  value,
  placeholder,
  onChange,
  name,
  defaultValue,
  error,
  register,
  disabled,
  labelClass,
}: InputFieldProps) => {
  return (
    <div className="w-full">
      {label ? <InputLabel text={label} otherClass={labelClass} /> : null}
      {type === "input" || type === "password" || type === "email" ? (
        <>
          <input
            name={name}
            required={required}
            type={type === "password" || type === "email" ? type : "text"}
            className="border border-primary-300 rounded-md px-3 py-2 shadow-sm w-full disabled:bg-gray-100 disabled:opacity-60 disabled:border-gray-200 outline-green-900"
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChange}
            {...register}
            disabled={disabled}
          />
          {error ? (
            <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100 mt-2">
              <p className="text-red-500 text-xs font-medium">{error}</p>
            </div>
          ) : null}
        </>
      ) : type === "select" ? (
        <select
          className="border border-primary-300 rounded-md px-3 py-2 shadow-sm w-full outline-green-900"
          value={value}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          {...register}
        >
          {selectOptions?.map((optionData, index) => (
            <option
              key={`${optionData?.value}-${index}`}
              value={optionData?.value}
            >
              {optionData?.name}
            </option>
          ))}
        </select>
      ) : type === "textarea" ? (
        <textarea
          className="border border-primary-300 rounded-md px-3 py-2 shadow-sm w-full"
          rows={4}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          {...register}
      />
      ) : type === "photo" ? (
        <div className="flex flex-col gap-2 rounded-md p-5 border-2 border-dashed border-primary-300 items-center justify-center">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-primary-400"
          >
            <path
              d="M28 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V32M8 32V36C8 37.0609 8.42143 38.0783 9.17157 38.8284C9.92172 39.5786 10.9391 40 12 40H36C37.0609 40 38.0783 39.5786 38.8284 38.8284C39.5786 38.0783 40 37.0609 40 36V28M8 32L17.172 22.828C17.9221 22.0781 18.9393 21.6569 20 21.6569C21.0607 21.6569 22.0779 22.0781 22.828 22.828L28 28M40 20V28M40 28L36.828 24.828C36.0779 24.0781 35.0607 23.6569 34 23.6569C32.9393 23.6569 31.9221 24.0781 31.172 24.828L28 28M28 28L32 32M36 8H44M40 4V12M28 16H28.02"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="font-medium text-sm text-primary-600">
            <button className="cursor-pointer">Upload a photo</button> or drag
            and drop
          </div>
          <div className="text-xs text-primary-500">Up to 10MB</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

interface InputLabelType {
  text: string;
  otherClass?: string;
}

const InputLabel = ({ text, otherClass }: InputLabelType) => {
  return (
    <label
      className={`text-sm text-primary-700 font-medium mb-1 block ${otherClass}`}
    >
      {text}
    </label>
  );
};

export { InputLabel };

export default InputField;
