import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { useSendEmailBusinessVerificationMutation } from "../../graphql/generated-types";

const CompanyVerification = () => {
  return (
    <div className="p-4 md:p-6 max-w-2xl mx-auto">
      <div className="mb-8 flex flex-col">
        <h1 className="text-2xl font-medium text-gray-900">
          USDA Organic Verification
        </h1>
        <p className="text-base text-gray-500">
          Upload your USDA Organic Certificate below to verify your Certified
          Organic status.
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <CompanyOID />
      </div>
    </div>
  );
};

type FileVerificationInputs = {
  file?: File;
};

const CompanyOID = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { organization } = useOrganization();

  const [sendEmailBusinessVerification] =
    useSendEmailBusinessVerificationMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FileVerificationInputs>();

  const onSubmit: SubmitHandler<FileVerificationInputs> = async ({ file }) => {
    setLoading(true);

    try {
      await sendEmailBusinessVerification({
        variables: {
          clerkUserId: user.id,
          clerkOrganizationId: organization.id,
          image: acceptedFiles[0],
        },
      });
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <h3 className="text-sm font-medium text-gray-700">USDA Organic Certificate</h3>
        <div
          {...getRootProps({
            className:
              "dropzone p-6 w-full flex flex-col gap-2 items-center justify-center text-center rounded-md border-2 border-dashed border-primary-300 hover:bg-gray-50 cursor-pointer",
          })}
        >
          <input {...getInputProps()} />
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-primary-400"
          >
            <path
              d="M28 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V32M8 32V36C8 37.0609 8.42143 38.0783 9.17157 38.8284C9.92172 39.5786 10.9391 40 12 40H36C37.0609 40 38.0783 39.5786 38.8284 38.8284C39.5786 38.0783 40 37.0609 40 36V28M8 32L17.172 22.828C17.9221 22.0781 18.9393 21.6569 20 21.6569C21.0607 21.6569 22.0779 22.0781 22.828 22.828L28 28M40 20V28M40 28L36.828 24.828C36.0779 24.0781 35.0607 23.6569 34 23.6569C32.9393 23.6569 31.9221 24.0781 31.172 24.828L28 28M28 28L32 32M36 8H44M40 4V12M28 16H28.02"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="font-medium text-sm text-primary-600">
            <span className="underline">Upload a file</span> or drag and drop
          </p>
          <p className="text-xs text-primary-500">Up to 10MB</p>
        </div>

        {acceptedFiles.length > 0 && (
          <div className="mt-2">
            <h4 className="font-medium text-sm text-gray-700">Files:</h4>
            <ul className="list-disc pl-5 mt-1 text-sm text-gray-600">
              {acceptedFiles.map((file) => (
                <li key={file.name}>{file.name}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex justify-end mt-4">
          <button
            type="submit"
            disabled={loading}
            className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Submit
          </button>
        </div>
      </form>
  );
};

export { CompanyVerification };