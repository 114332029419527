import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  Dialog,
  Transition,
  TransitionChild,
  DialogPanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useGlobalStore } from "../../store/useGlobalStore";

const filters = [
  {
    id: "show",
    name: "Show",
    type: "checkbox",
    options: [
      {
        value: "certified-organic-farms-handlers",
        label: "Certified Organic Farms & Handlers",
      },
      {
        value: "certified-organic-handlers",
        label: "Certified Organic Handlers",
      },
      {
        value: "for-sale",
        label: "Listings Selling Certified Organic Goods",
      },
      {
        value: "wanted",
        label: "Listings Buying Certified Organic Goods",
      },
    ],
  },
];

function Sidebar(props) {
  const mobileFiltersOpen = useGlobalStore((state) => state.mobileFiltersOpen);
  const setMobileFiltersOpen = useGlobalStore(
    (state) => state.setMobileFiltersOpen,
  );

  return (
    <>
      <Transition show={mobileFiltersOpen}>
        <Dialog
          className="relative z-40 lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <TransitionChild
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <DialogPanel className="relative ml-auto flex h-full w-full max-w-[300px] flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-4 border-t border-gray-200 p-4">
                  <FiltersForm formType="mobile" />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
      <aside className="max-md:hidden">
        <FiltersForm />
      </aside>
    </>
  );
}

interface FiltersFormType {
  formType?: string;
}

const FiltersForm = ({ formType = "desktop" }: FiltersFormType) => {
  const { query } = useParams<{ query; page }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const showType = searchParams.getAll("showType");
  const navigate = useNavigate();

  useEffect(() => {
    if (showType.length === 0) {
      const newSearchParams = new URLSearchParams(searchParams);
      filters[0].options.forEach(option => newSearchParams.append("showType", option.value));
      setSearchParams(newSearchParams);
    }
  }, []);

  const handleChange = (event) => {
    const { value, checked } = event.target;

    setSearchParams((params) => {
      if (checked) {
        params.append("showType", value);
      } else {
        const values = params.getAll("showType").filter((v) => v !== value);
        params.delete("showType");
        values.forEach((v) => params.append("showType", v));
      }
      return params;
    });

    navigate(`/search/${query}?${searchParams.toString()}`);
  };

  return (
    <form>
      <div className="flex flex-col">
        {filters.map((section) => (
          <div
            className="pb-6 mb-6 md:pb-12 md:mb-12 border-b"
            key={section.id}
          >
            <fieldset>
              <legend className="text-sm text-gray-900 font-medium mb-6">
                {section.name}
              </legend>
              <div className="flex flex-col gap-3">
                {section.options.map((option) => {
                  const isChecked = showType.includes(option.value);
                  return (
                    <div
                      key={option.value}
                      className="flex items-center gap-2 text-sm"
                    >
                      <input
                        name={`${section.id}[]`}
                        id={`filter-${formType}-${section.id}-${option.value}`}
                        value={option.value}
                        checked={isChecked}
                        type="checkbox"
                        onChange={handleChange}
                      />
                      <label
                        htmlFor={`filter-${formType}-${section.id}-${option.value}`}
                      >
                        {option.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </fieldset>
          </div>
        ))}
      </div>
    </form>
  );
};

export { Sidebar };