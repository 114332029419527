import React, { useState, FormEvent } from "react";
import InputField from "../marketplace/InputField";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSignUp } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from "react-router-dom";

type SignUpInputs = {
  emailAddress: string;
  password: string;
};

const Step1 = () => {
  const { isLoaded: isClerkLoaded, signUp, setActive } = useSignUp();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const [clerkError, setClerkError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpInputs>();

  const onSubmit: SubmitHandler<SignUpInputs> = async ({
    emailAddress,
    password,
  }) => {
    setLoading(true);
    setClerkError("");

    try {
      await signUp.create({
        emailAddress,
        password,
      });
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });

      navigate(`/signup/verification?email=${emailAddress}`);
    } catch (err: any) {
      setClerkError(err.errors[0].message);
    }

    setLoading(false);
  };

  return (
    <>
      <div>
        <h1 className="text-3xl text-gray-900 font-semibold mb-10">
          Create a Market.Organic account
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <InputField
            label="Email"
            required
            name="emailAddress"
            type="email"
            defaultValue={email}
            register={register("emailAddress", {
              required: true,
            })}
          />
          <InputField
            label="Password"
            type="password"
            name="password"
            required
            register={register("password", {
              required: true,
            })}
          />
        </div>

        {clerkError && (
          <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100 mt-4">
            <p className="text-red-500 text-xs font-medium">{clerkError}</p>
          </div>
        )}

        <div className="mt-6">
          <button
            type="submit"
            disabled={!isClerkLoaded || loading}
            className="rounded-full px-6 py-3 block w-full text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white text-sm font-semibold disabled:bg-gray-400 disabled:pointer-events-none"
          >
            Sign up
          </button>
        </div>
      </form>
    </>
  );
};

export default Step1;
