import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** Autogenerated input type of AddOperationProduct */
export type AddOperationProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
};

export type Ask = {
  __typename?: 'Ask';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  minQty?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  organizationId: Scalars['Int']['output'];
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type AskSearchResult = {
  __typename?: 'AskSearchResult';
  asks?: Maybe<Array<Ask>>;
  totalPages: Scalars['Int']['output'];
  totalResults: Scalars['Int']['output'];
};

export type ContactInfo = {
  __typename?: 'ContactInfo';
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateCheckoutLink */
export type CreateCheckoutLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
  products: Array<SquareCheckoutProductInput>;
};

/** Autogenerated input type of CreateCheckoutLinkStripe */
export type CreateCheckoutLinkStripeInput = {
  cancelPath: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['String']['input'];
  successPath: Scalars['String']['input'];
};

/** Autogenerated input type of CreateOrEditAsk */
export type CreateOrEditAskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minQty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateOrEditListing */
export type CreateOrEditListingInput = {
  availability?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expirationPeriod?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  minQty?: InputMaybe<Scalars['String']['input']>;
  operationId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of DeleteAsk */
export type DeleteAskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated input type of DeleteListing */
export type DeleteListingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated input type of EditOperationProduct */
export type EditOperationProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
};

export type Item = {
  __typename?: 'Item';
  category?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  certNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  itemList?: Maybe<Scalars['String']['output']>;
  madeWithOrganic?: Maybe<Scalars['Boolean']['output']>;
  operation: Operation;
  organic?: Maybe<Scalars['Boolean']['output']>;
  organic100?: Maybe<Scalars['Boolean']['output']>;
  products: Array<Product>;
  scope?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  varieties?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of LinkSquareMerchant */
export type LinkSquareMerchantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
};

export type Listing = {
  __typename?: 'Listing';
  availability?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expirationPeriod?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  minQty?: Maybe<Scalars['Int']['output']>;
  operation: Operation;
  operationId: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ListingSearchResult = {
  __typename?: 'ListingSearchResult';
  listings?: Maybe<Array<Listing>>;
  totalPages: Scalars['Int']['output'];
  totalResults: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addOperationProduct?: Maybe<Array<Product>>;
  createCheckoutLink?: Maybe<Scalars['String']['output']>;
  createCheckoutLinkStripe?: Maybe<Scalars['String']['output']>;
  createOrEditAsk?: Maybe<Ask>;
  createOrEditListing?: Maybe<Listing>;
  deleteAsk?: Maybe<Scalars['Boolean']['output']>;
  deleteListing?: Maybe<Scalars['Boolean']['output']>;
  editOperationProduct?: Maybe<Product>;
  linkSquareMerchant?: Maybe<Scalars['Boolean']['output']>;
  sendEmailBusinessVerification?: Maybe<Scalars['String']['output']>;
  sendEmailVerificationCode?: Maybe<Scalars['String']['output']>;
  sendPhoneVerificationCode?: Maybe<Scalars['String']['output']>;
  updateOperation?: Maybe<Operation>;
  updateOrganization?: Maybe<Organization>;
  verify2faCode?: Maybe<Scalars['String']['output']>;
};


export type MutationAddOperationProductArgs = {
  input: AddOperationProductInput;
};


export type MutationCreateCheckoutLinkArgs = {
  input: CreateCheckoutLinkInput;
};


export type MutationCreateCheckoutLinkStripeArgs = {
  input: CreateCheckoutLinkStripeInput;
};


export type MutationCreateOrEditAskArgs = {
  input: CreateOrEditAskInput;
};


export type MutationCreateOrEditListingArgs = {
  input: CreateOrEditListingInput;
};


export type MutationDeleteAskArgs = {
  input: DeleteAskInput;
};


export type MutationDeleteListingArgs = {
  input: DeleteListingInput;
};


export type MutationEditOperationProductArgs = {
  input: EditOperationProductInput;
};


export type MutationLinkSquareMerchantArgs = {
  input: LinkSquareMerchantInput;
};


export type MutationSendEmailBusinessVerificationArgs = {
  input: SendEmailBusinessVerificationInput;
};


export type MutationSendEmailVerificationCodeArgs = {
  input: SendEmailVerificationCodeInput;
};


export type MutationSendPhoneVerificationCodeArgs = {
  input: SendPhoneVerificationCodeInput;
};


export type MutationUpdateOperationArgs = {
  input: UpdateOperationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationVerify2faCodeArgs = {
  input: Verify2faCodeInput;
};

export type Operation = {
  __typename?: 'Operation';
  broker?: Maybe<Scalars['String']['output']>;
  certUrl?: Maybe<Scalars['String']['output']>;
  certificationStatus: Scalars['String']['output'];
  certificationStatusEffectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  certifierName: Scalars['String']['output'];
  clientId?: Maybe<Scalars['String']['output']>;
  contactFirstName?: Maybe<Scalars['String']['output']>;
  contactLastName?: Maybe<Scalars['String']['output']>;
  copacker?: Maybe<Scalars['String']['output']>;
  countyCode?: Maybe<Scalars['String']['output']>;
  cropsCertification?: Maybe<Scalars['String']['output']>;
  cropsCertificationEffectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  csa?: Maybe<Scalars['String']['output']>;
  dairy?: Maybe<Scalars['String']['output']>;
  distributor?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  extraInfo?: Maybe<Scalars['String']['output']>;
  growerGroup?: Maybe<Scalars['String']['output']>;
  handlingCertification?: Maybe<Scalars['String']['output']>;
  handlingCertificationEffectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  items: Array<Item>;
  lastUpdatedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  livestockCertification?: Maybe<Scalars['String']['output']>;
  livestockCertificationEffectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  mailingAddressCity?: Maybe<Scalars['String']['output']>;
  mailingAddressCountry?: Maybe<Scalars['String']['output']>;
  mailingAddressCounty?: Maybe<Scalars['String']['output']>;
  mailingAddressCountyCode?: Maybe<Scalars['String']['output']>;
  mailingAddressState?: Maybe<Scalars['String']['output']>;
  mailingAddressZip?: Maybe<Scalars['String']['output']>;
  marketerTrader?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nopAnniversaryDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  operationId: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  otherNames?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  physicalAddressCity?: Maybe<Scalars['String']['output']>;
  physicalAddressCountry?: Maybe<Scalars['String']['output']>;
  physicalAddressCounty?: Maybe<Scalars['String']['output']>;
  physicalAddressState?: Maybe<Scalars['String']['output']>;
  physicalAddressZip?: Maybe<Scalars['String']['output']>;
  poultry?: Maybe<Scalars['String']['output']>;
  privateLabeler?: Maybe<Scalars['String']['output']>;
  products: Array<Product>;
  restaurant?: Maybe<Scalars['String']['output']>;
  retailer?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Array<Scalars['String']['output']>>;
  slaughterHouse?: Maybe<Scalars['String']['output']>;
  storage?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  wildCropsCertification?: Maybe<Scalars['String']['output']>;
  wildCropsCertificationEffectiveDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type OperationSearchResult = {
  __typename?: 'OperationSearchResult';
  operations?: Maybe<Array<Operation>>;
  totalPages: Scalars['Int']['output'];
  totalResults: Scalars['Int']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  clerkOrganizationId: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  operations: Array<Operation>;
  phone?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  users: Array<User>;
};

export type Product = {
  __typename?: 'Product';
  autogenerated: Scalars['Boolean']['output'];
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  item: Item;
  name?: Maybe<Scalars['String']['output']>;
  operation: Operation;
};

export type Query = {
  __typename?: 'Query';
  ask: Ask;
  /** A set of Asks */
  asks: Array<Ask>;
  /** Find operations by name */
  findOperations?: Maybe<Array<Operation>>;
  /** Full text search for asks */
  fulltextSearchAsks: AskSearchResult;
  /** Full text search for listings */
  fulltextSearchListings: ListingSearchResult;
  /** Full text search for operations */
  fulltextSearchOperations: OperationSearchResult;
  isSquareLinked: Scalars['Boolean']['output'];
  /** A set of Items */
  items: Array<Item>;
  listing: Listing;
  /** An Operation's listings */
  listings: Array<Listing>;
  /** Return info on the current user */
  me?: Maybe<User>;
  /** An Operation */
  operation: Operation;
  /** Private contact information of an operation */
  operationContactInfo?: Maybe<ContactInfo>;
  operations: Array<Operation>;
  /** An Organization */
  organization: Organization;
  /** Private contact information of an organization */
  organizationContactInfo?: Maybe<ContactInfo>;
  /** A set of Products */
  products: Array<Product>;
  /** Fetch a square merchant catalog by operation ID */
  squareMerchantCatalog: SquareMerchantCatalog;
  stripeProducts: Array<StripeProduct>;
  stripeSubscriptions: Array<StripeSubscription>;
  /** Typeahead for filters */
  typeahead?: Maybe<Array<Scalars['String']['output']>>;
};


export type QueryAskArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAsksArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindOperationsArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFulltextSearchAsksArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFulltextSearchListingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFulltextSearchOperationsArgs = {
  handling?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIsSquareLinkedArgs = {
  operationId: Scalars['Int']['input'];
};


export type QueryListingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryListingsArgs = {
  operationId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOperationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOperationContactInfoArgs = {
  hcaptchaResponse: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type QueryOperationsArgs = {
  operationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationArgs = {
  clerkOrgId: Scalars['String']['input'];
};


export type QueryOrganizationContactInfoArgs = {
  hcaptchaResponse: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type QuerySquareMerchantCatalogArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
};


export type QueryStripeSubscriptionsArgs = {
  status?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTypeaheadArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SendEmailBusinessVerification */
export type SendEmailBusinessVerificationInput = {
  clerkOrganizationId: Scalars['String']['input'];
  clerkUserId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['Upload']['input'];
};

/** Autogenerated input type of SendEmailVerificationCode */
export type SendEmailVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
};

/** Autogenerated input type of SendPhoneVerificationCode */
export type SendPhoneVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
};

export type SquareCheckoutProductInput = {
  id: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['String']['input']>;
};

export type SquareInventory = {
  __typename?: 'SquareInventory';
  catalogObjectId: Scalars['String']['output'];
  locationId: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type SquareMerchantCatalog = {
  __typename?: 'SquareMerchantCatalog';
  cursor?: Maybe<Scalars['String']['output']>;
  inventory?: Maybe<Array<SquareInventory>>;
  products: Array<SquareMerchantProduct>;
};

export type SquareMerchantItemData = {
  __typename?: 'SquareMerchantItemData';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productType?: Maybe<Scalars['String']['output']>;
  variations?: Maybe<Array<SquareMerchantItemVariation>>;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type SquareMerchantItemLocationOverrides = {
  __typename?: 'SquareMerchantItemLocationOverrides';
  inventoryAlertThreshold?: Maybe<Scalars['Int']['output']>;
  inventoryAlertType?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  trackInventory?: Maybe<Scalars['Boolean']['output']>;
};

export type SquareMerchantItemPriceMoney = {
  __typename?: 'SquareMerchantItemPriceMoney';
  amount?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
};

export type SquareMerchantItemVariation = {
  __typename?: 'SquareMerchantItemVariation';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  itemVariationData?: Maybe<SquareMerchantItemVariationData>;
  presentAtAllLocations?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type SquareMerchantItemVariationData = {
  __typename?: 'SquareMerchantItemVariationData';
  itemId?: Maybe<Scalars['String']['output']>;
  locationOverrides?: Maybe<Array<SquareMerchantItemLocationOverrides>>;
  name?: Maybe<Scalars['String']['output']>;
  ordinal?: Maybe<Scalars['Int']['output']>;
  priceMoney?: Maybe<SquareMerchantItemPriceMoney>;
  sku?: Maybe<Scalars['String']['output']>;
};

export type SquareMerchantProduct = {
  __typename?: 'SquareMerchantProduct';
  id?: Maybe<Scalars['String']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  itemData?: Maybe<SquareMerchantItemData>;
  presentAtAllLocations?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceId: Scalars['String']['output'];
  recurring: Scalars['String']['output'];
  unitAmount: Scalars['String']['output'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  cancelAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  collectionMethod: Scalars['String']['output'];
  created: Scalars['ISO8601DateTime']['output'];
  currency: Scalars['String']['output'];
  currentPeriodEnd: Scalars['ISO8601DateTime']['output'];
  currentPeriodStart: Scalars['ISO8601DateTime']['output'];
  customer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  livemode: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};

/** Autogenerated input type of UpdateOperation */
export type UpdateOperationInput = {
  certifierName?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateOrganization */
export type UpdateOrganizationInput = {
  clerkOrganizationId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  clerkUserId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  organizations: Array<Organization>;
};

/** Autogenerated input type of Verify2faCode */
export type Verify2faCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
  phoneOrEmail: Scalars['String']['input'];
};

export type SendPhoneVerificationCodeMutationVariables = Exact<{
  input: SendPhoneVerificationCodeInput;
}>;


export type SendPhoneVerificationCodeMutation = { __typename?: 'Mutation', sendPhoneVerificationCode?: string | null };

export type SendEmailVerificationCodeMutationVariables = Exact<{
  input: SendEmailVerificationCodeInput;
}>;


export type SendEmailVerificationCodeMutation = { __typename?: 'Mutation', sendEmailVerificationCode?: string | null };

export type Verify2faCodeMutationVariables = Exact<{
  phoneOrEmail: Scalars['String']['input'];
  code: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
}>;


export type Verify2faCodeMutation = { __typename?: 'Mutation', verify2faCode?: string | null };

export type AsksQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AsksQuery = { __typename?: 'Query', asks: Array<{ __typename?: 'Ask', id: number, title: string, description?: string | null, createdAt: any, updatedAt: any, minQty?: number | null, status?: string | null, expirationDate?: any | null, organization: { __typename?: 'Organization', id: number, name?: string | null } }> };

export type AsksFullTextSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AsksFullTextSearchQuery = { __typename?: 'Query', fulltextSearchAsks: { __typename?: 'AskSearchResult', totalPages: number, totalResults: number, asks?: Array<{ __typename?: 'Ask', id: number, title: string, description?: string | null, createdAt: any, updatedAt: any, minQty?: number | null, status?: string | null, expirationDate?: any | null, organization: { __typename?: 'Organization', id: number, name?: string | null } }> | null } };

export type CreateCheckoutLinkMutationVariables = Exact<{
  input: CreateCheckoutLinkInput;
}>;


export type CreateCheckoutLinkMutation = { __typename?: 'Mutation', createCheckoutLink?: string | null };

export type CreateCheckoutLinkStripeMutationVariables = Exact<{
  priceId: Scalars['String']['input'];
  successPath: Scalars['String']['input'];
  cancelPath: Scalars['String']['input'];
}>;


export type CreateCheckoutLinkStripeMutation = { __typename?: 'Mutation', createCheckoutLinkStripe?: string | null };

export type CreateOrEditAskMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  minQty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
}>;


export type CreateOrEditAskMutation = { __typename?: 'Mutation', createOrEditAsk?: { __typename?: 'Ask', id: number } | null };

export type CreateOrEditListingMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  minQty?: InputMaybe<Scalars['String']['input']>;
  availability?: InputMaybe<Scalars['String']['input']>;
  expirationPeriod?: InputMaybe<Scalars['Int']['input']>;
  operationId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateOrEditListingMutation = { __typename?: 'Mutation', createOrEditListing?: { __typename?: 'Listing', id: number, title: string, description?: string | null, minQty?: number | null, availability?: string | null, expirationPeriod?: number | null, operationId: number } | null };

export type DeleteAskMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteAskMutation = { __typename?: 'Mutation', deleteAsk?: boolean | null };

export type DeleteListingMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteListingMutation = { __typename?: 'Mutation', deleteListing?: boolean | null };

export type FindOperationsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type FindOperationsQuery = { __typename?: 'Query', findOperations?: Array<{ __typename?: 'Operation', id: number, name: string, phone?: string | null, email?: string | null, operationId: string }> | null };

export type FullAskQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FullAskQuery = { __typename?: 'Query', ask: { __typename?: 'Ask', id: number, title: string, description?: string | null, organization: { __typename?: 'Organization', id: number, name?: string | null } } };

export type FullListingQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type FullListingQuery = { __typename?: 'Query', listing: { __typename?: 'Listing', id: number, title: string, description?: string | null, availability?: string | null, minQty?: number | null, operation: { __typename?: 'Operation', id: number, name: string, email?: string | null, mailingAddressCounty?: string | null, physicalAddressCounty?: string | null, mailingAddressState?: string | null, physicalAddressState?: string | null, mailingAddressCountry?: string | null, physicalAddressCountry?: string | null, certifierName: string } } };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', clerkUserId: string, id: number, organizations: Array<{ __typename?: 'Organization', id: number, operations: Array<{ __typename?: 'Operation', id: number, name: string, products: Array<{ __typename?: 'Product', name?: string | null }> }> }> } | null };

export type GetStripeProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeProductsQuery = { __typename?: 'Query', stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, name: string, description: string, priceId: string, unitAmount: string, recurring: string }> };

export type ListingsQueryVariables = Exact<{
  operationId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListingsQuery = { __typename?: 'Query', listings: Array<{ __typename?: 'Listing', id: number, title: string, description?: string | null, createdAt: any }> };

export type ListingsFullTextSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ListingsFullTextSearchQuery = { __typename?: 'Query', fulltextSearchListings: { __typename?: 'ListingSearchResult', totalPages: number, totalResults: number, listings?: Array<{ __typename?: 'Listing', id: number, title: string, description?: string | null, minQty?: number | null, availability?: string | null, expirationPeriod?: number | null, createdAt: any, operation: { __typename?: 'Operation', id: number, name: string, mailingAddressCounty?: string | null, physicalAddressCounty?: string | null, mailingAddressState?: string | null, physicalAddressState?: string | null, mailingAddressCountry?: string | null, physicalAddressCountry?: string | null } }> | null } };

export type OperationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OperationQuery = { __typename?: 'Query', operation: { __typename?: 'Operation', id: number, name: string, certificationStatus: string, certifierName: string, scope?: Array<string> | null, mailingAddressCounty?: string | null, physicalAddressCounty?: string | null, mailingAddressState?: string | null, physicalAddressState?: string | null, mailingAddressCountry?: string | null, physicalAddressCountry?: string | null, email?: string | null, url?: string | null, phone?: string | null, operationId: string, products: Array<{ __typename?: 'Product', name?: string | null }>, items: Array<{ __typename?: 'Item', id: string, products: Array<{ __typename?: 'Product', name?: string | null }> }> } };

export type OperationContactInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  hcaptchaResponse: Scalars['String']['input'];
}>;


export type OperationContactInfoQuery = { __typename?: 'Query', operationContactInfo?: { __typename?: 'ContactInfo', url?: string | null, email?: string | null, phone?: string | null } | null };

export type OperationsFullTextSearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
  stateQuery?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  handling?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type OperationsFullTextSearchQuery = { __typename?: 'Query', fulltextSearchOperations: { __typename?: 'OperationSearchResult', totalPages: number, totalResults: number, operations?: Array<{ __typename?: 'Operation', id: number, name: string, certificationStatus: string, certifierName: string, scope?: Array<string> | null, mailingAddressCounty?: string | null, physicalAddressCounty?: string | null, mailingAddressState?: string | null, physicalAddressState?: string | null, mailingAddressCountry?: string | null, physicalAddressCountry?: string | null, email?: string | null, url?: string | null, phone?: string | null, products: Array<{ __typename?: 'Product', name?: string | null }> }> | null } };

export type OrganizationContactInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  hcaptchaResponse: Scalars['String']['input'];
}>;


export type OrganizationContactInfoQuery = { __typename?: 'Query', organizationContactInfo?: { __typename?: 'ContactInfo', url?: string | null, email?: string | null, phone?: string | null } | null };

export type SendEmailBusinessVerificationMutationVariables = Exact<{
  clerkUserId: Scalars['String']['input'];
  clerkOrganizationId: Scalars['String']['input'];
  image: Scalars['Upload']['input'];
}>;


export type SendEmailBusinessVerificationMutation = { __typename?: 'Mutation', sendEmailBusinessVerification?: string | null };

export type StripeSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeSubscriptionsQuery = { __typename?: 'Query', stripeSubscriptions: Array<{ __typename?: 'StripeSubscription', customer: string, currency: string, id: string, livemode: boolean, status: string, currentPeriodStart: any, currentPeriodEnd: any, collectionMethod: string, canceledAt?: any | null }> };

export type UpdateOperationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  certifierName: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  contactFirstName?: InputMaybe<Scalars['String']['input']>;
  contactLastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateOperationMutation = { __typename?: 'Mutation', updateOperation?: { __typename?: 'Operation', id: number, name: string, certificationStatus: string, certifierName: string, scope?: Array<string> | null, mailingAddressCounty?: string | null, physicalAddressCounty?: string | null, mailingAddressState?: string | null, physicalAddressState?: string | null, mailingAddressCountry?: string | null, physicalAddressCountry?: string | null, email?: string | null, url?: string | null, phone?: string | null, products: Array<{ __typename?: 'Product', name?: string | null }> } | null };

export type UpdateOrganizationMutationVariables = Exact<{
  clerkOrganizationId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  url: Scalars['String']['input'];
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: { __typename?: 'Organization', id: number, clerkOrganizationId: string, phone?: string | null, email?: string | null, url?: string | null, name?: string | null } | null };

export type LinkSquareMerchantMutationVariables = Exact<{
  input: LinkSquareMerchantInput;
}>;


export type LinkSquareMerchantMutation = { __typename?: 'Mutation', linkSquareMerchant?: boolean | null };


export const SendPhoneVerificationCodeDocument = gql`
    mutation SendPhoneVerificationCode($input: SendPhoneVerificationCodeInput!) {
  sendPhoneVerificationCode(input: $input)
}
    `;
export type SendPhoneVerificationCodeMutationFn = Apollo.MutationFunction<SendPhoneVerificationCodeMutation, SendPhoneVerificationCodeMutationVariables>;

/**
 * __useSendPhoneVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendPhoneVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneVerificationCodeMutation, { data, loading, error }] = useSendPhoneVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPhoneVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendPhoneVerificationCodeMutation, SendPhoneVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPhoneVerificationCodeMutation, SendPhoneVerificationCodeMutationVariables>(SendPhoneVerificationCodeDocument, options);
      }
export type SendPhoneVerificationCodeMutationHookResult = ReturnType<typeof useSendPhoneVerificationCodeMutation>;
export type SendPhoneVerificationCodeMutationResult = Apollo.MutationResult<SendPhoneVerificationCodeMutation>;
export type SendPhoneVerificationCodeMutationOptions = Apollo.BaseMutationOptions<SendPhoneVerificationCodeMutation, SendPhoneVerificationCodeMutationVariables>;
export const SendEmailVerificationCodeDocument = gql`
    mutation SendEmailVerificationCode($input: SendEmailVerificationCodeInput!) {
  sendEmailVerificationCode(input: $input)
}
    `;
export type SendEmailVerificationCodeMutationFn = Apollo.MutationFunction<SendEmailVerificationCodeMutation, SendEmailVerificationCodeMutationVariables>;

/**
 * __useSendEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationCodeMutation, { data, loading, error }] = useSendEmailVerificationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationCodeMutation, SendEmailVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailVerificationCodeMutation, SendEmailVerificationCodeMutationVariables>(SendEmailVerificationCodeDocument, options);
      }
export type SendEmailVerificationCodeMutationHookResult = ReturnType<typeof useSendEmailVerificationCodeMutation>;
export type SendEmailVerificationCodeMutationResult = Apollo.MutationResult<SendEmailVerificationCodeMutation>;
export type SendEmailVerificationCodeMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationCodeMutation, SendEmailVerificationCodeMutationVariables>;
export const Verify2faCodeDocument = gql`
    mutation Verify2faCode($phoneOrEmail: String!, $code: String!, $operationId: Int!) {
  verify2faCode(
    input: {phoneOrEmail: $phoneOrEmail, code: $code, operationId: $operationId}
  )
}
    `;
export type Verify2faCodeMutationFn = Apollo.MutationFunction<Verify2faCodeMutation, Verify2faCodeMutationVariables>;

/**
 * __useVerify2faCodeMutation__
 *
 * To run a mutation, you first call `useVerify2faCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify2faCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verify2faCodeMutation, { data, loading, error }] = useVerify2faCodeMutation({
 *   variables: {
 *      phoneOrEmail: // value for 'phoneOrEmail'
 *      code: // value for 'code'
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useVerify2faCodeMutation(baseOptions?: Apollo.MutationHookOptions<Verify2faCodeMutation, Verify2faCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Verify2faCodeMutation, Verify2faCodeMutationVariables>(Verify2faCodeDocument, options);
      }
export type Verify2faCodeMutationHookResult = ReturnType<typeof useVerify2faCodeMutation>;
export type Verify2faCodeMutationResult = Apollo.MutationResult<Verify2faCodeMutation>;
export type Verify2faCodeMutationOptions = Apollo.BaseMutationOptions<Verify2faCodeMutation, Verify2faCodeMutationVariables>;
export const AsksDocument = gql`
    query Asks($organizationId: Int) {
  asks(organizationId: $organizationId) {
    id
    title
    description
    createdAt
    updatedAt
    minQty
    status
    expirationDate
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useAsksQuery__
 *
 * To run a query within a React component, call `useAsksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAsksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsksQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAsksQuery(baseOptions?: Apollo.QueryHookOptions<AsksQuery, AsksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AsksQuery, AsksQueryVariables>(AsksDocument, options);
      }
export function useAsksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AsksQuery, AsksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AsksQuery, AsksQueryVariables>(AsksDocument, options);
        }
export function useAsksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AsksQuery, AsksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AsksQuery, AsksQueryVariables>(AsksDocument, options);
        }
export type AsksQueryHookResult = ReturnType<typeof useAsksQuery>;
export type AsksLazyQueryHookResult = ReturnType<typeof useAsksLazyQuery>;
export type AsksSuspenseQueryHookResult = ReturnType<typeof useAsksSuspenseQuery>;
export type AsksQueryResult = Apollo.QueryResult<AsksQuery, AsksQueryVariables>;
export const AsksFullTextSearchDocument = gql`
    query AsksFullTextSearch($query: String!, $stateQuery: String, $limit: Int, $page: Int) {
  fulltextSearchAsks(
    query: $query
    stateQuery: $stateQuery
    limit: $limit
    page: $page
  ) {
    totalPages
    totalResults
    asks {
      id
      title
      description
      createdAt
      updatedAt
      minQty
      status
      expirationDate
      organization {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAsksFullTextSearchQuery__
 *
 * To run a query within a React component, call `useAsksFullTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAsksFullTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsksFullTextSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      stateQuery: // value for 'stateQuery'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useAsksFullTextSearchQuery(baseOptions: Apollo.QueryHookOptions<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables> & ({ variables: AsksFullTextSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>(AsksFullTextSearchDocument, options);
      }
export function useAsksFullTextSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>(AsksFullTextSearchDocument, options);
        }
export function useAsksFullTextSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>(AsksFullTextSearchDocument, options);
        }
export type AsksFullTextSearchQueryHookResult = ReturnType<typeof useAsksFullTextSearchQuery>;
export type AsksFullTextSearchLazyQueryHookResult = ReturnType<typeof useAsksFullTextSearchLazyQuery>;
export type AsksFullTextSearchSuspenseQueryHookResult = ReturnType<typeof useAsksFullTextSearchSuspenseQuery>;
export type AsksFullTextSearchQueryResult = Apollo.QueryResult<AsksFullTextSearchQuery, AsksFullTextSearchQueryVariables>;
export const CreateCheckoutLinkDocument = gql`
    mutation CreateCheckoutLink($input: CreateCheckoutLinkInput!) {
  createCheckoutLink(input: $input)
}
    `;
export type CreateCheckoutLinkMutationFn = Apollo.MutationFunction<CreateCheckoutLinkMutation, CreateCheckoutLinkMutationVariables>;

/**
 * __useCreateCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutLinkMutation, { data, loading, error }] = useCreateCheckoutLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutLinkMutation, CreateCheckoutLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutLinkMutation, CreateCheckoutLinkMutationVariables>(CreateCheckoutLinkDocument, options);
      }
export type CreateCheckoutLinkMutationHookResult = ReturnType<typeof useCreateCheckoutLinkMutation>;
export type CreateCheckoutLinkMutationResult = Apollo.MutationResult<CreateCheckoutLinkMutation>;
export type CreateCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutLinkMutation, CreateCheckoutLinkMutationVariables>;
export const CreateCheckoutLinkStripeDocument = gql`
    mutation CreateCheckoutLinkStripe($priceId: String!, $successPath: String!, $cancelPath: String!) {
  createCheckoutLinkStripe(
    input: {priceId: $priceId, successPath: $successPath, cancelPath: $cancelPath}
  )
}
    `;
export type CreateCheckoutLinkStripeMutationFn = Apollo.MutationFunction<CreateCheckoutLinkStripeMutation, CreateCheckoutLinkStripeMutationVariables>;

/**
 * __useCreateCheckoutLinkStripeMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutLinkStripeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutLinkStripeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutLinkStripeMutation, { data, loading, error }] = useCreateCheckoutLinkStripeMutation({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      successPath: // value for 'successPath'
 *      cancelPath: // value for 'cancelPath'
 *   },
 * });
 */
export function useCreateCheckoutLinkStripeMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutLinkStripeMutation, CreateCheckoutLinkStripeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutLinkStripeMutation, CreateCheckoutLinkStripeMutationVariables>(CreateCheckoutLinkStripeDocument, options);
      }
export type CreateCheckoutLinkStripeMutationHookResult = ReturnType<typeof useCreateCheckoutLinkStripeMutation>;
export type CreateCheckoutLinkStripeMutationResult = Apollo.MutationResult<CreateCheckoutLinkStripeMutation>;
export type CreateCheckoutLinkStripeMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutLinkStripeMutation, CreateCheckoutLinkStripeMutationVariables>;
export const CreateOrEditAskDocument = gql`
    mutation CreateOrEditAsk($id: Int, $title: String, $description: String, $minQty: String, $status: String, $expirationDate: ISO8601DateTime) {
  createOrEditAsk(
    input: {id: $id, title: $title, description: $description, minQty: $minQty, status: $status, expirationDate: $expirationDate}
  ) {
    id
  }
}
    `;
export type CreateOrEditAskMutationFn = Apollo.MutationFunction<CreateOrEditAskMutation, CreateOrEditAskMutationVariables>;

/**
 * __useCreateOrEditAskMutation__
 *
 * To run a mutation, you first call `useCreateOrEditAskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrEditAskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrEditAskMutation, { data, loading, error }] = useCreateOrEditAskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      minQty: // value for 'minQty'
 *      status: // value for 'status'
 *      expirationDate: // value for 'expirationDate'
 *   },
 * });
 */
export function useCreateOrEditAskMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrEditAskMutation, CreateOrEditAskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrEditAskMutation, CreateOrEditAskMutationVariables>(CreateOrEditAskDocument, options);
      }
export type CreateOrEditAskMutationHookResult = ReturnType<typeof useCreateOrEditAskMutation>;
export type CreateOrEditAskMutationResult = Apollo.MutationResult<CreateOrEditAskMutation>;
export type CreateOrEditAskMutationOptions = Apollo.BaseMutationOptions<CreateOrEditAskMutation, CreateOrEditAskMutationVariables>;
export const CreateOrEditListingDocument = gql`
    mutation CreateOrEditListing($id: Int, $title: String, $description: String, $minQty: String, $availability: String, $expirationPeriod: Int, $operationId: Int) {
  createOrEditListing(
    input: {id: $id, title: $title, description: $description, minQty: $minQty, availability: $availability, expirationPeriod: $expirationPeriod, operationId: $operationId}
  ) {
    id
    title
    description
    minQty
    availability
    expirationPeriod
    operationId
  }
}
    `;
export type CreateOrEditListingMutationFn = Apollo.MutationFunction<CreateOrEditListingMutation, CreateOrEditListingMutationVariables>;

/**
 * __useCreateOrEditListingMutation__
 *
 * To run a mutation, you first call `useCreateOrEditListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrEditListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrEditListingMutation, { data, loading, error }] = useCreateOrEditListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      minQty: // value for 'minQty'
 *      availability: // value for 'availability'
 *      expirationPeriod: // value for 'expirationPeriod'
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useCreateOrEditListingMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrEditListingMutation, CreateOrEditListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrEditListingMutation, CreateOrEditListingMutationVariables>(CreateOrEditListingDocument, options);
      }
export type CreateOrEditListingMutationHookResult = ReturnType<typeof useCreateOrEditListingMutation>;
export type CreateOrEditListingMutationResult = Apollo.MutationResult<CreateOrEditListingMutation>;
export type CreateOrEditListingMutationOptions = Apollo.BaseMutationOptions<CreateOrEditListingMutation, CreateOrEditListingMutationVariables>;
export const DeleteAskDocument = gql`
    mutation DeleteAsk($id: Int!) {
  deleteAsk(input: {id: $id})
}
    `;
export type DeleteAskMutationFn = Apollo.MutationFunction<DeleteAskMutation, DeleteAskMutationVariables>;

/**
 * __useDeleteAskMutation__
 *
 * To run a mutation, you first call `useDeleteAskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAskMutation, { data, loading, error }] = useDeleteAskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAskMutation, DeleteAskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAskMutation, DeleteAskMutationVariables>(DeleteAskDocument, options);
      }
export type DeleteAskMutationHookResult = ReturnType<typeof useDeleteAskMutation>;
export type DeleteAskMutationResult = Apollo.MutationResult<DeleteAskMutation>;
export type DeleteAskMutationOptions = Apollo.BaseMutationOptions<DeleteAskMutation, DeleteAskMutationVariables>;
export const DeleteListingDocument = gql`
    mutation DeleteListing($id: Int!) {
  deleteListing(input: {id: $id})
}
    `;
export type DeleteListingMutationFn = Apollo.MutationFunction<DeleteListingMutation, DeleteListingMutationVariables>;

/**
 * __useDeleteListingMutation__
 *
 * To run a mutation, you first call `useDeleteListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteListingMutation, { data, loading, error }] = useDeleteListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteListingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteListingMutation, DeleteListingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteListingMutation, DeleteListingMutationVariables>(DeleteListingDocument, options);
      }
export type DeleteListingMutationHookResult = ReturnType<typeof useDeleteListingMutation>;
export type DeleteListingMutationResult = Apollo.MutationResult<DeleteListingMutation>;
export type DeleteListingMutationOptions = Apollo.BaseMutationOptions<DeleteListingMutation, DeleteListingMutationVariables>;
export const FindOperationsDocument = gql`
    query FindOperations($name: String) {
  findOperations(name: $name, limit: 7) {
    id
    name
    phone
    email
    operationId
  }
}
    `;

/**
 * __useFindOperationsQuery__
 *
 * To run a query within a React component, call `useFindOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOperationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOperationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindOperationsQuery(baseOptions?: Apollo.QueryHookOptions<FindOperationsQuery, FindOperationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOperationsQuery, FindOperationsQueryVariables>(FindOperationsDocument, options);
      }
export function useFindOperationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOperationsQuery, FindOperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOperationsQuery, FindOperationsQueryVariables>(FindOperationsDocument, options);
        }
export function useFindOperationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindOperationsQuery, FindOperationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOperationsQuery, FindOperationsQueryVariables>(FindOperationsDocument, options);
        }
export type FindOperationsQueryHookResult = ReturnType<typeof useFindOperationsQuery>;
export type FindOperationsLazyQueryHookResult = ReturnType<typeof useFindOperationsLazyQuery>;
export type FindOperationsSuspenseQueryHookResult = ReturnType<typeof useFindOperationsSuspenseQuery>;
export type FindOperationsQueryResult = Apollo.QueryResult<FindOperationsQuery, FindOperationsQueryVariables>;
export const FullAskDocument = gql`
    query FullAsk($id: Int!) {
  ask(id: $id) {
    id
    title
    description
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useFullAskQuery__
 *
 * To run a query within a React component, call `useFullAskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullAskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullAskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullAskQuery(baseOptions: Apollo.QueryHookOptions<FullAskQuery, FullAskQueryVariables> & ({ variables: FullAskQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FullAskQuery, FullAskQueryVariables>(FullAskDocument, options);
      }
export function useFullAskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullAskQuery, FullAskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FullAskQuery, FullAskQueryVariables>(FullAskDocument, options);
        }
export function useFullAskSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FullAskQuery, FullAskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FullAskQuery, FullAskQueryVariables>(FullAskDocument, options);
        }
export type FullAskQueryHookResult = ReturnType<typeof useFullAskQuery>;
export type FullAskLazyQueryHookResult = ReturnType<typeof useFullAskLazyQuery>;
export type FullAskSuspenseQueryHookResult = ReturnType<typeof useFullAskSuspenseQuery>;
export type FullAskQueryResult = Apollo.QueryResult<FullAskQuery, FullAskQueryVariables>;
export const FullListingDocument = gql`
    query FullListing($id: Int!) {
  listing(id: $id) {
    id
    title
    description
    availability
    minQty
    operation {
      id
      name
      email
      mailingAddressCounty
      physicalAddressCounty
      mailingAddressState
      physicalAddressState
      mailingAddressCountry
      physicalAddressCountry
      certifierName
    }
  }
}
    `;

/**
 * __useFullListingQuery__
 *
 * To run a query within a React component, call `useFullListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullListingQuery(baseOptions: Apollo.QueryHookOptions<FullListingQuery, FullListingQueryVariables> & ({ variables: FullListingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FullListingQuery, FullListingQueryVariables>(FullListingDocument, options);
      }
export function useFullListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FullListingQuery, FullListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FullListingQuery, FullListingQueryVariables>(FullListingDocument, options);
        }
export function useFullListingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FullListingQuery, FullListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FullListingQuery, FullListingQueryVariables>(FullListingDocument, options);
        }
export type FullListingQueryHookResult = ReturnType<typeof useFullListingQuery>;
export type FullListingLazyQueryHookResult = ReturnType<typeof useFullListingLazyQuery>;
export type FullListingSuspenseQueryHookResult = ReturnType<typeof useFullListingSuspenseQuery>;
export type FullListingQueryResult = Apollo.QueryResult<FullListingQuery, FullListingQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    clerkUserId
    id
    organizations {
      id
      operations {
        id
        name
        products {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetStripeProductsDocument = gql`
    query GetStripeProducts {
  stripeProducts {
    id
    name
    description
    priceId
    unitAmount
    recurring
  }
}
    `;

/**
 * __useGetStripeProductsQuery__
 *
 * To run a query within a React component, call `useGetStripeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeProductsQuery, GetStripeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeProductsQuery, GetStripeProductsQueryVariables>(GetStripeProductsDocument, options);
      }
export function useGetStripeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeProductsQuery, GetStripeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeProductsQuery, GetStripeProductsQueryVariables>(GetStripeProductsDocument, options);
        }
export function useGetStripeProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStripeProductsQuery, GetStripeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStripeProductsQuery, GetStripeProductsQueryVariables>(GetStripeProductsDocument, options);
        }
export type GetStripeProductsQueryHookResult = ReturnType<typeof useGetStripeProductsQuery>;
export type GetStripeProductsLazyQueryHookResult = ReturnType<typeof useGetStripeProductsLazyQuery>;
export type GetStripeProductsSuspenseQueryHookResult = ReturnType<typeof useGetStripeProductsSuspenseQuery>;
export type GetStripeProductsQueryResult = Apollo.QueryResult<GetStripeProductsQuery, GetStripeProductsQueryVariables>;
export const ListingsDocument = gql`
    query Listings($operationId: Int) {
  listings(operationId: $operationId) {
    id
    title
    description
    createdAt
  }
}
    `;

/**
 * __useListingsQuery__
 *
 * To run a query within a React component, call `useListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsQuery({
 *   variables: {
 *      operationId: // value for 'operationId'
 *   },
 * });
 */
export function useListingsQuery(baseOptions?: Apollo.QueryHookOptions<ListingsQuery, ListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
      }
export function useListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingsQuery, ListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
        }
export function useListingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListingsQuery, ListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListingsQuery, ListingsQueryVariables>(ListingsDocument, options);
        }
export type ListingsQueryHookResult = ReturnType<typeof useListingsQuery>;
export type ListingsLazyQueryHookResult = ReturnType<typeof useListingsLazyQuery>;
export type ListingsSuspenseQueryHookResult = ReturnType<typeof useListingsSuspenseQuery>;
export type ListingsQueryResult = Apollo.QueryResult<ListingsQuery, ListingsQueryVariables>;
export const ListingsFullTextSearchDocument = gql`
    query ListingsFullTextSearch($query: String!, $stateQuery: String, $limit: Int, $page: Int) {
  fulltextSearchListings(
    query: $query
    stateQuery: $stateQuery
    limit: $limit
    page: $page
  ) {
    totalPages
    totalResults
    listings {
      id
      title
      description
      minQty
      availability
      expirationPeriod
      createdAt
      operation {
        id
        name
        mailingAddressCounty
        physicalAddressCounty
        mailingAddressState
        physicalAddressState
        mailingAddressCountry
        physicalAddressCountry
      }
    }
  }
}
    `;

/**
 * __useListingsFullTextSearchQuery__
 *
 * To run a query within a React component, call `useListingsFullTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsFullTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsFullTextSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      stateQuery: // value for 'stateQuery'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListingsFullTextSearchQuery(baseOptions: Apollo.QueryHookOptions<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables> & ({ variables: ListingsFullTextSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>(ListingsFullTextSearchDocument, options);
      }
export function useListingsFullTextSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>(ListingsFullTextSearchDocument, options);
        }
export function useListingsFullTextSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>(ListingsFullTextSearchDocument, options);
        }
export type ListingsFullTextSearchQueryHookResult = ReturnType<typeof useListingsFullTextSearchQuery>;
export type ListingsFullTextSearchLazyQueryHookResult = ReturnType<typeof useListingsFullTextSearchLazyQuery>;
export type ListingsFullTextSearchSuspenseQueryHookResult = ReturnType<typeof useListingsFullTextSearchSuspenseQuery>;
export type ListingsFullTextSearchQueryResult = Apollo.QueryResult<ListingsFullTextSearchQuery, ListingsFullTextSearchQueryVariables>;
export const OperationDocument = gql`
    query Operation($id: Int!) {
  operation(id: $id) {
    id
    name
    certificationStatus
    certifierName
    scope
    mailingAddressCounty
    physicalAddressCounty
    mailingAddressState
    physicalAddressState
    mailingAddressCountry
    physicalAddressCountry
    email
    url
    phone
    products {
      name
    }
    operationId
    items {
      id
      products {
        name
      }
    }
  }
}
    `;

/**
 * __useOperationQuery__
 *
 * To run a query within a React component, call `useOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOperationQuery(baseOptions: Apollo.QueryHookOptions<OperationQuery, OperationQueryVariables> & ({ variables: OperationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationQuery, OperationQueryVariables>(OperationDocument, options);
      }
export function useOperationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationQuery, OperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationQuery, OperationQueryVariables>(OperationDocument, options);
        }
export function useOperationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OperationQuery, OperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OperationQuery, OperationQueryVariables>(OperationDocument, options);
        }
export type OperationQueryHookResult = ReturnType<typeof useOperationQuery>;
export type OperationLazyQueryHookResult = ReturnType<typeof useOperationLazyQuery>;
export type OperationSuspenseQueryHookResult = ReturnType<typeof useOperationSuspenseQuery>;
export type OperationQueryResult = Apollo.QueryResult<OperationQuery, OperationQueryVariables>;
export const OperationContactInfoDocument = gql`
    query OperationContactInfo($id: Int!, $hcaptchaResponse: String!) {
  operationContactInfo(id: $id, hcaptchaResponse: $hcaptchaResponse) {
    url
    email
    phone
  }
}
    `;

/**
 * __useOperationContactInfoQuery__
 *
 * To run a query within a React component, call `useOperationContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationContactInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hcaptchaResponse: // value for 'hcaptchaResponse'
 *   },
 * });
 */
export function useOperationContactInfoQuery(baseOptions: Apollo.QueryHookOptions<OperationContactInfoQuery, OperationContactInfoQueryVariables> & ({ variables: OperationContactInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationContactInfoQuery, OperationContactInfoQueryVariables>(OperationContactInfoDocument, options);
      }
export function useOperationContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationContactInfoQuery, OperationContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationContactInfoQuery, OperationContactInfoQueryVariables>(OperationContactInfoDocument, options);
        }
export function useOperationContactInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OperationContactInfoQuery, OperationContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OperationContactInfoQuery, OperationContactInfoQueryVariables>(OperationContactInfoDocument, options);
        }
export type OperationContactInfoQueryHookResult = ReturnType<typeof useOperationContactInfoQuery>;
export type OperationContactInfoLazyQueryHookResult = ReturnType<typeof useOperationContactInfoLazyQuery>;
export type OperationContactInfoSuspenseQueryHookResult = ReturnType<typeof useOperationContactInfoSuspenseQuery>;
export type OperationContactInfoQueryResult = Apollo.QueryResult<OperationContactInfoQuery, OperationContactInfoQueryVariables>;
export const OperationsFullTextSearchDocument = gql`
    query OperationsFullTextSearch($query: String!, $stateQuery: String, $limit: Int, $page: Int, $handling: Boolean) {
  fulltextSearchOperations(
    query: $query
    stateQuery: $stateQuery
    limit: $limit
    page: $page
    handling: $handling
  ) {
    totalPages
    totalResults
    operations {
      id
      name
      certificationStatus
      certifierName
      scope
      mailingAddressCounty
      physicalAddressCounty
      mailingAddressState
      physicalAddressState
      mailingAddressCountry
      physicalAddressCountry
      email
      url
      phone
      products {
        name
      }
    }
  }
}
    `;

/**
 * __useOperationsFullTextSearchQuery__
 *
 * To run a query within a React component, call `useOperationsFullTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsFullTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationsFullTextSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      stateQuery: // value for 'stateQuery'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      handling: // value for 'handling'
 *   },
 * });
 */
export function useOperationsFullTextSearchQuery(baseOptions: Apollo.QueryHookOptions<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables> & ({ variables: OperationsFullTextSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>(OperationsFullTextSearchDocument, options);
      }
export function useOperationsFullTextSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>(OperationsFullTextSearchDocument, options);
        }
export function useOperationsFullTextSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>(OperationsFullTextSearchDocument, options);
        }
export type OperationsFullTextSearchQueryHookResult = ReturnType<typeof useOperationsFullTextSearchQuery>;
export type OperationsFullTextSearchLazyQueryHookResult = ReturnType<typeof useOperationsFullTextSearchLazyQuery>;
export type OperationsFullTextSearchSuspenseQueryHookResult = ReturnType<typeof useOperationsFullTextSearchSuspenseQuery>;
export type OperationsFullTextSearchQueryResult = Apollo.QueryResult<OperationsFullTextSearchQuery, OperationsFullTextSearchQueryVariables>;
export const OrganizationContactInfoDocument = gql`
    query OrganizationContactInfo($id: Int!, $hcaptchaResponse: String!) {
  organizationContactInfo(id: $id, hcaptchaResponse: $hcaptchaResponse) {
    url
    email
    phone
  }
}
    `;

/**
 * __useOrganizationContactInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationContactInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      hcaptchaResponse: // value for 'hcaptchaResponse'
 *   },
 * });
 */
export function useOrganizationContactInfoQuery(baseOptions: Apollo.QueryHookOptions<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables> & ({ variables: OrganizationContactInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>(OrganizationContactInfoDocument, options);
      }
export function useOrganizationContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>(OrganizationContactInfoDocument, options);
        }
export function useOrganizationContactInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>(OrganizationContactInfoDocument, options);
        }
export type OrganizationContactInfoQueryHookResult = ReturnType<typeof useOrganizationContactInfoQuery>;
export type OrganizationContactInfoLazyQueryHookResult = ReturnType<typeof useOrganizationContactInfoLazyQuery>;
export type OrganizationContactInfoSuspenseQueryHookResult = ReturnType<typeof useOrganizationContactInfoSuspenseQuery>;
export type OrganizationContactInfoQueryResult = Apollo.QueryResult<OrganizationContactInfoQuery, OrganizationContactInfoQueryVariables>;
export const SendEmailBusinessVerificationDocument = gql`
    mutation SendEmailBusinessVerification($clerkUserId: String!, $clerkOrganizationId: String!, $image: Upload!) {
  sendEmailBusinessVerification(
    input: {clerkUserId: $clerkUserId, clerkOrganizationId: $clerkOrganizationId, image: $image}
  )
}
    `;
export type SendEmailBusinessVerificationMutationFn = Apollo.MutationFunction<SendEmailBusinessVerificationMutation, SendEmailBusinessVerificationMutationVariables>;

/**
 * __useSendEmailBusinessVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailBusinessVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailBusinessVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailBusinessVerificationMutation, { data, loading, error }] = useSendEmailBusinessVerificationMutation({
 *   variables: {
 *      clerkUserId: // value for 'clerkUserId'
 *      clerkOrganizationId: // value for 'clerkOrganizationId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useSendEmailBusinessVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailBusinessVerificationMutation, SendEmailBusinessVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailBusinessVerificationMutation, SendEmailBusinessVerificationMutationVariables>(SendEmailBusinessVerificationDocument, options);
      }
export type SendEmailBusinessVerificationMutationHookResult = ReturnType<typeof useSendEmailBusinessVerificationMutation>;
export type SendEmailBusinessVerificationMutationResult = Apollo.MutationResult<SendEmailBusinessVerificationMutation>;
export type SendEmailBusinessVerificationMutationOptions = Apollo.BaseMutationOptions<SendEmailBusinessVerificationMutation, SendEmailBusinessVerificationMutationVariables>;
export const StripeSubscriptionsDocument = gql`
    query StripeSubscriptions {
  stripeSubscriptions {
    customer
    currency
    id
    livemode
    status
    currentPeriodStart
    currentPeriodEnd
    collectionMethod
    canceledAt
  }
}
    `;

/**
 * __useStripeSubscriptionsQuery__
 *
 * To run a query within a React component, call `useStripeSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>(StripeSubscriptionsDocument, options);
      }
export function useStripeSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>(StripeSubscriptionsDocument, options);
        }
export function useStripeSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>(StripeSubscriptionsDocument, options);
        }
export type StripeSubscriptionsQueryHookResult = ReturnType<typeof useStripeSubscriptionsQuery>;
export type StripeSubscriptionsLazyQueryHookResult = ReturnType<typeof useStripeSubscriptionsLazyQuery>;
export type StripeSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useStripeSubscriptionsSuspenseQuery>;
export type StripeSubscriptionsQueryResult = Apollo.QueryResult<StripeSubscriptionsQuery, StripeSubscriptionsQueryVariables>;
export const UpdateOperationDocument = gql`
    mutation UpdateOperation($id: Int!, $name: String!, $certifierName: String!, $url: String, $contactFirstName: String, $contactLastName: String, $phone: String, $email: String) {
  updateOperation(
    input: {id: $id, name: $name, certifierName: $certifierName, url: $url, contactFirstName: $contactFirstName, contactLastName: $contactLastName, phone: $phone, email: $email}
  ) {
    id
    name
    certificationStatus
    certifierName
    scope
    mailingAddressCounty
    physicalAddressCounty
    mailingAddressState
    physicalAddressState
    mailingAddressCountry
    physicalAddressCountry
    email
    url
    phone
    products {
      name
    }
  }
}
    `;
export type UpdateOperationMutationFn = Apollo.MutationFunction<UpdateOperationMutation, UpdateOperationMutationVariables>;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationMutation, { data, loading, error }] = useUpdateOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      certifierName: // value for 'certifierName'
 *      url: // value for 'url'
 *      contactFirstName: // value for 'contactFirstName'
 *      contactLastName: // value for 'contactLastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateOperationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperationMutation, UpdateOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperationMutation, UpdateOperationMutationVariables>(UpdateOperationDocument, options);
      }
export type UpdateOperationMutationHookResult = ReturnType<typeof useUpdateOperationMutation>;
export type UpdateOperationMutationResult = Apollo.MutationResult<UpdateOperationMutation>;
export type UpdateOperationMutationOptions = Apollo.BaseMutationOptions<UpdateOperationMutation, UpdateOperationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($clerkOrganizationId: String!, $email: String!, $phone: String!, $url: String!) {
  updateOrganization(
    input: {clerkOrganizationId: $clerkOrganizationId, email: $email, phone: $phone, url: $url}
  ) {
    id
    clerkOrganizationId
    phone
    email
    url
    name
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      clerkOrganizationId: // value for 'clerkOrganizationId'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const LinkSquareMerchantDocument = gql`
    mutation LinkSquareMerchant($input: LinkSquareMerchantInput!) {
  linkSquareMerchant(input: $input)
}
    `;
export type LinkSquareMerchantMutationFn = Apollo.MutationFunction<LinkSquareMerchantMutation, LinkSquareMerchantMutationVariables>;

/**
 * __useLinkSquareMerchantMutation__
 *
 * To run a mutation, you first call `useLinkSquareMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkSquareMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkSquareMerchantMutation, { data, loading, error }] = useLinkSquareMerchantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkSquareMerchantMutation(baseOptions?: Apollo.MutationHookOptions<LinkSquareMerchantMutation, LinkSquareMerchantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkSquareMerchantMutation, LinkSquareMerchantMutationVariables>(LinkSquareMerchantDocument, options);
      }
export type LinkSquareMerchantMutationHookResult = ReturnType<typeof useLinkSquareMerchantMutation>;
export type LinkSquareMerchantMutationResult = Apollo.MutationResult<LinkSquareMerchantMutation>;
export type LinkSquareMerchantMutationOptions = Apollo.BaseMutationOptions<LinkSquareMerchantMutation, LinkSquareMerchantMutationVariables>;