import React from "react";
import classNames from "classnames";
import { Button, ButtonSizes, ButtonStyleVariations } from "./Button";

enum ChipActiveStyles {
  GREEN,
  KHAKI,
  BROWN,
  CLAY,
}

enum ChipSizes {
  SMALL,
  LARGE,
}

interface IChipProps {
  children?: React.ReactNode;
  activeStyle?: ChipActiveStyles;
  active?: boolean;
  size?: ChipSizes;
  onClick?: (Event) => void;
}

function Chip({
  children,
  activeStyle,
  active = false,
  size = ChipSizes.SMALL,
  onClick,
}: IChipProps) {
  let selected;
  switch (activeStyle) {
    case ChipActiveStyles.GREEN:
      selected = ButtonStyleVariations.GREEN;
      break;
    case ChipActiveStyles.BROWN:
      selected = ButtonStyleVariations.BROWN;
      break;
    case ChipActiveStyles.CLAY:
      selected = ButtonStyleVariations.CLAY;
      break;
    case ChipActiveStyles.KHAKI:
      selected = ButtonStyleVariations.KHAKI;
      break;
    default:
      selected = ButtonStyleVariations.GREEN;
  }

  let chipSize;
  switch (size) {
    case ChipSizes.LARGE:
      chipSize = ButtonSizes.DEFAULT;
      break;
    default:
      chipSize = ButtonSizes.SMALL;
  }

  const style = active ? selected : ButtonStyleVariations.DESELECTED;
  const classes = classNames({
    chip: true,
    "button--prevent-hover": !onClick,
  });

  return (
    <Button
      size={chipSize}
      styleVariation={style}
      name="chip-select"
      onClick={onClick}
      className={classes}
    >
      {children}
    </Button>
  );
}

export { Chip, ChipActiveStyles, ChipSizes };
