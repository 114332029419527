import React, { useRef, useState } from "react";
import InputField, { InputLabel } from "../marketplace/InputField";
import { businessTypesData, countries } from "../../data";
import { useOrganization } from "@clerk/clerk-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { notifyUpdated } from "../Utils/notifyUpdated";
import { DefaultAvatar } from "./UserButton";
import classNames from "classnames";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useGetMeQuery } from "../../graphql/generated-types";

const CompanyInformation = () => {
  const { loading, error, data: meData } = useGetMeQuery();

  const operationProducts =
    meData?.me?.organizations?.[0]?.operations?.[0]?.products || [];

  return (
    <div className="p-4 md:p-6 max-w-2xl mx-auto">
      <div className="mb-8 flex flex-col">
        <h1 className="text-2xl font-medium text-gray-900">
          Organization Information
        </h1>
        <p className="text-base text-gray-500">
          This information will be displayed publicly so please be mindful of
          what you share.
        </p>
      </div>
      <div className="flex flex-col gap-8">
        <CompanyAddressForm />
        <hr className="border-gray-300" />
        <CompanyMoreDetailsForm />
        <hr className="border-gray-300" />
        <CompanyProductListForm
          products={operationProducts.map((product) => product.name)}
        />
      </div>
    </div>
  );
};

type CompanyAddressInputs = {
  name: string;
  orgLogo?: FileList;
  country: string;
  city: string;
  state: string;
  postalCode: string;
  website: string;
};

const CompanyAddressForm = () => {
  const { organization } = useOrganization();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyAddressInputs>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit: SubmitHandler<CompanyAddressInputs> = async ({
    name,
    orgLogo,
    country,
    city,
    state,
    postalCode,
    website,
  }) => {
    setLoading(true);

    try {
      await organization.update({ name });

      if (orgLogo?.[0]) {
        try {
          await organization.setLogo({ file: orgLogo[0] });
        } catch (error) {
          console.warn(error);
        }
      }

      // Here you would typically update other fields like country, city, etc.
      // This might involve a separate API call or updating the organization metadata

      notifyUpdated();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  if (!organization) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div>
        <InputLabel text="Company Logo" />
        <div className="flex gap-4 items-center">
          <DefaultAvatar defaultValue={imagePreview} type="organization" />
          <input
            type="file"
            {...register("orgLogo", {
              onChange: handleFileChange,
            })}
            ref={(e) => {
              register("orgLogo").ref(e);
              fileInputRef.current = e;
            }}
            className="hidden"
          />
          <button
            type="button"
            className="text-sm text-primary-700 px-2.5 py-1.5 rounded-full border border-primary-300 shadow-sm font-medium hover:bg-gray-50"
            onClick={() => fileInputRef?.current?.click()}
          >
            Change
          </button>
        </div>
      </div>
      <InputField
        label="Organization, Business, or Farm Name"
        defaultValue={organization.name}
        register={register("name", {
          required: true,
        })}
        error={errors.name?.message}
      />
      <InputField
        label="Country"
        required
        type="select"
        selectOptions={countries}
        register={register("country")}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <InputField label="City" register={register("city")} />
        <InputField label="State / Province" register={register("state")} />
        <InputField label="ZIP / Postal" register={register("postalCode")} />
      </div>
      <InputField label="Website (optional)" register={register("website")} />
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </form>
  );
};

type CompanyMoreDetailsInputs = {
  businessType: string[];
};

const CompanyMoreDetailsForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyMoreDetailsInputs>();

  const [loading, setLoading] = useState(false);

  const onSubmit: SubmitHandler<CompanyMoreDetailsInputs> = async ({
    businessType,
  }) => {
    setLoading(true);

    try {
      // Here you would typically update the business type
      // This might involve updating the organization metadata or a separate API call
      notifyUpdated();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div>
        <h2 className="text-xl text-gray-900 font-medium mb-2">
          Business type (check all that apply)
        </h2>
        <div className="flex flex-col gap-2.5">
          {businessTypesData.map((option, index) => (
            <label
              key={`business-type-${index}`}
              className="flex items-center text-base font-medium text-gray-700 cursor-pointer"
            >
              <input
                type="checkbox"
                name="businessType"
                value={`type-${index}`}
                className="mr-3 h-5 w-5"
                {...register("businessType")}
              />
              {option}
            </label>
          ))}
        </div>
      </div>
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </form>
  );
};

type CompanyProductListInputs = {
  products: string[];
};

const CompanyProductListForm = ({ products }: { products: string[] }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompanyProductListInputs>();

  const [loading, setLoading] = useState(false);
  const [newProducts, setNewProducts] = useState<string[]>([]);

  const onSubmit: SubmitHandler<CompanyProductListInputs> = async (data) => {
    if (!data.products) return;
    setLoading(true);

    try {
      setNewProducts([...newProducts, data.products[0]]);
      reset();
      // Here you would typically update the products list
      // This might involve updating the organization metadata or a separate API call
      notifyUpdated();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const removeTag = (indexToRemove: number) => {
    setNewProducts(newProducts.filter((_, index) => index !== indexToRemove));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
      <div>
        <h2 className="text-xl text-gray-900 font-medium mb-2">Product list</h2>
        <p className="text-base text-gray-500 mb-4">
          Keeping your product list up to date makes it easier for other
          businesses to find you.
        </p>
        <div>
          <h3 className="text-sm mb-1">Your products</h3>
          {products.length > 0 && (
            <div className="mb-2">
              <ul className="inline-flex flex-wrap gap-2">
                {products.map((product, index) => (
                  <li key={`${product}-${index}`}>
                    <span className="bg-primary-50 text-primary-800 font-medium px-3 py-0.5 rounded-full inline-block text-sm">
                      {product}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {newProducts.length > 0 && (
            <div className="flex gap-2 border-t pt-2 mb-4">
              {newProducts.map((product, index) => (
                <div
                  key={index}
                  className="rounded-full bg-gray-100 font-semibold px-2 py-1 text-xs flex items-center gap-1"
                >
                  {product}{" "}
                  <span
                    onClick={() => removeTag(index)}
                    className="hover:opacity-70 cursor-pointer"
                  >
                    <XMarkIcon className="size-4" />
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <textarea
          {...register("products")}
          placeholder="Enter a product and press 'Enter' to add it to your list"
          onKeyDown={handleKeyDown}
          rows={3}
          className="w-full border border-gray-300 rounded-md px-3 py-2 mt-1 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
        />
      </div>
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export { CompanyInformation };