import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function SearchFormQueryIcon(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full absolute left-[15px] pointer-events-none fill-primary-400"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2C4.29086 2 2.5 3.79086 2.5 6C2.5 8.20914 4.29086 10 6.5 10C8.70914 10 10.5 8.20914 10.5 6C10.5 3.79086 8.70914 2 6.5 2ZM0.5 6C0.5 2.68629 3.18629 0 6.5 0C9.81371 0 12.5 2.68629 12.5 6C12.5 7.29583 12.0892 8.49572 11.3907 9.47653L16.2071 14.2929C16.5976 14.6834 16.5976 15.3166 16.2071 15.7071C15.8166 16.0976 15.1834 16.0976 14.7929 15.7071L9.97653 10.8907C8.99572 11.5892 7.79583 12 6.5 12C3.18629 12 0.5 9.31371 0.5 6Z"
      />
    </svg>
  );
}

export default SearchFormQueryIcon;
