import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

export interface LinkType {
  icon: React.ReactNode;
  route: string;
  label: string;
}

interface SidebarProps {
  links: LinkType[];
}

const Sidebar = ({ links }: SidebarProps) => {
  const { pathname } = useLocation();
  if (!links.length) return;

  const showSelectMobile = pathname.includes("account");

  return (
    <>
      {showSelectMobile ? (
        <div className={classNames({ "md:hidden": showSelectMobile })}>
          <LinksDropdown links={links} />
        </div>
      ) : null}
      <div className={classNames({ "max-md:hidden": showSelectMobile })}>
        <LinksSidebar links={links} />
      </div>
    </>
  );
};

const LinksSidebar = ({ links }: SidebarProps) => {
  const { pathname } = useLocation();

  return (
    <nav>
      <ul className="flex md:flex-col gap-4 md:gap-1 py-4 overflow-x-scroll md:overflow-auto">
        {links.map((link) => {
          const linkClass = classNames({
            "flex items-center gap-3 font-semibold px-2 py-2 rounded-2lg text-sm text-primary-700 hover:text-primary-700 hover:bg-primary-50 [&_svg]:text-primary-400  text-nowrap":
              true,
            "text-primary-700 bg-primary-50 [&_svg]:text-primary-500":
              pathname === link.route ||
              (link.route === "/marketplace/list/selling" &&
                (pathname === "/marketplace" || pathname === "/marketplace/")),
          });

          return (
            <li key={link.route}>
              <Link to={link.route} className={linkClass}>
                {link.icon} {link.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const LinksDropdown = ({ links }: SidebarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    navigate(e.currentTarget.value);
  };

  return (
    <div className="py-4">
      <select
        title="Select Account Page"
        className="border border-primary-300 rounded-md px-3 py-2 shadow-sm w-full"
        onChange={handleChange}
        value={pathname}
      >
        {links.map((link) => (
          <option key={link.route} value={link.route}>
            {link.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Sidebar;
