import React, { useRef, useState } from "react";
import InputField, { InputLabel } from "../marketplace/InputField";
import { interestsData } from "../../data";
import { useUser } from "@clerk/clerk-react";
import { SubmitHandler, useForm } from "react-hook-form";
import { notifyUpdated } from "../Utils/notifyUpdated";
import { DefaultAvatar } from "./UserButton";
import classNames from "classnames";

const Account = () => {
  const { user } = useUser();
  if (!user) return null;

  return (
    <form className="p-4 md:p-6 max-w-2xl mx-auto">
      <div className="flex flex-col gap-8">
        <ProfileForm />
        <hr className="border-gray-300" />
        <InterestsForm />
        <hr className="border-gray-300" />
        <PasswordForm />
      </div>
    </form>
  );
};

type ProfileFormInputs = {
  firstName: string;
  lastName: string;
  userPhoto?: FileList;
};

const ProfileForm = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormInputs>();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const onSubmit: SubmitHandler<ProfileFormInputs> = async ({
    firstName,
    lastName,
    userPhoto,
  }) => {
    setLoading(true);

    try {
      await user.update({ firstName, lastName });

      if (userPhoto?.[0]) {
        try {
          await user.setProfileImage({ file: userPhoto[0] });
        } catch (error) {
          console.warn(error);
        }
      }

      notifyUpdated();
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <div>
        <InputLabel text="Photo" />
        <div className="flex gap-4 items-center">
          <DefaultAvatar defaultValue={imagePreview} type="user" />
          <input
            type="file"
            {...register("userPhoto", {
              onChange: handleFileChange,
            })}
            ref={(e) => {
              register("userPhoto").ref(e);
              fileInputRef.current = e;
            }}
            className="hidden"
          />
          <button
            type="button"
            className="text-sm text-primary-700 px-2.5 py-1.5 rounded-full border border-primary-300 shadow-sm font-medium hover:bg-gray-50"
            onClick={() => fileInputRef?.current?.click()}
          >
            Change
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="text-xl md:text-2xl">
          <InputField
            label="First name"
            name="firstName"
            defaultValue={user?.firstName}
            register={register("firstName", { required: true })}
            required
          />
        </div>
        <div className="text-xl md:text-2xl">
          <InputField
            label="Last name"
            name="lastName"
            defaultValue={user?.lastName}
            register={register("lastName", { required: true })}
            required
          />
        </div>
      </div>
      <div className="flex justify-end mt-2">
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </div>
  );
};

type InterestInputs = {
  interests: string[];
};

const InterestsForm = () => {
  const { user } = useUser();
  const [selectedInterests, setSelectedInterests] = useState<string[]>(() => {
    const userInterests = user?.unsafeMetadata?.interests;
    return Array.isArray(userInterests) ? userInterests : [];
  });
  const [loading, setLoading] = useState(false);

  const handleInterestChange = (value: string) => {
    setSelectedInterests((prev) =>
      prev.includes(value)
        ? prev.filter((interest) => interest !== value)
        : [...prev, value]
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      await user?.update({
        unsafeMetadata: { ...user.unsafeMetadata, interests: selectedInterests },
      });
      notifyUpdated();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-xl text-gray-700 font-medium mb-2">
          Your interests
        </h2>
        <p className="text-base text-gray-500">
          Please choose the areas that best describe how you plan to use
          Market.Organic.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {interestsData.map((option) => (
          <label
            key={option.value}
            className="flex items-center text-base font-medium text-gray-700 cursor-pointer"
          >
            <input
              type="checkbox"
              name="interests"
              value={option.value}
              className="mr-3 h-5 w-5"
              checked={selectedInterests.includes(option.value)}
              onChange={() => handleInterestChange(option.value)}
            />
            {option.label}
          </label>
        ))}
      </div>
      <div className="flex justify-end mt-6">
        <button
          type="button"
          onClick={onSubmit}
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </div>
  );
};

type PasswordFormInputs = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const PasswordForm = () => {
  const { user } = useUser();
  const [clerkError, setClerkError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<PasswordFormInputs>();

  const onSubmit: SubmitHandler<PasswordFormInputs> = async ({
    currentPassword,
    newPassword,
  }) => {
    setClerkError("");
    setLoading(true);

    try {
      await user.updatePassword({
        newPassword,
        currentPassword,
      });

      notifyUpdated();
      reset();
    } catch (err: any) {
      setClerkError(err.errors[0].message);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="mb-4">
        <h2 className="text-xl text-gray-700 font-medium mb-2">
          Change password
        </h2>
        <p className="text-base text-gray-500">
          Update your password associated with your account.
        </p>
      </div>
      <div className="text-xl md:text-2xl">
        <InputField
          label="Current password"
          name="currentPassword"
          type="password"
          register={register("currentPassword", {
            required: {
              value: true,
              message: "Password is required",
            },
          })}
          required
        />
      </div>
      <div className="text-xl md:text-2xl">
        <InputField
          label="New password"
          name="newPassword"
          type="password"
          register={register("newPassword", {
            required: {
              value: true,
              message: "Password is required",
            },
            minLength: {
              value: 8,
              message: "Passwords must be 8 characters or more.",
            },
          })}
          required
        />
      </div>
      <div className="text-xl md:text-2xl">
        <InputField
          label="Confirm password"
          name="confirmNewPassword"
          type="password"
          register={register("newPasswordConfirm", {
            required: {
              value: true,
              message: "Password is required",
            },
            validate: (value) =>
              value === getValues("newPassword") || "Passwords don't match",
          })}
          required
        />
      </div>
      {clerkError && (
        <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100">
          <p className="text-red-500 text-xs font-medium">{clerkError}</p>
        </div>
      )}
      <div className="flex justify-end mt-2">
        <button
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          className="bg-primary-600 hover:bg-primary-700 text-white px-4 py-2 rounded-full font-medium shadow-sm text-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export { Account };