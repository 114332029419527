import React, { useState } from "react";

// Components
import { OperationSearchField } from "../forms/OperationSearchField";

import { Button, ButtonStyleVariations } from "../Button";

function ClaimOperationSearch({ handleFindOperationByName }) {
  const [selectedOperation, setSelectedOperation] = useState(null);

  const handleSubmit = (e = null, operation) => {
    e.preventDefault();
    handleFindOperationByName(operation);
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e, selectedOperation)}>
        <p id="dialog-description" className="interface-text--supporting">
          Market.Organic allows Certified Organic farms and businesses to claim
          their own unique profiles. To get started, type in the name of your
          farm or business below.
        </p>
        <div className="claim-operation__fields">
          <OperationSearchField
            setSelectedOperation={setSelectedOperation}
            selectedOperation={selectedOperation}
          />
        </div>
        <div className="modal__controls claim-operation__buttons">
          {selectedOperation && (
            <Button
              name="find-operation"
              label="Next"
              styleVariation={ButtonStyleVariations.PRIMARY}
              disabled={!selectedOperation}
              onClick={(e) => handleSubmit(e, selectedOperation)}
            />
          )}
        </div>
      </form>
    </>
  );
}

export { ClaimOperationSearch };
