import React from "react";
import { OldCreateOrEditListingInput } from "../../services/graphql-custom-types";
import { InputField } from "../forms/InputField";
import { TextareaField } from "../forms/TeaxtareaField";
import { SelectField } from "../forms/SelectField";

function DetailStep({
  listing,
  handleChange,
}: {
  listing: OldCreateOrEditListingInput;
  handleChange: any;
}) {
  // Data requires a null minQty to be 0, so in the create component, we have to change an empty string value to 0, and then here convert it back to an empty string
  const derivedMinQty =
    listing.minQty && listing.minQty === "0" ? "" : listing.minQty;

  return (
    <div className="create-listing__fields">
      <InputField
        name="title"
        placeholder="New Listing"
        value={listing.title || ""}
        handleChange={handleChange}
        inputType="text"
        required
        autoComplete="off"
        minLength={3}
        maxLength={99}
      />
      <TextareaField
        label="Describe your listing"
        name="description"
        value={listing.description || ""}
        handleChange={handleChange}
        autoComplete="off"
        maxLength={500}
      />
      <div className="inline-fieldset inline-fieldset--input-select">
        <InputField
          name="minQty"
          placeholder="Minimum order quantity"
          value={derivedMinQty}
          handleChange={handleChange}
          inputType="number"
          autoComplete="tel"
          min={0}
        />
      </div>
    </div>
  );
}

export { DetailStep };
