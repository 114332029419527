import { makeVar } from "@apollo/client";
import { Cart, Listing, Variation } from "../../services/graphql-custom-types";

const savedCartString = localStorage.getItem("cart");
const savedCart = savedCartString && JSON.parse(savedCartString);

export const cartVar = makeVar(savedCart || {});

export function add(listing: Listing, variation: Variation, quantity: string) {
  const currentCart: Cart = cartVar();
  const currentOperationCart = currentCart[listing.operation.id] || {};
  const newCart: Cart = {
    ...currentCart,
    [listing.operation.id]: {
      ...currentOperationCart,
      [variation.id]: { listing, variation, quantity },
    },
  };

  cartVar(newCart);
  localStorage.setItem("cart", JSON.stringify(newCart));
}

export function remove(listing, variation) {
  const currentCart: Cart = cartVar();
  const newCart: Cart = { ...currentCart };

  delete newCart[listing.operation.id][variation.id];

  if (Object.keys(newCart[listing.operation.id]).length === 0)
    delete newCart[listing.operation.id];

  cartVar(newCart);
  localStorage.setItem("cart", JSON.stringify(newCart));
}

export function clear() {
  cartVar({});
  localStorage.removeItem("cart");
}
