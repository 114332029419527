import React, { useRef, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Keys from "../../config/Keys";
import { Button, ButtonSizes, ButtonStyleVariations } from "../Button";
import { InputField } from "../forms/InputField";
import { ListingDetailsWrapper } from "../Listings/ListingDetailsWrapper";
import { Cart as CartType } from "../../services/graphql-custom-types";
import { isMobile, Cart as CartUtils } from "../Utils";
import { useCreateCheckoutLinkMutation } from "../../graphql/generated-types";

function Cart() {
  const [createCheckoutLink] = useCreateCheckoutLinkMutation();
  const cart: CartType = useReactiveVar(CartUtils.cartVar);
  const operationIds = Object.keys(cart);

  async function checkout(operationId) {
    const products = Object.keys(cart[operationId]).map((variationId) => {
      const { quantity } = cart[operationId][variationId];

      return {
        id: variationId,
        quantity,
      };
    });

    const res = await createCheckoutLink({
      variables: {
        input: {
          operationId: Number(operationId),
          products,
        },
      },
    });

    window.open(res.data.createCheckoutLink, "_self");
  }

  const inputRef = useRef<HTMLInputElement>();
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    const searchQuery = inputRef.current.value;
    if (event.keyCode === Keys.KEY_CODES.ENTER) {
      if (!searchQuery) {
        return;
      }
      navigate(`/search/${searchQuery}`);
    }
  };

  useEffect(() => {
    window && window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  if (!operationIds || operationIds?.length < 1) {
    return (
      <main className="cart-view cart-view--empty main gutters">
        <h1 className="cart-view--empty__heading interface-text--heading--large">
          Your cart is empty
        </h1>
        <p className="cart-view--empty__description interface-text--body--large">
          Try searching for organic products, farms, and business
        </p>
        <div className="cart-view--empty__search-wrapper">
          <InputField
            isSearch
            ref={inputRef}
            placeholder="Search Market.Organic"
            inputClass="cart-view--empty__search-input"
          />
        </div>
      </main>
    );
  }

  return (
    <main className="cart-view main gutters">
      {operationIds.map((operationId, i) => {
        const cartItems = cart[operationId];
        const cartItemIds = Object.keys(cart[operationId]);
        const operation = cartItems[cartItemIds[0]].listing.operation;

        return (
          <section className="cart-operation" key={i}>
            <h1 className="interface-text--heading cart-operation__heading">
              Your cart with {operation.name}
            </h1>
            {cartItemIds.map((itemId, j) => {
              const { listing, variation, quantity } = cartItems[itemId];

              return (
                <ListingDetailsWrapper
                  key={j}
                  listing={listing}
                  cartView
                  cartVariation={variation}
                  cartQuantity={quantity}
                />
              );
            })}
            <Button
              name="square-checkout"
              className="cart-operation__checkout"
              styleVariation={ButtonStyleVariations.PRIMARY}
              size={isMobile() && ButtonSizes.DEFAULT_FULL_WIDTH}
              label="Checkout with Square"
              onClick={() => checkout(operationId)}
            />
          </section>
        );
      })}
    </main>
  );
}

export { Cart };
