import React from "react";
import { listingType } from "../views/MarketplaceCreateOrEdit";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { listingTypeData } from "../../data";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

interface StepProp {
  listingType: listingType;
  onChangeListing: (type: string) => void;
}

const Step1 = ({ listingType, onChangeListing }: StepProp) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="border-b pb-4 mb-6 flex justify-between items-center">
        <h1 className="font-semibold">Choose Listing Type</h1>
      </div>

      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {listingTypeData.map((listing) => {
            const selectBoxClass = classNames({
              "relative py-5 px-6 md:py-6 md:px-8 border border-primary-300 shadow-sm rounded-lg flex justify-between hover:bg-gray-50 cursor-pointer":
                true,
              "outline outline-2 outline-primary-600 shadow-none":
                listing.slug === listingType,
            });

            return (
              <div
                className={selectBoxClass}
                onClick={() => onChangeListing(listing.slug)}
                key={listing.slug}
              >
                <div>
                  <div className="text-lg font-semibold mb-2">
                    {listing.label}
                  </div>
                  <div className="max-w-[220px] leading-normal text-gray-500">
                    {listing.description}
                  </div>
                </div>
                {listing.slug === listingType ? (
                  <div className="absolute top-4 right-4">
                    <CheckCircleIcon className="size-7 text-primary-400" />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex justify-end mt-6">
        <button
          className="bg-primary-600 hover:bg-primary-700 text-white px-6 py-3 rounded-full font-semibold shadow-sm"
          onClick={() => navigate(`?listingType=${listingType}`)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Step1;
