/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import classNames from "classnames";
import { Button, ButtonStyleVariations } from "../Button";
import { Input } from "./Input";
import SearchIcon from "../icons/Search";

interface InputFieldProps {
  name?: string;
  label?: string;
  inputType?: string;
  placeholder?: string;
  value?: string;
  inputClass?: string;
  labelClass?: string;
  readOnly?: boolean;
  disabled?: boolean;
  hasInlineAction?: boolean;
  inlineAction?: () => void;
  actionLabel?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isSearch?: boolean;
  actionDisabled?: boolean;
  required?: boolean;
  autoComplete?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  children?: React.ReactNode;
  rows?: number;
}

const InputField = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, InputFieldProps>(
  (props, ref) => {
    const {
      name,
      label,
      inputType = "text",
      placeholder,
      value,
      inputClass,
      labelClass,
      readOnly = false,
      disabled,
      hasInlineAction = false,
      actionLabel,
      inlineAction,
      handleChange,
      onKeyUp,
      isSearch = false,
      actionDisabled = false,
      required,
      autoComplete,
      min,
      max,
      minLength,
      maxLength,
      rows,
    } = props;

    const inputClasses = classNames(inputClass, {
      "input-field__input": true,
      "input-field": !label,
    });

    const labelClasses = classNames(labelClass, {
      "input-field__label": true,
      "interface-text--label": true,
    });

    const inputElement = inputType === "textarea" ? (
      <textarea
        name={name}
        ref={ref as React.Ref<HTMLTextAreaElement>}
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        rows={rows || 4}
      />
    ) : (
      <Input
        name={name}
        ref={ref as React.Ref<HTMLInputElement>}
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        handleChange={handleChange}
        onKeyUp={onKeyUp}
        inputType={isSearch ? "search" : inputType}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        min={min}
        max={max}
        minLength={minLength}
        maxLength={maxLength}
      />
    );

    let inputArea = inputElement;

    if (hasInlineAction) {
      inputArea = (
        <div className="input-field__with-action">
          {inputElement}
          <Button
            name="input-field-inline-action"
            className="input-field__inline-action"
            label={actionLabel}
            styleVariation={ButtonStyleVariations.PRIMARY}
            onClick={inlineAction}
            disabled={actionDisabled}
          />
        </div>
      );
    } else if (isSearch) {
      inputArea = (
        <div className="input-field--is-search">
          <SearchIcon className="input-field__search-icon" />
          {inputElement}
        </div>
      );
    }

    if (label) {
      return (
        <label className="input-field">
          <span className={labelClasses}>{label}</span>
          {inputArea}
        </label>
      );
    }

    return inputArea;
  }
);

export { InputField };