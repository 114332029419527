import React, { useState } from "react";
import { Modal, ModalSizes } from "../modals/Modal";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  useOperationContactInfoQuery,
  useOrganizationContactInfoQuery,
} from "../../graphql/generated-types";

function OperationContactModal({
  operationId,
  queryType = "operation",
  show = false,
  handleClose,
}) {
  const [hcaptchaResponse, setHcaptchaResponse] = useState("");
  const [contactInfo, setContactInfo] = useState(null);

  useOperationContactInfoQuery({
    variables: { id: operationId, hcaptchaResponse: hcaptchaResponse },
    skip: !hcaptchaResponse || queryType !== "operation",
    onCompleted: (data) => {
      setContactInfo(data?.operationContactInfo);
    },
  });

  useOrganizationContactInfoQuery({
    variables: { id: operationId, hcaptchaResponse: hcaptchaResponse },
    skip: !hcaptchaResponse || queryType === "operation",
    onCompleted: (data) => {
      setContactInfo(data?.organizationContactInfo);
    },
  });

  const handleVerificationSuccess = (token: string) => {
    setHcaptchaResponse(token);
  };

  if (!show) return;

  return (
    <Modal
      size={ModalSizes.LARGE}
      className="contact-operation"
      handleClose={handleClose}
      useCloseIcon
      title="Contact"
    >
      {contactInfo ? (
        <div className="flex flex-col gap-5">
          {contactInfo.email ? (
            <div>
              <div className="text-lg font-semibold">Email</div>
              <div>{contactInfo.email}</div>
            </div>
          ) : null}

          {contactInfo.phone ? (
            <div>
              <div className="text-lg font-semibold">Phone</div>
              <div>{contactInfo.phone}</div>
            </div>
          ) : null}
        </div>
      ) : (
        <HCaptcha
          sitekey="80075ab5-edda-4f86-b367-6ae57cbcd8c1" // TODO: Don't hardcode this
          onVerify={(token) => handleVerificationSuccess(token)}
        />
      )}
    </Modal>
  );
}

export { OperationContactModal };
