import React from "react";
import { Chip, ChipActiveStyles } from "./Chip";
import classNames from "classnames";

interface IChipSelectProps {
  activeStyle?: ChipActiveStyles;
  single?: any;
  multiple?: any;
  selected?: any;
  className?: string;
  handleChange?: (Event) => void;
}

/*
 *  For radio type behavior (only one selection at a time),
 *  pass an array of [{key: label}, ...] as the 'single' prop.
 *
 *  For checkbox behavior (multiple selections possible),
 *  pass an array of [{key: label}, ...] as the 'multiple' prop.
 *
 *  When both are present, selecting single options clears
 *  any multiple options, like in the following example
 *  <ChipSelect
 *    multiple={red: 'Red', green: 'Green', blue: 'Blue'}
 *    single={none: 'None of the above'}
 *  />
 */
function ChipSelect({
  activeStyle,
  single = {},
  multiple = {},
  handleChange,
  selected = {},
  className,
}: IChipSelectProps) {
  const classes = classNames(className, {
    "chip-select": true,
  });
  const handleSingle = (key) => {
    // clear all selections
    const newSelected = {};
    // if old value was false or missing
    if (!selected[key]) {
      newSelected[key] = true;
    }
    handleChange(newSelected);
  };

  const handleMultiple = (key) => {
    const newSelected = { ...selected };
    // if old value was false or missing
    if (!selected[key]) {
      newSelected[key] = true;
    } else {
      // deselect this option
      delete newSelected[key];
    }
    // cancel selections of any "single" elements
    Object.keys(single).map((k) => {
      delete newSelected[k];
    });
    handleChange(newSelected);
  };

  return (
    <ul className={classes}>
      {Object.keys(multiple).map((key) => (
        <Chip
          key={key}
          active={selected[key] ? true : false}
          activeStyle={activeStyle}
          onClick={() => handleMultiple(key)}
        >
          {multiple[key]}
        </Chip>
      ))}
      {Object.keys(single).map((key) => (
        <Chip
          key={key}
          active={selected[key] ? true : false}
          activeStyle={activeStyle}
          onClick={() => handleSingle(key)}
        >
          {single[key]}
        </Chip>
      ))}
    </ul>
  );
}

export { ChipSelect, ChipActiveStyles };
