import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, Navigate } from "react-router-dom";

function AuthorizeView() {
  const location = useLocation();
  // const { isAuthenticated } = useAuth0();
  const isAuthenticated = true; // TODO: Change me

  const searchParams = new URLSearchParams(location.search);
  const urlRedirect = decodeURIComponent(searchParams.get("redirectUrl"));

  if (isAuthenticated) {
    return <Navigate to={`${urlRedirect}`} replace />;
  }

  return <div>Authorizing...</div>;
}

export { AuthorizeView };
