import React, { useState } from "react";
import classNames from "classnames";
import {
  OperationHeader,
  OperationProfileAdmin,
  OperationProductsAdmin,
} from ".";

enum TabStates {
  profileInfo,
  productList,
}

function OperationEditProfileView({ operation, userOwnsOperation }) {
  const [currentTab, setCurrentTab] = useState<TabStates>(
    TabStates.profileInfo,
  );
  const menuClasses = classNames({
    tabs__item: true,
    "interface-text--label--large": true,
  });

  if (!operation) {
    return (
      <p className="interface-text--supporting">
        Sorry, we couldn't find that operation. Please go back and try again.
      </p>
    );
  }

  const renderOperationProfileAdmin = () => {
    if (currentTab !== TabStates.profileInfo) return null;
    return <OperationProfileAdmin operation={operation} />;
  };

  const renderOperationProductsAdmin = () => {
    if (currentTab !== TabStates.productList) return null;
    return <OperationProductsAdmin operation={operation} />;
  };

  return (
    <>
      <main className="operation-view operation-owner-view main">
        <OperationHeader
          operation={operation}
          userOwnsOperation={userOwnsOperation}
          isEditView
        />

        <div className="gutters">
          <ol className="tabs verified-publish-steps">
            <li
              className={`${menuClasses} ${
                currentTab === TabStates.profileInfo && "tabs__item--active"
              }`}
              onClick={() => setCurrentTab(TabStates.profileInfo)}
            >
              Profile information
            </li>
            <li
              className={`${menuClasses} ${
                currentTab === TabStates.productList && "tabs__item--active"
              }`}
              onClick={() => setCurrentTab(TabStates.productList)}
            >
              Product list
            </li>
          </ol>

          <div className="verified__content">
            {renderOperationProfileAdmin()}
            {renderOperationProductsAdmin()}
          </div>
        </div>
      </main>
    </>
  );
}

export { OperationEditProfileView };
