import React, { useEffect, useState } from "react";
import { Modal } from "../modals/Modal";
import { InputField } from "../forms/InputField";

function EditProductModal({
  selectedProduct,
  showModal,
  handleClose,
  handleSubmit,
}) {
  const [isRemove, setIsRemove] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editedProduct, setEditProduct] = useState(selectedProduct || "");

  useEffect(() => {
    setEditProduct(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    if (!showModal) {
      setIsRemove(false);
      setIsEdit(false);
    }
  }, [showModal]);

  const handleOnChange = (e) => {
    if (!isEdit) {
      setIsEdit(true);
    }
    setEditProduct(e.target.value);
  };

  const handleRemove = () => {
    setEditProduct(selectedProduct);
    setIsRemove(true);
  };

  if (!showModal) return null;

  return (
    <Modal
      title="Edit Product"
      closeLabel="Cancel"
      submitLabel={isRemove ? "Remove" : "Save"}
      submitDisabled={!isRemove && !isEdit}
      handleSubmit={() => handleSubmit(editedProduct, isRemove)}
      handleClose={handleClose}
    >
      <InputField
        value={editedProduct}
        handleChange={handleOnChange}
        hasInlineAction
        inlineAction={handleRemove}
        actionLabel="X"
        actionDisabled={isRemove}
        readOnly={isRemove}
      />
    </Modal>
  );
}

export { EditProductModal };
