import React from "react";
import PropTypes from "prop-types";

function ScopeList({ operation }) {
  if (!operation.scope || operation.scope.length === 0) return "--";

  return operation.scope.map((scopeItem, i) => (
    <span key={scopeItem.id || i}>
      {operation.scope.length - 1 === i ? scopeItem : `${scopeItem}, `}
    </span>
  ));
}

ScopeList.propTypes = {
  operation: PropTypes.object.isRequired,
};

export { ScopeList };
