import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function EmailIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M3.3999 8.40002L11.2905 13.6604C11.9623 14.1083 12.8375 14.1083 13.5093 13.6604L21.3999 8.40002M5.3999 19.4H19.3999C20.5045 19.4 21.3999 18.5046 21.3999 17.4V7.40002C21.3999 6.29545 20.5045 5.40002 19.3999 5.40002H5.3999C4.29533 5.40002 3.3999 6.29545 3.3999 7.40002V17.4C3.3999 18.5046 4.29533 19.4 5.3999 19.4Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
          />
        </React.Fragment>
      )}
    />
  );
}

EmailIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

EmailIcon.defaultProps = {
  className: null,
};

export default EmailIcon;
