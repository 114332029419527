import React from "react";
import classNames from "classnames";

enum LinkStyleVariations {
  NONE,
  INVERSE,
  LABEL,
  OVERLAY,
  BUTTON_PRIMARY,
  BUTTON_SECONDARY,
  BUTTON_SECONDARY_INVERSE,
}

enum LinkSizes {
  DEFAULT,
  DEFAULT_FULL_WIDTH,
}

interface ILinkProps {
  className?: string;
  href: string;
  openInNewWindow?: boolean;
  children?: React.ReactNode;
  styleVariation?: LinkStyleVariations;
  size?: LinkSizes;
}

function Link({
  className = "",
  href,
  openInNewWindow = false,
  children,
  styleVariation = LinkStyleVariations.NONE,
  size = LinkSizes.DEFAULT,
}: ILinkProps) {
  const classes = classNames(className, "link", {
    "link--label": styleVariation === LinkStyleVariations.LABEL,
    "link--label--overlay": styleVariation === LinkStyleVariations.OVERLAY,
    "link--button-primary":
      styleVariation === LinkStyleVariations.BUTTON_PRIMARY,
    "link--button-secondary":
      styleVariation === LinkStyleVariations.BUTTON_SECONDARY,
    "link--button-secondary-inverse":
      styleVariation === LinkStyleVariations.BUTTON_SECONDARY_INVERSE,
    "button--full-width": size === LinkSizes.DEFAULT_FULL_WIDTH,
  });

  return (
    <a
      className={classes}
      target={openInNewWindow ? "_blank" : null}
      rel={openInNewWindow ? "noopener noreferrer" : null}
      href={href}
    >
      {children}
    </a>
  );
}

export { Link, LinkStyleVariations, LinkSizes };
