import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export const setupSentry = () => {
  // if (process.env.NODE_ENV === "development") {
  //   return;
  // }

  Sentry.init({
    dsn: "https://fff8bfbbe450c3e13ca01b8ade700b0c@o1007663.ingest.us.sentry.io/4507953698570240",
    environment: process.env.NODE_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/market\.organic\/graphql/,
    ],
    debug: false,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};
