import React, { useState, useRef } from "react";
import { InputField } from "../forms/InputField";
import { Button, ButtonStyleVariations } from "../Button";
import { Toast } from "../Toast";
import {
  UpdateOperationMutationVariables,
  useUpdateOperationMutation,
} from "../../graphql/generated-types";

function OperationProfileAdmin(props) {
  const { operation } = props;
  const { scope, certificationStatus } = operation;
  const ci_scope =
    Array.isArray(scope) && scope.length > 0 ? scope[0] : undefined; // tempprary
  const [showUpdateOperationToast, setShowUpdateOperationToast] =
    useState(false);
  const [updatedOperation, setUpdatedOperation] =
    useState<UpdateOperationMutationVariables>({
      id: operation.id,
      name: operation.name || "",
      certifierName: operation.certifierName || "",
      email: operation.email || "",
      phone: operation.phone || "",
    });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newOperation = {
      ...updatedOperation,
      [name]: value,
    };
    setUpdatedOperation(newOperation);
  };

  const formRef = useRef(null);

  // call HTML5 validations on form elements
  const validateForm = () => {
    return formRef.current.reportValidity();
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    updateOperation({
      variables: updatedOperation,
    });
  };

  const [updateOperation, { loading }] = useUpdateOperationMutation({
    onCompleted: () => {
      setShowUpdateOperationToast(true);
      setTimeout(() => {
        setShowUpdateOperationToast(false);
      }, 3000);
    },
  });

  const renderUpdateOperationToast = () => {
    if (!showUpdateOperationToast) return null;
    return (
      <Toast
        handleClose={() =>
          setShowUpdateOperationToast(!showUpdateOperationToast)
        }
      >
        <>Your profile info was updated successfully</>
      </Toast>
    );
  };

  return (
    <form
      ref={formRef}
      className="verified__edit-profile"
      onSubmit={handleFormSubmit}
    >
      <h2 className="operation-edit__heading interface-text--heading--small">
        Edit your contact info and its visibility on your profile
      </h2>
      <InputField
        name="name"
        label="Operation Name"
        value={updatedOperation.name || undefined}
        readOnly={!!updatedOperation.name}
        handleChange={(e) => handleChange(e)}
      />
      <InputField label="Scope" value={ci_scope} readOnly={!!ci_scope} />
      <InputField
        name="certifierName"
        label="Certified by"
        value={updatedOperation.certifierName || undefined}
        readOnly={!!updatedOperation.certifierName}
        handleChange={(e) => handleChange(e)}
      />
      <InputField
        label="Certification status"
        value={certificationStatus || undefined}
        readOnly={!!certificationStatus}
      />
      <InputField
        name="email"
        label="Email"
        value={updatedOperation.email}
        handleChange={(e) => handleChange(e)}
      />
      <InputField
        name="phone"
        label="Phone"
        value={updatedOperation.phone || undefined}
        handleChange={(e) => handleChange(e)}
      />
      <Button
        name="update-operation"
        label="Save"
        styleVariation={ButtonStyleVariations.PRIMARY}
        onClick={handleFormSubmit}
        disabled={loading}
      />
      {renderUpdateOperationToast()}
    </form>
  );
}

export { OperationProfileAdmin };
