import { ClaimOperation } from "./ClaimOperation";
import { ClaimOperationSearch } from "./ClaimOperationSearch";
import { ClaimOperationInitialStep } from "./ClaimOperationInitialStep";
import { ClaimOperationSuccessStep } from "./ClaimOperationSuccessStep";
import { Operation } from "../../graphql/generated-types";

export enum Steps {
  FindOperation,
  InitialStep,
  VerifyStep,
  ClaimSuccessStep,
}

// In Claim Operation steps, we have an operation, and a function to set what step to go to.
export interface ClaimOperationStepParams {
  operation: Operation;
  setCurrentStep?: (Steps) => void;
}

export {
  ClaimOperation,
  ClaimOperationSearch,
  ClaimOperationInitialStep,
  ClaimOperationSuccessStep,
};
