import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function ListingIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01"
            stroke={color}
            fill="transparent"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </React.Fragment>
      )}
    />
  );
}

ListingIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

ListingIcon.defaultProps = {
  className: null,
};

export default ListingIcon;
