import React, { useState, useEffect, FormEvent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useSignUp } from "@clerk/clerk-react";

const StepVerification = () => {
  const { isLoaded, signUp, setActive } = useSignUp();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.getAll("email");
  const [otp, setOtp] = useState("");
  const [clerkError, setClerkError] = useState("");
  const [counterRetry, setCounterRetry] = useState(59);

  const handleVerify = async (e: FormEvent) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code: otp,
      });
      if (completeSignUp.status !== "complete") {
        console.warn(JSON.stringify(completeSignUp, null, 2));
      }

      if (completeSignUp.status === "complete") {
        await setActive({ session: completeSignUp.createdSessionId });
        navigate("/signup/user-profile");
      }
    } catch (err) {
      setClerkError(err.errors[0].longMessage);

      console.warn("Error:", JSON.stringify(err, null, 2));
    }
  };

  const handleRetry = () => {
    navigate(`/signup?email=${email}`);
  };

  useEffect(() => {
    if (counterRetry > 0) {
      const timer = setTimeout(() => {
        setCounterRetry(counterRetry - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counterRetry]);

  useEffect(() => {
    if (!email) navigate("/signup");
  }, []);

  return (
    <>
      <div className="mb-10">
        <h1 className="text-3xl text-gray-900 font-semibold mb-2">
          Enter the verification code sent to your email <br />
        </h1>
        <p className="text-lg">
          We just sent a verification code to {email}. Please check your inbox
          and verify your account to complete your sign-up
        </p>
      </div>
      <form onSubmit={handleVerify}>
        <div>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            containerStyle="flex gap-3 justify-center w-full"
            renderInput={(props) => (
              <input
                {...props}
                className="border rounded-md !h-12 !w-12 focus:outline-2 outline-offset-2 outline-green-900"
              />
            )}
          />

          {clerkError && (
            <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100 mt-4">
              <p className="text-red-500 text-xs font-medium">{clerkError}</p>
            </div>
          )}
        </div>

        <div className="text-center mt-10">
          <button
            type="submit"
            disabled={otp.length !== 6}
            className="rounded-full px-6 py-3 text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white font-semibold inline-block disabled:bg-gray-400 disabled:pointer-events-non"
          >
            Continue
          </button>
        </div>
      </form>
      <div className="text-center mt-6">
        <button
          disabled={counterRetry > 0}
          className="text-center text-gray-900 text-xs font-semibold disabled:opacity-60 p-2"
          onClick={handleRetry}
        >
          Didn't receive a code? Retry{" "}
          {counterRetry > 0 ? `(${counterRetry})` : null}
        </button>
      </div>
    </>
  );
};

export default StepVerification;
