const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  ENTER: 13,
  ESCAPE: 27,
  TAB: 9,
  SPACE: 32,
};

export default { KEY_CODES };
