import React, { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";

// Components
import { Form } from "./HomeAlt";
import { Loading } from "../Loading";
import { Sidebar } from "../search/Sidebar";
import { DefaultSearchResult } from "../search/DefaultSearchResult";
import { SearchResult } from "../search/SearchResult";
import { SearchHeader } from "../search/SearchHeader";
import {
  useAsksFullTextSearchQuery,
  useListingsFullTextSearchQuery,
  useOperationsFullTextSearchQuery,
} from "../../graphql/generated-types";

const ALL_FILTER_OPTIONS = [
  "certified-organic-farms-handlers",
  "certified-organic-handlers",
  "for-sale",
  "wanted"
];

function Search() {
  const { query, page: pageStr } = useParams<{ query; page }>();
  const page: number = pageStr ? parseInt(pageStr) : 1;
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const showType = searchParams.getAll("showType");

  const [viewMode, setViewMode] = useState<"grid" | "list">("list");
  const searchResultsRef = useRef<HTMLElement>(null);

  const queryResultsLimit = showType.length === 1 ? 32 : 8;

  // Initialize filters
  useEffect(() => {
    if (showType.length === 0) {
      const newSearchParams = new URLSearchParams(searchParams);
      ALL_FILTER_OPTIONS.forEach(filter => newSearchParams.append("showType", filter));
      setSearchParams(newSearchParams);
    }
  }, []);

  const {
    loading: listingsLoading,
    error: listingsError,
    data: listingsData,
    refetch: listingsRefresh,
  } = useListingsFullTextSearchQuery({
    variables: {
      query: query || "amaranth ",
      stateQuery: "amaranth",
      page: page,
      limit: queryResultsLimit,
    },
  });

  const {
    loading: asksLoading,
    error: asksError,
    data: asksData,
    refetch: asksRefresh,
  } = useAsksFullTextSearchQuery({
    variables: {
      query: query || "amaranth ",
      stateQuery: "",
      page: page,
      limit: queryResultsLimit,
    },
  });

  const {
    loading: operationsLoading,
    error: operationsError,
    data: operationsData,
    refetch: operationsRefresh,
  } = useOperationsFullTextSearchQuery({
    variables: {
      query: query || "amaranth ",
      stateQuery: "",
      page: page,
      limit: queryResultsLimit,
    },
  });

  const {
    loading: operationsHandlersLoading,
    error: operationsHandlersError,
    data: operationsHandlersData,
    refetch: operationsHandlersRefresh,
  } = useOperationsFullTextSearchQuery({
    variables: {
      query: query || "amaranth ",
      stateQuery: "",
      page: page,
      limit: queryResultsLimit,
      handling: true,
    },
  });

  useEffect(() => {
    if (searchResultsRef.current) {
      // Check if this is the initial load or a navigation from another page
      if (location.state?.fromSearch || query) {
        searchResultsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, query]);

  useEffect(() => {
    listingsRefresh();
    asksRefresh();
    operationsRefresh();
    operationsHandlersRefresh();
  }, [showType, query, page]);

  const loading =
    operationsLoading ||
    operationsHandlersLoading ||
    listingsLoading ||
    asksLoading;

  const totalResults =
    (showType.includes("certified-organic-farms-handlers")
      ? operationsData?.fulltextSearchOperations?.totalResults
      : 0) +
    (showType.includes("certified-organic-handlers")
      ? operationsHandlersData?.fulltextSearchOperations?.totalResults
      : 0) +
    (showType.includes("for-sale")
      ? listingsData?.fulltextSearchListings?.totalResults
      : 0) +
    (showType.includes("wanted")
      ? asksData?.fulltextSearchAsks?.totalResults
      : 0);

  const handleSearch = (newQuery: string) => {
    navigate(`/search/${newQuery}?${searchParams.toString()}`, { state: { fromSearch: true } });
  };

  return (
    <main>
      <Form query={query} onSearch={handleSearch} />

      <section id="search-results" ref={searchResultsRef}>
        <div className="container">
          <SearchHeader
            handleViewModeChange={setViewMode}
            query={query}
            loading={loading}
            totalResults={totalResults}
            viewMode={viewMode}
          />

          <div className="py-6 md:py-10 flex flex-col gap-10">
            <section className="grid grid-cols-1 md:grid-cols-4 gap-10">
              {query ? (
                <>
                  <Sidebar />
                  {loading ? (
                    <Loading isView type="search" />
                  ) : (
                    <div className="col-span-3">
                      <SearchResult
                        operationsData={operationsData}
                        operationsHandlersData={operationsHandlersData}
                        listingsData={listingsData}
                        asksData={asksData}
                        viewMode={viewMode}
                        query={query}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="col-span-4">
                  <DefaultSearchResult
                    operationsData={operationsData}
                    operationsHandlersData={operationsHandlersData}
                    listingsData={listingsData}
                    asksData={asksData}
                    viewMode={viewMode}
                    query={query}
                  />
                </div>
              )}
            </section>
          </div>
        </div>
      </section>
    </main>
  );
}

export { Search };