import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../Loading";
import { Location } from "../Operation";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { certifiedOrganicFarms } from "../../data";
import Card from "../Card";
import AccountIcon from "../icons/Account";
import { OperationContactModal } from "../Operation/OperationContactModal";

import {
  useFullAskQuery,
  useFullListingQuery,
} from "../../graphql/generated-types";
import { useUser } from "@clerk/clerk-react";
import BackButton from "../BackButton";

type ProductType = {
  type: "listing" | "ask";
};

function Product({ type = "listing" }: ProductType) {
  const { id } = useParams<{ id: string }>();
  const [showContactModal, setShowContactModal] = useState(false);
  const { isLoaded: isUserLoaded } = useUser();
  const backButtonRef = useRef(null);

  const {
    loading: loadingListing,
    error: errorListing,
    data: dataListing,
  } = useFullListingQuery({
    variables: { id: parseInt(id) },
    skip: type === "ask" || !isUserLoaded,
  });

  const {
    loading: loadingAsk,
    error: errorAsk,
    data: dataAsk,
  } = useFullAskQuery({
    variables: { id: parseInt(id) },
    skip: type === "listing" || !isUserLoaded,
  });

  const toggleContactModal = () => {
    setShowContactModal((show) => !show);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loadingListing || loadingAsk || !isUserLoaded) {
    return <Loading isView />;
  }

  if (errorListing || errorAsk) {
    return <p>There was an error finding this listing, please try again.</p>;
  }

  const ProductData = type === "ask" ? dataAsk.ask : dataListing.listing;

  const author =
    ProductData.__typename === "Ask"
      ? ProductData.organization
      : ProductData.operation;

  return (
    <>
      <section className="py-10 md:py-20">
        <div className="container container--small">
          <div className="">
            <div className="flex flex-col gap-2 mb-10">
              <div className="flex flex-col">
                <h1 className="order-last mt-3 mt mb-1 text-3xl font-semibold tracking-tight text-primary-600">
                  {ProductData?.title}
                </h1>
                <div className="mb-4 scroll-mt-10" ref={backButtonRef}>
                  <BackButton />
                </div>
                <nav
                  aria-label="Breadcrumbs"
                  className="flex space-x-4 text-sm font-medium items-center mb-4"
                >
                  <a className="hover:text-gray-600" href="/">
                    Organic Listings
                  </a>
                  <div aria-hidden="true" className="select-none ">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.29289 14.7071C6.90237 14.3166 6.90237 13.6834 7.29289 13.2929L10.5858 10L7.29289 6.70711C6.90237 6.31658 6.90237 5.68342 7.29289 5.29289C7.68342 4.90237 8.31658 4.90237 8.70711 5.29289L12.7071 9.29289C13.0976 9.68342 13.0976 10.3166 12.7071 10.7071L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071Z"
                      />
                    </svg>
                  </div>
                  <span>{ProductData?.title}</span>
                </nav>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-900 mt-4 mb-4 font-medium">
                <AccountIcon />
                {author?.name}
              </div>

              {author.__typename == "Operation" && author?.certifierName ? (
                <div className="flex items-center gap-3 text-sm mb-2 bg-green-50 rounded-lg p-4 font-semibold">
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    className="text-green-400"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 0.944336C5.91528 2.81021 3.17437 3.95797 0.166107 3.99878C0.0568637 4.64956 0 5.31809 0 5.99991C0 11.2248 3.33923 15.6697 8 17.317C12.6608 15.6697 16 11.2248 16 5.99991C16 5.31809 15.9431 4.64956 15.8339 3.99878C12.8256 3.95797 10.0847 2.81021 8 0.944336ZM9 12.9999C9 13.5522 8.55229 13.9999 8 13.9999C7.44772 13.9999 7 13.5522 7 12.9999C7 12.4476 7.44772 11.9999 8 11.9999C8.55229 11.9999 9 12.4476 9 12.9999ZM9 5.99988C9 5.44759 8.55229 4.99988 8 4.99988C7.44772 4.99988 7 5.44759 7 5.99988V8.99988C7 9.55216 7.44772 9.99988 8 9.99988C8.55229 9.99988 9 9.55216 9 8.99988V5.99988Z"
                    />
                  </svg>

                  <p className="text-green-800">{author.certifierName}</p>
                </div>
              ) : null}

              <p>{ProductData.description}</p>

              <div className="my-6 md:my-8 flex flex-col gap-6 md:gap-8">
                {ProductData.__typename === "Listing" &&
                ProductData?.availability ? (
                  <div>
                    <div className="font-semibold mb-2.5">Availability</div>
                    <div>
                      <ul className="inline-flex gap-2">
                        {ProductData?.availability
                          .split(",")
                          .map((availability, index) => (
                            <li
                              key={index}
                              className="bg-primary-50 text-primary-800 font-medium px-3 py-0.5 rounded-full inline-block text-sm"
                            >
                              {availability}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ) : null}

                {type === "listing" ? (
                  <div>
                    <div className="font-semibold mb-2.5">Location</div>
                    <div className="underline">
                      <Link to="/" className="text-primary-700">
                        <Location operation={author} />
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="inline-block ml-1"
                        >
                          <path d="M11 3C10.4477 3 10 3.44772 10 4C10 4.55228 10.4477 5 11 5H13.5858L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L15 6.41421V9C15 9.55228 15.4477 10 16 10C16.5523 10 17 9.55228 17 9V4C17 3.44772 16.5523 3 16 3H11Z" />
                          <path d="M5 5C3.89543 5 3 5.89543 3 7V15C3 16.1046 3.89543 17 5 17H13C14.1046 17 15 16.1046 15 15V12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V15H5V7L8 7C8.55228 7 9 6.55228 9 6C9 5.44772 8.55228 5 8 5H5Z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                ) : null}

                <div>{/* <ActiveListings /> */}</div>
              </div>

              <div className="hidden md:block">
                <button
                  onClick={toggleContactModal}
                  className="bg-primary-600 hover:bg-primary-700 text-white font-semibold px-6 py-3 rounded-full w-full block text-center"
                >
                  Contact {author?.name}
                </button>
              </div>
            </div>
            <div className="block md:hidden mt-8">
              <button
                onClick={toggleContactModal}
                className="bg-primary-600 hover:bg-primary-700 text-white font-semibold px-6 py-3 rounded-full w-full block text-center"
              >
                Contact {author?.name}
              </button>
            </div>
          </div>
        </div>
      </section>
      <OperationContactModal
        show={showContactModal}
        queryType="organization"
        operationId={author?.id}
        handleClose={toggleContactModal}
      />
    </>
  );
}

export { Product };