import React, { Fragment } from "react";
import { Link } from "../Link";
import {
  Menu,
  MenuItem,
  MenuItems,
  MenuSeparator,
  Transition,
} from "@headlessui/react";
import { FormattedMessage } from "react-intl";
import {
  OrganizationSwitcher,
  useClerk,
  useOrganization,
  useUser,
} from "@clerk/clerk-react";
import {
  ArrowRightStartOnRectangleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/20/solid";

export const UserButton = () => {
  const { user } = useUser();
  const { signOut } = useClerk();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="cursor-pointer text-primary-400 hover:text-primary-900 block">
          <DefaultAvatar type="organization" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 -translate-y-2"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 translate-y-0"
        leaveTo="transform opacity-0 -translate-y-2"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              <div className="flex items-center gap-3 pl-4 pr-6 py-3 text-xs">
                <DefaultAvatar type="organization" />
                <span className="text-xs font-semibold">
                  {user.primaryEmailAddress.emailAddress}
                </span>
              </div>
            </MenuItem>
            <MenuSeparator className="h-px bg-black/5" />
            <MenuItem>
              <a
                href="/account"
                className="flex items-center gap-3 px-6 py-3 text-xs data-[focus]:bg-gray-100 cursor-pointer"
              >
                <Cog6ToothIcon className="size-4" />
                Manage account
              </a>
            </MenuItem>
            <MenuSeparator className="h-px bg-black/5" />
            <MenuItem>
              <div
                className="flex items-center gap-3 px-6 py-3 text-xs data-[focus]:bg-gray-100 cursor-pointer"
                onClick={() => signOut()}
              >
                <ArrowRightStartOnRectangleIcon className="size-4" />
                Sign out
              </div>
            </MenuItem>
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

type DefaultAvatarType = {
  defaultValue?: any;
  type?: "user" | "organization";
};

export const DefaultAvatar = ({
  defaultValue,
  type = "user",
}: DefaultAvatarType) => {
  const { user } = useUser();
  const { organization } = useOrganization();

  const imageUrl =
    defaultValue ||
    (type === "user" && user?.hasImage && user?.imageUrl) ||
    (type === "organization" &&
      organization?.hasImage &&
      organization?.imageUrl);

  return (
    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-gray-100 hover:opacity-80 overflow-hidden">
      {imageUrl ? (
        <div>
          <img alt="Manage account" src={imageUrl} className="object-cover" />
        </div>
      ) : (
        <DefaultLogo />
      )}
    </div>
  );
};

const Avatar = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_521_3473)">
        <rect y="0.5" width="32" height="32" rx="16" fill="#F3F4F6" />
        <path
          d="M32 28.492V32.5013H0V28.5066C1.86127 26.0192 4.27721 24.0004 7.05572 22.6107C9.83423 21.221 12.8987 20.4987 16.0053 20.5013C22.544 20.5013 28.352 23.64 32 28.492ZM21.336 12.5C21.336 13.9144 20.7741 15.271 19.7739 16.2712C18.7737 17.2714 17.4172 17.8333 16.0027 17.8333C14.5882 17.8333 13.2316 17.2714 12.2314 16.2712C11.2312 15.271 10.6693 13.9144 10.6693 12.5C10.6693 11.0855 11.2312 9.72892 12.2314 8.72872C13.2316 7.72853 14.5882 7.16663 16.0027 7.16663C17.4172 7.16663 18.7737 7.72853 19.7739 8.72872C20.7741 9.72892 21.336 11.0855 21.336 12.5Z"
          fill="#D1D5DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_521_3473">
          <rect y="0.5" width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DefaultLogo = () => {
  return (
    <svg
      width="17"
      height="33"
      viewBox="0 0 17 33"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="size-5 text-gray-700"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55551 13.9211L3.4428 10.7284C1.2693 9.04122 0 6.44509 0 3.69551V0.5L4.11203 3.69208C6.28416 5.37855 7.55551 7.97468 7.55551 10.7236V13.9211Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29004 13.9211L13.4007 10.7284C15.5742 9.04122 16.8435 6.44509 16.8435 3.69551V0.5L12.7328 3.69208C10.5593 5.37855 9.29004 7.97468 9.29004 10.7236V13.9211Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55551 23.2094L3.4428 20.018C1.2693 18.3316 0 15.7354 0 12.9852V9.78827L4.11203 12.9831C6.28416 14.6689 7.55551 17.2643 7.55551 20.0153V23.2094Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29004 23.2094L13.4007 20.018C15.5742 18.3316 16.8435 15.7354 16.8435 12.9852V9.78827L12.7328 12.9831C10.5593 14.6689 9.29004 17.2643 9.29004 20.0153V23.2094Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55551 32.5L3.4428 29.3079C1.2693 27.6201 0 25.0246 0 22.2757V19.0789L4.11203 22.2716C6.28416 23.9581 7.55551 26.5542 7.55551 29.3038V32.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29004 32.5L13.4007 29.3079C15.5742 27.6201 16.8435 25.0246 16.8435 22.2757V19.0789L12.7328 22.2716C10.5593 23.9581 9.29004 26.5542 9.29004 29.3038V32.5Z"
      />
    </svg>
  );
};
