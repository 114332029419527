import React from "react";
import classNames from "classnames";
import Card from "../Card";
import { Link } from "react-router-dom";
import { Location } from "../Operation";
import {
  useListingsFullTextSearchQuery,
  useOperationsFullTextSearchQuery,
} from "../../graphql/generated-types";
import { SearchResultSection } from "./SearchResultSection";
import { FormattedMessage } from "react-intl";

interface DefaultSearchResult {
  operationsData: any;
  operationsHandlersData: any;
  listingsData: any;
  asksData: any;
  query: string;
  viewMode: "grid" | "list";
}

function DefaultSearchResult({
  operationsData,
  operationsHandlersData,
  listingsData,
  asksData,
  query,
  viewMode,
}: DefaultSearchResult) {
  return (
    <div className="flex flex-col gap-20">
      <SearchResultSection
        title="Certified Organic Farms & Handlers"
        viewAll={`/search/${query}?showType=certified-organic-farms-handlers`}
        data={operationsData?.fulltextSearchOperations?.operations}
        query={query}
        viewMode={viewMode}
        type="operations"
      />
      <SearchResultSection
        title="Certified Organic Listings"
        viewAll={`/search/${query}?showType=for-sale`}
        data={listingsData?.fulltextSearchListings?.listings}
        query={query}
        viewMode={viewMode}
        type="listings"
      />
    </div>
  );
}

export { DefaultSearchResult };
