import React from "react";

import { ListingCompact } from ".";
import { Listing } from "../../services/graphql-custom-types";
import { Operation } from "../../graphql/generated-types";

function ViewListings({
  operation,
  listings = [],
  isPrivate = false,
}: {
  operation: Operation;
  listings: Listing[];
  isPrivate: boolean;
}) {
  return (
    <div className="view-listings">
      {/* TODO: This should really be done in a completely different way. */}
      {isPrivate && listings.length === 0 && (
        <p className="interface-text--body">
          You haven’t posted any listings yet.
        </p>
      )}
      {listings.map((listing, index) => (
        <ListingCompact
          operation={operation}
          key={index}
          listing={listing}
          isPrivate={isPrivate}
        />
      ))}
    </div>
  );
}

export { ViewListings };
