import React, { useEffect, useRef, useState } from "react";
import { Location } from ".";
import { Link } from "react-router-dom";
import scrollToTop from "../Utils/ScrollToTop";
import { OperationContactModal } from "./OperationContactModal";
import BackButton from "../BackButton";
import LogoLandscape from "../assets/LogoLandscape";

function OperationHeader({ operation, userOwnsOperation, isEditView = false }) {
  const [showContactModal, setShowContactModal] = useState(false);
  const backButtonRef = useRef(null);

  const toggleContactModal = () => {
    setShowContactModal((show) => !show);
  };

  useEffect(() => {
    if (backButtonRef.current) {
      backButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <>
      <section className="py-10 md:py-20">
        <div className="container container--small">
          <div className="">
            <div className="flex flex-col gap-2 mb-10">
              <div className="flex flex-col mb-2">
                <div className="mb-4 scroll-mt-10" ref={backButtonRef}>
                  <BackButton />
                </div>
                <nav
                  aria-label="Breadcrumbs"
                  className="flex space-x-4 text-sm font-medium items-center mb-4"
                >
                  <div className="text-primary-800">Farms & Handlers</div>
                </nav>
              </div>
              <div className="w-[48px] h-[48px] flex items-center justify-center">
                <LogoLandscape className="fill-primary-600" />
              </div>
              <h1 className="mt-3 text-3xl font-semibold tracking-tight text-primary-600">
                {operation.name}
              </h1>
              <div className="flex items-center gap-3 text-sm mt-4 bg-green-50 rounded-lg p-4 font-semibold">
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  className="text-green-400"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 0.944336C5.91528 2.81021 3.17437 3.95797 0.166107 3.99878C0.0568637 4.64956 0 5.31809 0 5.99991C0 11.2248 3.33923 15.6697 8 17.317C12.6608 15.6697 16 11.2248 16 5.99991C16 5.31809 15.9431 4.64956 15.8339 3.99878C12.8256 3.95797 10.0847 2.81021 8 0.944336ZM9 12.9999C9 13.5522 8.55229 13.9999 8 13.9999C7.44772 13.9999 7 13.5522 7 12.9999C7 12.4476 7.44772 11.9999 8 11.9999C8.55229 11.9999 9 12.4476 9 12.9999ZM9 5.99988C9 5.44759 8.55229 4.99988 8 4.99988C7.44772 4.99988 7 5.44759 7 5.99988V8.99988C7 9.55216 7.44772 9.99988 8 9.99988C8.55229 9.99988 9 9.55216 9 8.99988V5.99988Z"
                  />
                </svg>

                <p className="text-green-800">{operation.certifierName}</p>
              </div>

              <div className="my-6 md:my-8 flex flex-col gap-6 md:gap-8">
                <div>
                  <div className="font-semibold mb-2.5">Scope</div>
                  <div>
                    <ul className="inline-flex gap-2">
                      <li className="bg-primary-50 text-primary-800 font-medium px-3 py-0.5 rounded-full inline-block text-sm">
                        {operation.scope}
                      </li>
                    </ul>
                  </div>
                </div>

                <div>
                  <div className="font-semibold mb-2.5">Location</div>
                  <div className="underline">
                    <Link to="/" className="text-primary-700">
                      <Location operation={operation} />
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-block ml-1"
                      >
                        <path d="M11 3C10.4477 3 10 3.44772 10 4C10 4.55228 10.4477 5 11 5H13.5858L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L15 6.41421V9C15 9.55228 15.4477 10 16 10C16.5523 10 17 9.55228 17 9V4C17 3.44772 16.5523 3 16 3H11Z" />
                        <path d="M5 5C3.89543 5 3 5.89543 3 7V15C3 16.1046 3.89543 17 5 17H13C14.1046 17 15 16.1046 15 15V12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V15H5V7L8 7C8.55228 7 9 6.55228 9 6C9 5.44772 8.55228 5 8 5H5Z" />
                      </svg>
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    onClick={toggleContactModal}
                    className="bg-primary-600 hover:bg-primary-700 text-white font-semibold px-6 py-3 rounded-full w-full"
                  >
                    Contact {operation.name}
                  </button>
                </div>

                <div>
                  <div className="font-semibold mb-2.5">
                    Certified Product List
                  </div>
                  <div>
                    <ul className="inline-flex flex-wrap gap-2">
                      {operation.products.map((product, index) => (
                        <li key={`${product.name}-${index}`}>
                          <Link
                            to={`/search/${product.name}`}
                            className="bg-primary-50 hover:bg-primary-100 text-primary-800 font-medium px-3 py-0.5 rounded-full inline-block text-sm"
                          >
                            {product.name} {product.id}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <OperationContactModal
        show={showContactModal}
        operationId={operation.id}
        handleClose={toggleContactModal}
      />
    </>
  );
}

export { OperationHeader };