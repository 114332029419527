// Style config, for when styles are needed in the DOM

// Colors
// Taken from app/assets/stylesheets/helpers/_color.scss
// Important to keep these in sync - TODO: import the scss map here or vice versa
const COLORS = {
  // Off white - tinted down from cream
  paper: "#FFFEFC",

  // Brand
  cream: "#FFFCF5",
  khaki: "#FEDCBA",
  clay: "#E2775A",
  viridian: "#4E6B66",
  brown: "#452C18",

  // Gray scale
  dark_gray: "#373B44",
  medium_gray: "#87898F",
  light_gray: "#D7D8DA",
  faint_gray: "#EBEBEC",
};

export default { COLORS };
