import React from "react";
import { useMutation, gql } from "@apollo/client";

// Components
import { InputField } from "../forms/InputField";
import { Steps, ClaimOperationStepParams } from ".";
import { useRecoilState } from "recoil";
import {
  enteredOperationEmailState,
  enteredOperationPhoneState,
} from "../../store/recoil";

const VERIFY_PHONE = gql`
  mutation SendPhoneVerificationCode($input: SendPhoneVerificationCodeInput!) {
    sendPhoneVerificationCode(input: $input)
  }
`;
const VERIFY_EMAIL = gql`
  mutation SendEmailVerificationCode($input: SendEmailVerificationCodeInput!) {
    sendEmailVerificationCode(input: $input)
  }
`;

function ClaimOperationInitialStep({
  operation,
  setCurrentStep,
}: ClaimOperationStepParams) {
  const [enteredPhone, setEnteredPhone] = useRecoilState<string>(
    enteredOperationPhoneState,
  );
  const [enteredEmail, setEnteredEmail] = useRecoilState<string>(
    enteredOperationEmailState,
  );

  const [verifyPhone, { loading: loadingPhone }] = useMutation(VERIFY_PHONE, {
    onCompleted: () => setCurrentStep(Steps.ClaimSuccessStep),
  });
  const handlePhoneClaim = () => {
    verifyPhone({
      variables: {
        input: {
          operationId: operation.id,
          phone: enteredPhone,
        },
      },
    });
  };

  const [verifyEmail, { loading: loadingEmail }] = useMutation(VERIFY_EMAIL, {
    onCompleted: () => setCurrentStep(Steps.ClaimSuccessStep),
  });
  const handleEmailClaim = () => {
    verifyEmail({
      variables: {
        input: {
          operationId: operation.id,
          email: enteredEmail,
        },
      },
    });
  };

  return (
    <form>
      {!operation.email && !operation.phone ? (
        <>
          <p id="dialog-description" className="interface-text--body">
            We couldn’t find a phone number or email address associated with
            your Organic Integrity Database listing.
          </p>
          <p className="interface-text--supporting">
            Please call us at +1-877-216-7426 (+1-877-21-ORGANIC) and we will
            assist you.
          </p>
        </>
      ) : (
        <>
          <p id="dialog-description" className="interface-text--body">
            Do you have access to the email or phone number associated with your
            Organic Integrity Database listing? If you do, type in the complete
            email or phone number in the boxes below and click 'Claim'.
          </p>
          <p className="interface-text--supporting">
            If you're having trouble, please call us at +1-877-216-7426 and we
            will assist you.
          </p>
          <fieldset className="claim-operation__email-phone-fields">
            <InputField
              label="Email"
              placeholder={operation.email}
              value={enteredEmail}
              handleChange={(ev) => setEnteredEmail(ev.target.value)}
              inputType={operation.email ? "email" : "text"}
              readOnly={!operation.email}
              hasInlineAction
              actionDisabled={!operation.email || loadingEmail}
              actionLabel="Claim with email"
              inlineAction={handleEmailClaim}
            />
            <InputField
              label="Phone"
              placeholder={operation.phone}
              value={enteredPhone}
              handleChange={(ev) => setEnteredPhone(ev.target.value)}
              inputType={operation.phone ? "tel" : "text"}
              hasInlineAction
              actionDisabled={!operation.phone || loadingPhone}
              actionLabel="Claim with phone"
              inlineAction={handlePhoneClaim}
            />
          </fieldset>
        </>
      )}
    </form>
  );
}

export { ClaimOperationInitialStep };
