import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Location } from "../Operation";

const tableData = {
  operations: [
    { label: "Operation Name", value: "name" },
    { label: "Scope", value: "scope" },
    { label: "Certified Products", value: "products" },
    { label: "Location", value: "location" },
  ],
  listings: [
    { label: "Operation Name", value: "operation.name" },
    { label: "Description", value: "description" },
    { label: "Location", value: "location" },
  ],
  asks: [
    { label: "Title", value: "title" },
    { label: "Description", value: "description" },
    { label: "Location", value: "" },
  ],
};

const TableSearchResult = ({ data, type }) => {
  const navigate = useNavigate();

  const handleRowClick = (id) => {
    navigate(`/${type}/${id}`);
  };

  return (
    <div className="w-full">
      {/* Desktop View */}
      <table className="w-full bg-white border-collapse table-fixed hidden sm:table">
        <thead>
          <tr className="bg-gray-100 text-left">
            {tableData[type].map((item) => (
              <th
                key={`${item.value}-head`}
                className="py-3 px-4 font-semibold text-sm text-gray-600 border-b"
              >
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const operation = type === "listings" ? item.operation : item;

            return (
              <tr
                key={`${type}-${item.id}-desktop`}
                className="hover:bg-gray-50 cursor-pointer border-b"
                onClick={() => handleRowClick(item.id)}
              >
                {tableData[type].map((tableItem) => (
                  <td
                    key={`${item.id}-${tableItem.value}-row-desktop`}
                    className="py-4 px-4"
                  >
                    <div className={`text-primary-600 font-medium ${
                      tableItem.value.includes('name') ? 'truncate' : ''
                    }`}>
                      {renderValue(tableItem, item, operation)}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Mobile View */}
      <div className="sm:hidden">
        {data?.map((item, index) => {
          const operation = type === "listings" ? item.operation : item;

          return (
            <div key={`${type}-${item.id}-mobile`} className="mb-4 border border-primary-300 rounded-lg overflow-hidden">
              <div
                className="cursor-pointer bg-white hover:bg-gray-50"
                onClick={() => handleRowClick(item.id)}
              >
                {tableData[type].map((tableItem) => (
                  <div
                    key={`${item.id}-${tableItem.value}-row-mobile`}
                    className="py-3 px-4 border-b border-primary-100 last:border-b-0"
                  >
                    <span className="font-semibold text-primary-600 block mb-1">
                      {tableItem.label}:
                    </span>
                    <div className={`text-primary-700 font-medium ${
                      tableItem.value.includes('name') ? 'truncate' : ''
                    }`}>
                      {renderValue(tableItem, item, operation)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>

      {!data?.length && (
        <div className="text-center py-4 text-gray-500">
          <FormattedMessage
            id="search.noResults"
            defaultMessage="No results found"
          />
        </div>
      )}
    </div>
  );
};

const renderValue = (tableItem, item, operation) => {
  const value = tableItem.value.includes(".")
    ? tableItem.value
        .split(".")
        .reduce((o, i) => (o ? o[i] : ""), item)
    : item[tableItem.value];

  switch (tableItem.value) {
    case "location":
      return <Location operation={operation} />;
    case "scope":
      return item.scope.map((scope, index) => (
        <React.Fragment key={scope}>
          {scope}
          {index < item.scope.length - 1 ? ", " : ""}
        </React.Fragment>
      ));
    case "products":
      return <LimitedProducts operation={operation} />;
    default:
      return value;
  }
};

const LimitedProducts = ({ operation, limit = 3 }) => {
  const products = operation.products || [];

  if (products.length === 0) {
    return "No products listed";
  }

  const displayedProducts = products
    .slice(0, limit)
    .map((product) => product.name || "Unnamed product");

  const remainingCount = products.length - limit;

  return (
    <div className="flex items-center space-x-1">
      <div className="truncate flex-grow">{displayedProducts.join(", ")}</div>
      {remainingCount > 0 && (
        <span className="text-xs text-gray-500 whitespace-nowrap">
          (+{remainingCount})
        </span>
      )}
    </div>
  );
};

export default TableSearchResult;