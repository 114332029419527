import React, { useState } from "react";
import classNames from "classnames";
import { Variation } from "../../services/graphql-custom-types";
import { Listing } from "../../services/graphql-custom-types";
import Styles from "../../config/Styles";
import { Chip, ChipActiveStyles, ChipSizes } from "../Chip";
import FavoriteIcon from "../icons/Favorite";
import { friendlyDateFormat } from "../Utils/DateFormat";
import { SelectField } from "../forms/SelectField";
import { InputField } from "../forms/InputField";
import { Button, ButtonSizes, ButtonStyleVariations } from "../Button";
import { isMobile } from "../Utils";
import { Cart } from "../Utils";
import { Toast } from "../Toast";
import { Link } from "../Link";

function ListingDetails({
  listing,
  isPrivate = false,
  cartView = false,
  cartVariation,
  cartQuantity,
  handleToggleListingDetails,
  handleToggleEditModal,
}: {
  listing: Listing;
  isPrivate?: boolean;
  cartView?: boolean;
  cartVariation?: Variation;
  cartQuantity?: string;
  handleToggleListingDetails?: () => void;
  handleToggleEditModal?: (e) => void;
}) {
  const [selected, setSelected] = useState(false);
  const [selectedVariationName, setSelectedVariationName] = useState(
    (cartVariation && cartVariation.name) ||
      (listing.variations && listing.variations[0].name),
  );
  const [selectedQuantity, setSelectedQuantity] = useState(cartQuantity || "1");
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(true);
  const [showAddToCartToast, setShowAddToCartToast] = useState(false);
  const [showInventoryMessage, setShowInventoryMessage] = useState(false);

  const chipSize = isMobile() ? ChipSizes.SMALL : ChipSizes.LARGE;
  const expiryRowClasses = classNames({
    "listing--details__date-row": true,
    "listing__date-row": true,
    "listing--details__date-action": isPrivate || cartView,
  });

  const isSquareListing = Boolean(listing.variations);
  const selectedVariation =
    isSquareListing &&
    listing.variations.find(
      (variation) => variation.name === selectedVariationName,
    );
  const listingVariationNames =
    isSquareListing && listing.variations.map((variation) => variation.name);
  const price = selectedVariation && selectedVariation.price;

  const handleSelectVariation = (e) => {
    const newVariationName = e.target.value;

    if (cartView) {
      const newVariation = listing.variations.find(
        (variation) => variation.name === newVariationName,
      );
      Cart.remove(listing, selectedVariation);
      Cart.add(listing, newVariation, selectedQuantity);
    }

    setSelectedVariationName(newVariationName);
  };

  const handleSelectQuantity = (e) => {
    const newQuantity = e.target.value;
    const inventory = selectedVariation.inventoryQuantity;

    setSelectedQuantity(newQuantity);

    if (inventory !== null && Number(newQuantity) > inventory) {
      setShowInventoryMessage(true);
      setIsAddToCartDisabled(true);
    } else {
      setShowInventoryMessage(false);
      setIsAddToCartDisabled(Number(newQuantity) < 1);
      if (cartView) {
        Cart.add(listing, selectedVariation, newQuantity);
      }
    }
  };

  const handleAddToCart = () => {
    Cart.add(listing, selectedVariation, selectedQuantity);
    setShowAddToCartToast(!showAddToCartToast);
    window?.setTimeout(() => handleToggleListingDetails(), 3000);
    // Todo: clear timeout, requires useEffect to setTimeout
  };

  const handleRemoveFromCart = () => {
    Cart.remove(listing, selectedVariation);
  };

  const renderAddToCartToast = (name) => {
    if (!showAddToCartToast) return null;
    return (
      <Toast handleClose={() => setShowAddToCartToast(!showAddToCartToast)}>
        <>
          "{name}" was added to your{" "}
          <Link className="toast__message-link" href="/cart">
            cart
          </Link>
        </>
      </Toast>
    );
  };

  return (
    <>
      <div
        className="listing--details__upper"
        onClick={handleToggleListingDetails}
      >
        <div className={expiryRowClasses}>
          {!isPrivate && !cartView && (
            <div
              className="listing__favorite"
              onClick={() => setSelected(!selected)}
            >
              <FavoriteIcon
                color={Styles.COLORS.viridian}
                selected={selected}
              />
            </div>
          )}
          <p className="listing__date interface-text--list--small">
            {friendlyDateFormat(listing.createdAt)}
          </p>
          {isPrivate && !cartView && (
            <Button
              className="listing--details__action"
              label="Edit"
              name="edit-listing"
              onClick={(e) => handleToggleEditModal(e)}
            />
          )}
          {cartView && (
            <Button
              className="listing--details__action"
              label="Remove"
              name="remove-listing-from-cart"
              onClick={handleRemoveFromCart}
              size={ButtonSizes.SMALL}
              styleVariation={ButtonStyleVariations.BASE_BROWN}
            />
          )}
        </div>
        <h2 className="listing--details__name interface-text--heading--large">
          {listing.title}
          {price && ` - ${price}`}
        </h2>
        {listing.description && (
          <p className="interface-text--body--large">{listing.description}</p>
        )}
      </div>

      <hr className="listing--details__divider" />

      <div className="listing--details__avail-options">
        {listing.availability && !cartView && (
          <div className="listing--details__avail">
            <label className="listing--details__label interface-text--label">
              Available for:
            </label>
            <div className="listing--details__chips listing__chips">
              {listing.availability.includes("contract") && (
                <Chip
                  active
                  activeStyle={ChipActiveStyles.BROWN}
                  size={chipSize}
                >
                  Contract
                </Chip>
              )}
              {listing.availability.includes("wholesale") && (
                <Chip
                  active
                  activeStyle={ChipActiveStyles.KHAKI}
                  size={chipSize}
                >
                  Wholesale
                </Chip>
              )}
              {listing.availability.includes("consumer") && (
                <Chip
                  active
                  activeStyle={ChipActiveStyles.CLAY}
                  size={chipSize}
                >
                  Consumer
                </Chip>
              )}
            </div>
          </div>
        )}

        {isSquareListing && (
          <>
            {selectedVariation.inventoryQuantity && isPrivate && (
              <div className="listing--details__inventory">
                <label className="listing--details__label interface-text--label">
                  Inventory:
                </label>
                <Chip size={chipSize}>
                  {selectedVariation.inventoryQuantity}
                </Chip>
              </div>
            )}
            <div className="listing--details__options">
              <label className="listing--details__label interface-text--label">
                Purchase options:
              </label>
              <div className="listing--details__fields">
                <div className="listing--details__field-inputs">
                  <SelectField
                    name="listing-variation"
                    required={Boolean(listingVariationNames)}
                    options={listingVariationNames}
                    value={selectedVariationName}
                    handleChange={handleSelectVariation}
                    className="listing--details__select"
                  />
                  <div className="input-field-with-message">
                    <InputField
                      name="listing-quantity"
                      placeholder="Quantity"
                      required={false}
                      inputType="number"
                      autoComplete="tel"
                      value={selectedQuantity}
                      handleChange={handleSelectQuantity}
                      inputClass="listing--details__input"
                    />
                  </div>
                </div>
                {showInventoryMessage && (
                  <p className="input-field__message">{`Only ${selectedVariation.inventoryQuantity} left in stock`}</p>
                )}
              </div>
            </div>
            {!cartView && !isPrivate && (
              <>
                <Button
                  styleVariation={ButtonStyleVariations.GREEN}
                  className="listing--details__button"
                  disabled={isAddToCartDisabled}
                  name="add-to-cart"
                  label="Add to cart"
                  onClick={handleAddToCart}
                />
                {renderAddToCartToast(listing.title)}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export { ListingDetails };
