import React, { useState } from "react";
import InputField from "../marketplace/InputField";
import LogoLandscape from "../assets/LogoLandscape";
import { useSignIn, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

type ForgotPasswordInputs = {
  emailAddress: string;
  password?: string;
  code?: string;
};

function ForgotPassword() {
  const { isLoaded: isClerkLoaded, signIn, setActive } = useSignIn();
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [clerkError, setClerkError] = useState("");
  const [successfulCreation, setSuccessfulCreation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ForgotPasswordInputs>();

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = async ({
    emailAddress,
    code,
    password,
  }) => {
    setLoading(true);
    setClerkError("");

    if (!successfulCreation) {
      try {
        const result = await signIn?.create({
          strategy: "reset_password_email_code",
          identifier: emailAddress,
        });

        setSuccessfulCreation(true);
      } catch (error) {
        setClerkError(error.errors[0].longMessage);
      }
    } else {
      // Confirm new password and redirect to sign-in
      try {
        const result = await signIn?.attemptFirstFactor({
          strategy: "reset_password_email_code",
          code,
          password,
        });

        if (result.status === "complete") {
          await setActive({ session: result.createdSessionId });
          navigate("/account");
        } else {
          console.warn(result);
        }
      } catch (error) {
        setClerkError(error.errors[0].longMessage);
      }
    }

    setLoading(false);
  };

  const buttonLabel = successfulCreation
    ? "Confirm"
    : "Send password reset code";

  return (
    <main>
      <section className="bg-gray-50 py-14 pb-32 justify-center flex flex-col">
        <div className="text-center">
          <div className="flex items-center justify-center text-primary-600">
            <LogoLandscape className="h-[48px] w-[25px]" />
          </div>
          <h2 className="text-gray-900 font-semibold text-3xl mt-6">
            Reset your password
          </h2>
        </div>
        <div className="max-w-[480px] w-full mx-auto shadow-md shadow-black/5 bg-white mt-10 rounded-lg">
          <div className="py-8 px-4 md:p-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-5">
                {!successfulCreation ? (
                  <>
                    <InputField
                      label="Email address"
                      name="emailAddress"
                      type="email"
                      required
                      register={register("emailAddress", {
                        required: true,
                      })}
                    />
                  </>
                ) : (
                  <>
                    <InputField
                      label="Email address"
                      name="emailAddress"
                      type="email"
                      defaultValue={getValues().emailAddress}
                      disabled
                    />
                    <InputField
                      label="Enter the code that was sent to your email"
                      name="code"
                      type="input"
                      register={register("code", {
                        required: true,
                      })}
                    />
                    <InputField
                      label="Enter your new password"
                      name="password"
                      type="password"
                      register={register("password", {
                        required: true,
                      })}
                    />
                  </>
                )}
              </div>

              {clerkError && (
                <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100 mt-4">
                  <p className="text-red-500 text-xs font-medium">
                    {clerkError}
                  </p>
                </div>
              )}

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={!isClerkLoaded || loading}
                  className="rounded-full px-4 py-2 block w-full text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium disabled:bg-gray-400 disabled:pointer-events-none"
                >
                  {loading ? "loading" : buttonLabel}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
}

export { ForgotPassword };
