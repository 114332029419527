import { useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useCreateCheckoutLinkStripeMutation } from "../graphql/generated-types";
import { Plan } from "../data";

export const useCheckout = (successPath: string, cancelPath: string) => {
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const [createCheckoutLink] = useCreateCheckoutLinkStripeMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClick = async (
    product: Plan & {
      planName: string;
    },
  ) => {
    setLoading(true);
    setError(null);
    if (!isSignedIn) {
      navigate("/signup");
    } else {
      if (product.planName !== "premium") {
        navigate("/signup/complete");
      } else {
        try {
          const res = await createCheckoutLink({
            variables: {
              priceId: product.priceId,
              successPath,
              cancelPath,
            },
          });
          if (res.errors) {
            throw new Error(res.errors.map((e) => e.message).join(", "));
          }
          const data = res.data?.createCheckoutLinkStripe
            ? JSON.parse(res.data.createCheckoutLinkStripe)
            : null;
          if (data?.url) {
            window.open(data.url, "_self");
          } else {
            throw new Error("No checkout URL returned");
          }
        } catch (error) {
          console.error("Error creating checkout link:", error);
          setError(error instanceof Error ? error.message : String(error));
        }
      }
    }
    setLoading(false);
  };

  return { handleClick, loading, error };
};
