import React from "react";
import { NoResultSection } from "./NoResultSection";
import { Link, useSearchParams } from "react-router-dom";
import Card from "../Card";
import TableSearchResult from "./TableSearchResult";
import { Location } from "../Operation";

export const SearchResultSection = ({
  title,
  data,
  query,
  viewMode,
  type,
  viewAll,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  if (!data?.length) return <NoResultSection />;
  const showType = searchParams.getAll("showType");

  return (
    <div>
      <div className="flex justify-between gap-6 items-center mb-6">
        {showType.length !== 1 ? (
          <>
            <h2 className="text-2xl font-semibold text-primary-600">{title}</h2>

            <Link
              to={viewAll}
              className="text-primary-700 hover:text-primary-900 font-medium text-sm text-nowrap hidden md:block"
            >
              View all →
            </Link>
          </>
        ) : null}
      </div>

      <>
        {viewMode === "grid" ? (
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8">
            {data.map((item) => {
              const title = type === "operations" ? item.name : item.title;
              const description =
                type === "operations" ? (
                  <Location operation={item} />
                ) : (
                  item.description
                );
              const author =
                type === "listings"
                  ? { image: "", name: item?.operation?.name }
                  : { image: "", name: item?.name };

              return (
                <Card
                  key={title}
                  viewMode="grid"
                  image={item?.image}
                  title={title}
                  description={description}
                  author={author}
                  link={`/${type}/${item.id}`}
                />
              );
            })}
          </div>
        ) : (
          <TableSearchResult data={data} type={type} />
        )}

        <div className="mt-5 md:hidden">
          <Link
            to={viewAll}
            className="text-primary-700 hover:text-primary-900 font-medium text-sm text-nowrap"
          >
            View all →
          </Link>
        </div>
      </>
    </div>
  );
};
