/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import classNames from "classnames";

interface ITextareaProps {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  labelClass?: string;
  readOnly?: boolean;
  disabled?: boolean;
  handleChange?: (any) => void;
  onKeyUp?: (any) => void;
  required?: boolean;
  autoComplete?: string;
  minLength?: number;
  maxLength?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, ITextareaProps>(
  (props, ref) => {
    const {
      name,
      placeholder,
      value,
      className,
      readOnly = false,
      disabled = false,
      handleChange,
      onKeyUp,
      required,
      autoComplete,
      minLength,
      maxLength,
    } = props;

    const classes = classNames(className, {
      "textarea-element": true,
    });

    return (
      <textarea
        name={name}
        ref={ref}
        className={classes}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        minLength={minLength}
        maxLength={maxLength}
      />
    );
  },
);

export { Textarea };
