/**
 * Returns the date in a friendly format
 * @param  {number} date   - The date
 * @return {string}        - The formatted date
 */
function friendlyDateFormat(date) {
  // TODO: Return abbreviated month day, year
  if (!date) return "now"; // Newest entry doesn't have createdAt until we refresh and get it back from the db. Doing this based on no createdAt probably isn't reliable
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  return `${month}/${day}/${year}`;
}
export { friendlyDateFormat };
