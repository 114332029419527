import { create } from "zustand";

type GlobalStoreState = {
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (isOpen: boolean) => void;
};

const useGlobalStore = create<GlobalStoreState>((set) => ({
  mobileFiltersOpen: false,
  setMobileFiltersOpen: (isOpen) => set({ mobileFiltersOpen: isOpen }),
}));

export { useGlobalStore };
