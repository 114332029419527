import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLinkSquareMerchantMutation } from "../graphql/generated-types";

function SquareTestView() {
  // const { isAuthenticated } = useAuth0();
  const isAuthenticated = true; // TODO: Change me
  const [linkSquareMerchant] = useLinkSquareMerchantMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const error = query.get("error");
  const errorDescription = query.get("error_description");

  React.useEffect(() => {
    const code = query.get("code");
    const authCode = query.get("auth-code");

    // there's weird issue where if there is a query param named "code" in the
    // URL, the auth0 token will never load
    if (code) {
      navigate(`/square-test?auth-code=${code}`);
      // also another issue where the auth status doesn't update unless we reload
      window.location.reload();
    }

    if (authCode && isAuthenticated) {
      // even after waiting until auth0 tells us we are authenticated, without a
      // short timeout, the bearer token wont be sent with the request
      setTimeout(() => {
        handleLinkSquareMerchant(authCode);
      }, 1000);
    }
  }, [query, isAuthenticated]);

  async function handleLinkSquareMerchant(code) {
    navigate(`/square-test`);

    // square doesn't allow dynamic redirect urls so we can just use this page
    // as an intermediary screen, store the operation id in localstorage, and then
    // redirect to the operation page after linking
    const operationId = localStorage.getItem(
      "square-link-merchant-redirect-operation-id",
    );

    await linkSquareMerchant({
      variables: {
        input: { operationId: Number(operationId), code },
      },
    });

    localStorage.removeItem("square-link-merchant-redirect-operation-id");
    navigate(`/operations/${operationId}`);
  }

  return (
    <div>
      {error && (
        <p>
          Error: {error} - {errorDescription}
        </p>
      )}
      <p>Loading...</p>
    </div>
  );
}

export { SquareTestView };
