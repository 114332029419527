import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Styles from "../../config/Styles";

function Icon({
  name,
  className,
  toolTip,
  size,
  height,
  width,
  viewBox,
  color,
  renderComponent,
}) {
  const classes = classNames(className, {
    [`${name}-icon`]: true,
  });
  const Component = renderComponent;
  const derivedToolTip = toolTip || name;
  const derivedViewBox = viewBox || `0 0 ${size} ${size}`;
  return (
    <svg
      role="img"
      aria-labelledby={`${name}-icon-title`}
      className={classes}
      width={width || size}
      height={height || size}
      viewBox={derivedViewBox}
    >
      <title id={`${name}-icon-title`}>{derivedToolTip}</title>
      <Component color={color} />
    </svg>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  toolTip: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
  color: PropTypes.string,
  renderComponent: PropTypes.func.isRequired,
};

Icon.defaultProps = {
  className: "",
  toolTip: null,
  size: 24,
  width: null,
  height: null,
  viewBox: null,
  color: Styles.COLORS.light_gray,
};

export default Icon;
