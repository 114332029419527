import React from "react";
import PropTypes from "prop-types";

function Location({ operation }) {
  const county =
    operation.physicalAddressCounty || operation.mailingAddressCounty;
  const state = operation.physicalAddressState || operation.mailingAddressState;
  const country =
    operation.physicalAddressCountry || operation.mailingAddressCountry;
  const abbreviatedCountry =
    country === "United States of America (the)" ? "USA" : country;

  const locationParts = [county, state, abbreviatedCountry];
  const locationString = locationParts.filter((el) => el != null).join(", ");

  return <>{locationString}</>;
}

Location.propTypes = {
  operation: PropTypes.object.isRequired,
};

export { Location };
