import React, { useState } from "react";
import PropTypes from "prop-types";
import { OldCreateOrEditListingInput } from "../../services/graphql-custom-types";
import { InputField } from "../forms/InputField";
import { ChipSelect } from "../ChipSelect";

function InitialStep({
  operation,
  listing,
  handleChange,
}: {
  operation: any;
  listing: OldCreateOrEditListingInput;
  handleChange: (Event) => void;
}) {
  const getProductNames = () => {
    const names = {};
    operation.products.map(({ name }) => {
      names[name] = name;
    });
    return names;
  };

  const productNames = getProductNames();

  const [selected, setSelected] = useState(
    listing.title ? { [listing.title]: true } : {},
  );

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setSelected({ [value]: true });
    handleChange(e);
  };

  const handleChipSelect = (title, value) => {
    const parsed = Object.keys(value)[0];
    setSelected(value);
    if (value[parsed]) {
      handleChange({
        target: {
          name: title,
          value: parsed,
        },
      });
    } else {
      handleChange({
        target: {
          name: title,
          value: "",
        },
      });
    }
  };

  return (
    <>
      <p className="interface-text--supporting">
        Select from your certified product list, or enter in a new item below.
      </p>
      <div className="create-listing__fields">
        <InputField
          name="title"
          placeholder="New Listing"
          value={listing.title || ""}
          handleChange={handleTitleChange}
          inputType="text"
          required
          autoComplete="off"
          minLength={3}
          maxLength={99}
        />
      </div>
      <div>
        <ChipSelect
          single={productNames}
          selected={selected}
          handleChange={(value) => handleChipSelect("title", value)}
        />
      </div>
    </>
  );
}

InitialStep.propTypes = {
  operation: PropTypes.object.isRequired,
  listing: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

export { InitialStep };
