import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function SearchFormLocationIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="h-full absolute left-[15px] pointer-events-none"
    >
      <path
        d="M14.7141 13.8807C13.9865 14.6083 12.519 16.0758 11.4135 17.1813C10.6324 17.9624 9.36763 17.9623 8.58658 17.1813C7.50097 16.0957 6.0605 14.6552 5.286 13.8807C2.6825 11.2772 2.6825 7.05612 5.286 4.45262C7.88949 1.84913 12.1106 1.84913 14.7141 4.45262C17.3176 7.05612 17.3176 11.2772 14.7141 13.8807Z"
        fill="white"
      />
      <path
        d="M12.5 9.16667C12.5 10.5474 11.3808 11.6667 10 11.6667C8.61933 11.6667 7.50004 10.5474 7.50004 9.16667C7.50004 7.78595 8.61933 6.66667 10 6.66667C11.3808 6.66667 12.5 7.78595 12.5 9.16667Z"
        fill="white"
      />
      <path
        d="M14.7141 13.8807C13.9865 14.6083 12.519 16.0758 11.4135 17.1813C10.6324 17.9624 9.36763 17.9623 8.58658 17.1813C7.50097 16.0957 6.0605 14.6552 5.286 13.8807C2.6825 11.2772 2.6825 7.05612 5.286 4.45262C7.88949 1.84913 12.1106 1.84913 14.7141 4.45262C17.3176 7.05612 17.3176 11.2772 14.7141 13.8807Z"
        stroke="#336553"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.16667C12.5 10.5474 11.3808 11.6667 10 11.6667C8.61933 11.6667 7.50004 10.5474 7.50004 9.16667C7.50004 7.78595 8.61933 6.66667 10 6.66667C11.3808 6.66667 12.5 7.78595 12.5 9.16667Z"
        stroke="#336553"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchFormLocationIcon;
