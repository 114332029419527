import React, { useState, useRef, useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import AccountIcon from "../icons/Account";
import LockIcon from "../icons/Lock";
import ListingIcon from "../icons/Listing";
import EmailIcon from "../icons/Email";
import classNames from "classnames";

// Config
import Keys from "../../config/Keys";
import Styles from "../../config/Styles";

// Components
import { Button, ButtonStyleVariations } from "../Button";
import ProfileIcon from "../icons/Profile";
import ListingsIcon from "../icons/Listings";
import { InputField } from "../forms/InputField";
import { Modal, ModalPositions, ModalSizes } from "../modals/Modal";
import { ClaimOperation } from "../ClaimOperation";
import CartIconLarge from "../icons/CartLarge";
import { FormattedMessage, useIntl } from "react-intl";

const Home = () => {
  return (
    <main>
      <Form />
      <Details />
      <CreateListing />
      <Pricing />
    </main>
  );
};

const Form = () => {
  const inputSearchRef = useRef<HTMLInputElement>();
  const inputLocationRef = useRef<HTMLInputElement>();
  // const { isAuthenticated, loginWithRedirect } = useAuth0();
  const isAuthenticated = true; // TODO: Change me
  const navigate = useNavigate();
  const intl = useIntl();

  const handleKeyDown = (event) => {
    const searchQuery = inputSearchRef.current?.value;
    if (event.keyCode === Keys.KEY_CODES.ENTER) {
      if (!searchQuery) {
        return;
      }

      submitSearch();
    }
  };

  useEffect(() => {
    window && window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const submitSearch = () => {
    const searchQuery = inputSearchRef.current.value;
    const locationQuery = inputSearchRef.current.value;
    if (!searchQuery) {
      return;
    }
    navigate(`/search/${searchQuery}`);
  };

  return (
    <section className="py-20 pt-28">
      <div className="container">
        <div>
          <h1 className="text-4xl font-bold text-gray-900 text-center mb-5">
            <FormattedMessage id="home.title" />
          </h1>
          <div className="max-w-[860px] mx-auto">
            <form onSubmit={submitSearch}>
              <div className="flex flex-col md:flex-row gap-4 md:items-end">
                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="search"
                      className="font-medium text-sm mb-1 block"
                    >
                      <FormattedMessage id="home.search.title" />
                    </label>
                    <div className="relative">
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-full absolute left-[15px] pointer-events-none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.5 2C4.29086 2 2.5 3.79086 2.5 6C2.5 8.20914 4.29086 10 6.5 10C8.70914 10 10.5 8.20914 10.5 6C10.5 3.79086 8.70914 2 6.5 2ZM0.5 6C0.5 2.68629 3.18629 0 6.5 0C9.81371 0 12.5 2.68629 12.5 6C12.5 7.29583 12.0892 8.49572 11.3907 9.47653L16.2071 14.2929C16.5976 14.6834 16.5976 15.3166 16.2071 15.7071C15.8166 16.0976 15.1834 16.0976 14.7929 15.7071L9.97653 10.8907C8.99572 11.5892 7.79583 12 6.5 12C3.18629 12 0.5 9.31371 0.5 6Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                      <input
                        ref={inputSearchRef}
                        required
                        type="text"
                        title="Search"
                        id="search"
                        placeholder={intl.formatMessage({
                          id: "home.search.placeholder",
                        })}
                        className="border border-gray-300 rounded-md shadow-sm block w-full py-2 px-3 text-sm pl-10"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div>
                    <label
                      htmlFor="search"
                      className="font-medium text-sm mb-1 block"
                    >
                      <FormattedMessage id="home.location.title" />
                    </label>
                    <div className="relative">
                      <svg
                        width="15"
                        height="17"
                        viewBox="0 0 15 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-full absolute left-[15px] pointer-events-none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.55025 2.05025C5.28392 -0.683417 9.71608 -0.683418 12.4497 2.05025C15.1834 4.78392 15.1834 9.21608 12.4497 11.9497L7.5 16.8995L2.55025 11.9497C-0.183418 9.21608 -0.183418 4.78392 2.55025 2.05025ZM7.5 9C8.60457 9 9.5 8.10457 9.5 7C9.5 5.89543 8.60457 5 7.5 5C6.39543 5 5.5 5.89543 5.5 7C5.5 8.10457 6.39543 9 7.5 9Z"
                          fill="#9CA3AF"
                        />
                      </svg>
                      <input
                        ref={inputLocationRef}
                        type="text"
                        title="Location"
                        id="location"
                        placeholder={intl.formatMessage({
                          id: "home.location.placeholder",
                        })}
                        className="border border-gray-300 rounded-md shadow-sm block w-full py-2 px-3 text-sm pl-10"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="bg-yellow-900 hover:bg-yellow-920 text-white px-4 py-2 rounded-md w-full"
                  >
                    <FormattedMessage id="home.search.submit" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const Details = () => {
  const intl = useIntl();

  return (
    <section className="py-10">
      <div className="container">
        <div className="max-w-[920px] mx-auto">
          <div className="lg:text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-24 text-balance">
              <FormattedMessage id="home.how.title" />
            </h2>
            <p className="text-yellow-900 mb-8 md:mb-12 font-semibold text-xl">
              <FormattedMessage id="home.how.subtitle" />
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-12 md:gap-y-32 mt-4 md:mt-8 mb-10">
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.account.title",
              })}
              description={intl.formatMessage({
                id: "home.how.account.description",
              })}
              type="account"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.verify.title",
              })}
              description={intl.formatMessage({
                id: "home.how.verify.description",
              })}
              type="verify"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.listing.title",
              })}
              description={intl.formatMessage({
                id: "home.how.listing.description",
              })}
              type="listing"
            />
            <DetailsBloc
              title={intl.formatMessage({
                id: "home.how.contact.title",
              })}
              description={intl.formatMessage({
                id: "home.how.contact.description",
              })}
              type="contact"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

interface DetailsBlocProps {
  title: string;
  description: string;
  type: "account" | "verify" | "listing" | "contact";
}

const DetailsBloc = ({ title, description, type }: DetailsBlocProps) => {
  const iconClasses = classNames({
    "rounded-lg p-2 w-[40px] h-[40px] flex items-center justify-center": true,
    "bg-[#FEDCBA]": type === "account",
    "bg-[#E2775A]": type === "verify",
    "bg-[#452C18]": type === "listing",
    "bg-[#4E6B66]": type === "contact",
  });

  const iconComponent = () => {
    switch (type) {
      case "account":
        return <AccountIcon color="#111827" />;
      case "verify":
        return <LockIcon color="#fff" />;
      case "listing":
        return <ListingIcon color="#fff" />;
      case "contact":
        return <EmailIcon color="#fff" />;
      default:
        return;
    }
  };

  return (
    <div className="flex gap-6">
      <div>
        <div className={iconClasses}>{iconComponent()}</div>
      </div>
      <div>
        <h4 className="font-semibold mb-2 text-gray-900">{title}</h4>
        <p className="text-gray-600 leading-relaxed">{description}</p>
      </div>
    </div>
  );
};

const CreateListing = () => {
  return (
    <section className="py-20">
      <div className="text-center">
        <h2 className="text-4xl font-bold text-gray-900 mb-10">
          Get started - create free listings today!
        </h2>
        <div>
          <button className="bg-yellow-900 hover:bg-yellow-920 text-white px-4 py-2 rounded-md inline-flex items-center gap-2">
            <div className="">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.4951 3C11.0474 3 11.4951 3.44772 11.4951 4V9H16.4951C17.0474 9 17.4951 9.44772 17.4951 10C17.4951 10.5523 17.0474 11 16.4951 11H11.4951V16C11.4951 16.5523 11.0474 17 10.4951 17C9.94283 17 9.49512 16.5523 9.49512 16V11H4.49512C3.94283 11 3.49512 10.5523 3.49512 10C3.49512 9.44771 3.94283 9 4.49512 9L9.49512 9V4C9.49512 3.44772 9.94283 3 10.4951 3Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>Create Listing</div>
          </button>
        </div>
      </div>
    </section>
  );
};

const Pricing = () => {
  const Checkmark = () => {
    return (
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0342 6.15303C17.1127 6.21266 17.1787 6.28719 17.2284 6.37236C17.2781 6.45753 17.3106 6.55166 17.3238 6.64938C17.3371 6.74709 17.331 6.84646 17.3058 6.94181C17.2807 7.03716 17.2369 7.1266 17.1772 7.20503L9.17717 17.705C9.11228 17.7901 9.02994 17.8603 8.93569 17.9109C8.84143 17.9615 8.73744 17.9913 8.6307 17.9984C8.52395 18.0056 8.41693 17.9897 8.3168 17.9521C8.21667 17.9144 8.12576 17.8557 8.05017 17.78L3.55017 13.28C3.41769 13.1379 3.34557 12.9498 3.34899 12.7555C3.35242 12.5612 3.43113 12.3758 3.56855 12.2384C3.70596 12.101 3.89135 12.0223 4.08565 12.0189C4.27995 12.0154 4.46799 12.0876 4.61017 12.22L8.50417 16.113L15.9842 6.29603C16.1046 6.13799 16.2827 6.03416 16.4796 6.00735C16.6764 5.98054 16.8759 6.03294 17.0342 6.15303Z"
          fill="#10B981"
        />
      </svg>
    );
  };

  return (
    <section className="py-20 mb-8 md:mb-16">
      <div className="container">
        <div className="text-center">
          <h2 className="text-5xl font-bold text-gray-900 mb-10">
            Simple pricing, no commitment
          </h2>
          <p className="max-w-[700px] mx-auto text-lg leading-loose">
            Market.Organic was started by farmers who believe everyone should
            have access to Organic food and goods.
          </p>
        </div>
        <div className="mt-10 md:mt-16">
          <div className="grid grid-cols-1 gap-3 md:gap-10 lg:gap-0 lg:grid-cols-3">
            <div className="p-10 rounded-3xl lg:rounded-tr-none lg:rounded-br-none mt-8 border-[#E5E7EB] border lg:border-r-0 flex flex-col justify-between">
              <div>
                <h3 className="text-lg text-gray-900 mb-4 font-semibold">
                  Free
                </h3>
                <p className="text-sm mb-6">
                  A free plan that allows up to 3 listings per month.
                </p>
                <div className="flex items-baseline mb-10 gap-x-1">
                  <span className="font-bold text-4xl text-gray-900">$0</span>
                  <span className="font-semibold text-sm">/month</span>
                </div>
                <div>
                  <ul className="flex flex-col gap-3">
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Verified profiles for Certified Organic farms and
                      businesses
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Direct contact to buyers and sellers
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Up to 3 listings per month
                    </li>
                  </ul>
                </div>
              </div>
              <button className="border border-gray-300 hover:bg-gray-50 rounded-md font-medium px-4 py-2 block w-full text-center shadow-sm mt-16">
                Get started for free
              </button>
            </div>
            <div className="p-10 rounded-3xl lg:rounded-br-none lg:rounded-bl-none border-[#E5E7EB] border flex flex-col justify-between">
              <div>
                <h3 className="text-lg mb-4 font-semibold text-yellow-900">
                  Certified Organic Businesses
                </h3>
                <p className="text-sm mb-6">
                  A premium plan for Certified Organic farms & businesses that
                  includes unlimited listings.
                </p>
                <div className="flex items-baseline mb-10 gap-x-1">
                  <span className="font-bold text-4xl text-gray-900">$39</span>
                  <span className="font-semibold text-sm">/month</span>
                </div>
                <div>
                  <ul className="flex flex-col gap-3">
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Verified profiles for Certified Organic farms and
                      businesses
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Direct contact to buyers and sellers
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Unlimited listings per month
                    </li>
                  </ul>
                </div>
              </div>
              <button className="rounded-md px-4 py-2 block w-full text-center shadow-sm mt-16 bg-yellow-900 hover:bg-yellow-920 text-white">
                Subscribe
              </button>
            </div>
            <div className="p-10 rounded-3xl lg:rounded-bl-none lg:rounded-tl-none border-[#E5E7EB] lg:border-l-0 border flex flex-col justify-between mt-8">
              <div>
                <h3 className="text-lg text-gray-900 mb-4 font-semibold">
                  Non-Certified Organic Businesses
                </h3>
                <p className="text-sm mb-6">
                  A premium plan that allows anyone to make unlimited listings
                  per month.
                </p>
                <div className="flex items-baseline mb-10 gap-x-1">
                  <span className="font-bold text-4xl text-gray-900">$79</span>
                  <span className="font-semibold text-sm">/month</span>
                </div>
                <div>
                  <ul className="flex flex-col gap-3">
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Verified profiles for Certified Organic farms and
                      businesses
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Direct contact to buyers and sellers
                    </li>
                    <li className="flex gap-x-3 text-sm leading-relaxed">
                      <Checkmark />
                      Unlimited listings per month
                    </li>
                  </ul>
                </div>
              </div>
              <button className="rounded-md px-4 py-2 block w-full text-center shadow-sm mt-16 bg-yellow-900 hover:bg-yellow-920 text-white">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Home, Form };
