import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../marketplace/InputField";
import scrollToTop from "../Utils/ScrollToTop";

const SignupPayment = () => {
  const [planStep, setPlanStep] = useState<"payment" | "confirmation">(
    "payment",
  );

  const handleSubmit = (e) => {
    setPlanStep("confirmation");
  };

  const renderStep = () => {
    switch (planStep) {
      case "payment":
        return <PaymentStep onSubmit={handleSubmit} />;
      case "confirmation":
        return <ConfirmationStep />;
      default:
        return null;
    }
  };

  scrollToTop();

  return <>{renderStep()}</>;
};

interface PlanStepsProps {
  onSubmit: any;
}

const PaymentStep = ({ onSubmit }: PlanStepsProps) => {
  const navigate = useNavigate();

  return (
    <div className="w-[100vw] ml-[calc((-100vw_+_100%)_/_2)] grid grid-cols-1 md:grid-cols-2 border-t">
      <div className="px-4 md:px-20 py-12 flex flex-col gap-12">
        <div>
          <h2 className="text-gray-900 font-medium mb-6">
            Contact information
          </h2>
          <div className="flex flex-col gap-6">
            <InputField label="Email address" />
          </div>
        </div>
        <div>
          <h2 className="text-gray-900 font-medium mb-6">Payment details</h2>
          <div className="flex flex-col gap-6">
            <InputField label="Name on card" />
            <InputField label="Card number" />
            <div className="flex gap-6">
              <InputField label="Expiration date (MM/YY)" />
              <div className="w-[200px]">
                <InputField label="CVC" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-gray-900 font-medium mb-6">Billing address</h2>
          <div className="flex flex-col gap-6">
            <InputField label="Address" />
            <InputField label="Apartment, suite, etc." />
            <div className="flex gap-6">
              <InputField label="City" />
              <InputField label="State / Province" />
              <InputField label="Postal code" />
            </div>
          </div>
        </div>
        <hr />
        <div className="flex item-center justify-between gap-6">
          <div>You won't be charged until the next step.</div>
          <button
            className="rounded-md px-4 py-2 bg-indigo-600 hover:bg-indigo-700 shadow-sm font-medium text-sm text-white"
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
      </div>
      <div className="bg-gray-50 h-full px-4 md:px-20 py-12">
        <h2 className="text-gray-900 font-medium mb-4">Order summary</h2>
        <div className="flex flex-col gap-6">
          <div className="flex gap-4">
            <div className="w-[80px] h-[80px] bg-white rounded-md flex items-center justify-center">
              <svg
                width="17"
                height="32"
                viewBox="0 0 17 32"
                fill="#4E6B66"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.55551 13.4211L3.4428 10.2284C1.2693 8.54122 0 5.94509 0 3.19551V0L4.11203 3.19208C6.28416 4.87855 7.55551 7.47468 7.55551 10.2236V13.4211Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.29004 13.4211L13.4007 10.2284C15.5742 8.54122 16.8435 5.94509 16.8435 3.19551V0L12.7328 3.19208C10.5593 4.87855 9.29004 7.47468 9.29004 10.2236V13.4211Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.55551 22.7094L3.4428 19.518C1.2693 17.8316 0 15.2354 0 12.4852V9.28827L4.11203 12.4831C6.28416 14.1689 7.55551 16.7643 7.55551 19.5153V22.7094Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.29004 22.7094L13.4007 19.518C15.5742 17.8316 16.8435 15.2354 16.8435 12.4852V9.28827L12.7328 12.4831C10.5593 14.1689 9.29004 16.7643 9.29004 19.5153V22.7094Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.55551 32L3.4428 28.8079C1.2693 27.1201 0 24.5246 0 21.7757V18.5788L4.11203 21.7716C6.28416 23.4581 7.55551 26.0542 7.55551 28.8038V32Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.29004 32L13.4007 28.8079C15.5742 27.1201 16.8435 24.5246 16.8435 21.7757V18.5788L12.7328 21.7716C10.5593 23.4581 9.29004 26.0542 9.29004 28.8038V32Z"
                />
              </svg>
            </div>
            <div className="flex-1">
              <div className="text-gray-900 font-medium">
                1x Monthly Subscription
              </div>
              <p className="font-medium text-sm text-gray-500">
                For Certified Organic Farms and Businesses
                <br />
                No contract, cancel anytime.
              </p>
            </div>
            <div className="font-medium text-gray-900">$39.00 USD</div>
          </div>
          <hr />
          <div className="flex flex-col gap-6">
            <div className="flex justify-between text-sm font-medium">
              <span className="text-gray-600">Subtotal</span>
              <span className="text-gray-900">$39.00 USD</span>
            </div>
            <div className="flex justify-between text-sm font-medium">
              <span className="text-gray-600">Taxes</span>
              <span className="text-gray-900">$4.68</span>
            </div>
          </div>
          <hr />
          <div className="flex justify-between font-medium text-gray-900">
            <span>Taxes</span>
            <span>$43.68</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfirmationStep = () => {
  const navigate = useNavigate();

  return (
    <div className="max-w-[680px] mx-auto py-20">
      <div>
        <div className="text-indigo-600 font-medium mb-4">Thank you!</div>
        <h1 className="text-5xl text-gray-900 font-semibold mb-10">
          Order Confirmation
        </h1>
      </div>
      <div>
        <div className="border-t border-b py-10 px-4">
          <div>
            <h2 className="font-medium">1x Monthly Subscription</h2>
            <p className="text-sm text-gray-600">
              For Certified Organic Farms and Businesses. No contract, cancel
              anytime.
            </p>
          </div>
          <div className="flex items-center mt-12">
            <div className="mr-7 pr-7 border-r flex gap-2 text-sm">
              <span className="font-medium text-gray-900">Quantity</span>
              <span className="text-gray-700">1</span>
            </div>
            <div className="mr-7 pr-7 flex gap-2 text-sm">
              <span className="font-medium text-gray-900">Price</span>
              <span className="text-gray-700">$39.00/month</span>
            </div>
          </div>
        </div>
        <div className="py-10 px-20 grid grid-cols-2 gap-8 border-b">
          <div className="text-gray-700 text-sm">
            <h4 className="font-medium text-gray-900 mb-2">Billing address</h4>
            <p>Kristin Watson</p>
            <p>7363 Cynthia Pass</p>
            <p>Toronto, ON N3Y 4H8</p>
          </div>
          <div className="text-gray-700 text-sm">
            <h4 className="font-medium text-gray-900 mb-2">Payment method</h4>
            <p>Apple Pay</p>
            <p>Mastercard</p>
            <p>••••1545</p>
          </div>
        </div>
        <div className="flex flex-col gap-6 text-sm text-gray-700 py-8">
          <div className="flex justify-between">
            <span className="text-gray-900 font-medium">Subtotal</span>
            <span>$36.00</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-900 font-medium">
              Discount{" "}
              <span className="text-gray-600 bg-gray-200 px-2 py-1 rounded-full">
                CCOF24
              </span>
            </span>
            <span>-$18.00 (50%)</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-900 font-medium">Taxes</span>
            <span>$4.68</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-900 font-medium">Total</span>
            <span>$23.00</span>
          </div>
        </div>
        <button
          type="button"
          className="rounded-md px-6 py-3 block w-full text-center shadow-sm bg-yellow-900 hover:bg-yellow-920 text-white"
          onClick={() => navigate("/signup/complete")}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SignupPayment;
