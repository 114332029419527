import React, { useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Loading } from "../Loading";
import { useProductList } from "../../hooks/useProductList";
import { useCheckout } from "../../hooks/useCheckout";
import { useStripeSubscriptionsQuery } from "../../graphql/generated-types";
import { useLocation } from "react-router-dom";

const Plans = () => {
  const { plans, loading: stripeProductsLoading } = useProductList();
  const { data: subscriptions } = useStripeSubscriptionsQuery();
  const location = useLocation();
  const {
    handleClick,
    loading: checkoutLoading,
    error,
  } = useCheckout("/account/plans", "/account/plans");

  useEffect(() => {
    if (location.search.includes("returnFromStripe=true")) {
      const savedScrollPosition = sessionStorage.getItem("scrollPosition");
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition));
        sessionStorage.removeItem("scrollPosition");
      }
    }
  }, [location]);

  if (stripeProductsLoading) {
    return <Loading />;
  }

  const planOrder = [
    { ...plans.organicFarms, planName: "organicFarms" },
    { ...plans.premium, planName: "premium" },
    { ...plans.starter, planName: "starter" },
  ];

  const handlePlanClick = (plan) => {
    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
    handleClick(plan);
  };

  return (
    <div>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <div className="flex flex-col gap-8">
        {planOrder.map((plan, index) => (
          <DefaultCard
            key={index}
            index={index}
            price={plan.price}
            recurring={plan.recurring}
            name={plan?.name}
            description={plan?.description}
            features={plan?.features}
            subscribe={plan?.subscribeButtonText}
            handleClick={() => handlePlanClick(plan)}
            loading={checkoutLoading}
            isPremium={plan.planName === "premium"}
            isOrganicFarms={plan.planName === "organicFarms"}
          />
        ))}
      </div>
    </div>
  );
};

const DefaultCard = ({
  index,
  handleClick,
  loading,
  price,
  recurring,
  name,
  description,
  features,
  subscribe = "Subscribe",
  isPremium,
  isOrganicFarms,
}) => {
  return (
    <div className="w-full rounded-3xl ring-1 ring-gray-200 lg:flex lg:max-w-none">
      <div className="p-8 sm:p-10 lg:flex-1 md:min-w-[400px]">
        <h3 className="text-2xl font-semibold tracking-tight text-primary-600">
          {name}
        </h3>
        <p className="mt-6 text-base leading-7">{description}</p>
        <div className="mt-10 flex items-center gap-x-4">
          <h4 className="flex-none text-sm font-semibold leading-6 text-primary-600">
            What's included
          </h4>
          <div className="h-px flex-auto bg-gray-100" />
        </div>
        <ul
          role="list"
          className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6"
        >
          {features?.map((feature) => (
            <li key={feature} className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-green-600"
                aria-hidden="true"
              />
              {feature}
            </li>
          ))}
        </ul>
      </div>
      <div className="-mt-2 p-2 lg:mt-0 lg:flex-1">
        <div
          className={classNames(
            "rounded-2xl py-10 text-center lg:flex lg:flex-col lg:justify-center lg:py-16 h-full",
            {
              "bg-primary-600 text-white": isPremium,
              "bg-primary-50": !isPremium,
            },
          )}
        >
          <div className="mx-auto max-w-[256px] px-8">
            <p
              className={classNames(
                "mt-6 flex items-baseline justify-center gap-x-2 font-semibold",
                { "text-white": isPremium, "text-primary-600": !isPremium },
              )}
            >
              <span className="text-5xl tracking-tight font-general">
                {price ? `$${price}` : `Free`}
              </span>

              {recurring ? (
                <span className="leading-6 tracking-wide">/{recurring}</span>
              ) : null}
            </p>
            <button
              disabled={loading}
              onClick={handleClick}
              className={classNames(
                "border rounded-full px-4 py-2 block w-full text-center shadow-sm mt-10 font-semibold disabled:opacity-40",
                {
                  "border-white text-white hover:bg-white hover:text-primary-600":
                    isPremium,
                  "border-primary-300 bg-white text-primary-700 hover:bg-gray-50":
                    !isPremium,
                },
              )}
            >
              {loading ? "Loading..." : subscribe}
            </button>
            {isOrganicFarms && (
              <p className="mt-4 text-sm text-primary-600 font-medium">
                For Certified Organic farms
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Plans };
