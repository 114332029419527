import React from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

type LoadingType = {
  isView?: boolean;
  type?: "operation" | "listings" | "product" | "search";
};

function Loading({ isView = false, type }: LoadingType) {
  if (type === "search") {
    return (
      <div className="w-full col-span-3">
        <Skeleton count={3} height={80} style={{ marginBottom: 40 }} />
      </div>
    );
  }
  return (
    <div className="text-center h-[20vw] w-full flex items-center justify-center">
      <div>Loading...</div>
    </div>
  );
}

export { Loading };
