import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import AccountIcon from "./icons/Account";

interface AuthorType {
  image?: string;
  name: string;
}

interface CardType {
  viewMode?: "grid" | "list";
  image?: string;
  title: string;
  description?: string | React.ReactNode;
  author?: AuthorType;
  link?: string;
}

const Card = ({
  viewMode = "grid",
  image,
  title,
  description,
  author,
  link = "",
}: CardType) => {
  const rootClass = classNames({
    "group text-sm": true,
    "": viewMode === "grid",
    "py-4 md:py-6 flex item-center gap-4": viewMode === "list",
  });

  const textBlockClass = classNames({
    "md:grid grid-cols-2 gap-4 item-center flex-1": viewMode === "list",
  });

  const imageContainerClass = classNames({
    "rounded-md flex items-center justify-center group-hover:opacity-75 overflow-hidden bg-gray-200 text-primary-400":
      true,
    "h-[200px] md:h-[280px] mb-4": viewMode === "grid",
    "h-[72px] w-[72px]": viewMode === "list",
  });

  return (
    <Link to={link} className={rootClass}>
      <div className={imageContainerClass}>
        {image ? (
          <img src={image} alt={title} className="min-w-full" />
        ) : (
          <EmptyImage viewMode={viewMode} />
        )}
      </div>
      <div className={textBlockClass}>
        <div className="flex flex-col justify-center flex-1">
          <div className="text-primary-600 line-clamp-1 font-semibold">
            {viewMode === "list" ? (
              <>
                <div className="hidden md:block">{title}</div>
                <div className="md:hidden">{author.name}</div>
              </>
            ) : (
              title
            )}
          </div>
          <div className="line-clamp-1">{description}</div>
        </div>
        <div className="mt-2 md:mt-0 flex">
          {viewMode === "list" ? (
            <>
              <div className="hidden md:block">
                <Author viewMode={viewMode} author={author} />
              </div>
              <div className="md:hidden">{title}</div>
            </>
          ) : (
            <Author viewMode={viewMode} author={author} />
          )}
        </div>
      </div>
    </Link>
  );
};

const Author = ({ viewMode, author }) => {
  const authorClass = classNames({
    "flex gap-2 items-center": true,
    "max-md:[&_svg]:hidden ax-md:[&_img]:hidden ": viewMode === "list",
  });

  return (
    <div className={authorClass}>
      {author?.image ? (
        <img
          src={author.image}
          alt={author.name}
          className="rounded-full w-6 h-6"
        />
      ) : (
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[24px]"
        >
          <g clipPath="url(#clip0_521_3473)">
            <rect y="0.5" width="32" height="32" rx="16" fill="#F3F4F6"></rect>
            <path
              d="M32 28.492V32.5013H0V28.5066C1.86127 26.0192 4.27721 24.0004 7.05572 22.6107C9.83423 21.221 12.8987 20.4987 16.0053 20.5013C22.544 20.5013 28.352 23.64 32 28.492ZM21.336 12.5C21.336 13.9144 20.7741 15.271 19.7739 16.2712C18.7737 17.2714 17.4172 17.8333 16.0027 17.8333C14.5882 17.8333 13.2316 17.2714 12.2314 16.2712C11.2312 15.271 10.6693 13.9144 10.6693 12.5C10.6693 11.0855 11.2312 9.72892 12.2314 8.72872C13.2316 7.72853 14.5882 7.16663 16.0027 7.16663C17.4172 7.16663 18.7737 7.72853 19.7739 8.72872C20.7741 9.72892 21.336 11.0855 21.336 12.5Z"
              fill="#D1D5DB"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_521_3473">
              <rect y="0.5" width="32" height="32" rx="16" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      )}
      <span className="text-sm font-medium flex-1 line-clamp-1">
        {author.name}
      </span>
    </div>
  );
};

const EmptyImage = ({ viewMode }) => {
  const emptyIconClass = classNames({
    "h-12 w-12 md:w-auto md:h-auto": viewMode === "grid",
    "h-9 w-9 ": viewMode === "list",
  });

  return (
    <svg
      width="60"
      height="111"
      viewBox="0 0 60 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={emptyIconClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 46.555L12.8227 35.4826C5.33612 29.6308 0.958008 20.628 0.958008 11.092V0L15.1334 11.0773C22.6248 16.9291 27.0078 25.9318 27.0078 35.4679V46.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 46.555L47.1714 35.4826C54.6629 29.6308 59.041 20.628 59.041 11.092V0L44.8656 11.0773C37.3742 16.9291 32.9912 25.9318 32.9912 35.4679V46.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 78.7838L12.8227 67.7016C5.33612 61.8547 0.958008 52.8519 0.958008 43.311V32.2239L15.1334 43.3012C22.6248 49.1481 27.0078 58.1557 27.0078 67.6967V78.7838Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 78.7838L47.1714 67.7016C54.6629 61.8547 59.041 52.8519 59.041 43.311V32.2239L44.8656 43.3012C37.3742 49.1481 32.9912 58.1557 32.9912 67.6967V78.7838Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 111L12.8227 99.9228C5.33612 94.0808 0.958008 85.0731 0.958008 75.537V64.4451L15.1334 75.5224C22.6248 81.3693 27.0078 90.3769 27.0078 99.913V111Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 111L47.1714 99.9228C54.6629 94.0808 59.041 85.0731 59.041 75.537V64.4451L44.8656 75.5224C37.3742 81.3693 32.9912 90.3769 32.9912 99.913V111Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Card;
