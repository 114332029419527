import React, { useEffect } from "react";
import { Link } from "../Link";

const BulletPoint: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex items-start space-x-3 mb-4">
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-primary-400 flex-shrink-0 mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.857 6.191C11.9149 6.11129 11.9566 6.02095 11.9796 5.92514C12.0026 5.82933 12.0065 5.72994 11.991 5.63262C11.9756 5.5353 11.9412 5.44198 11.8897 5.35797C11.8382 5.27396 11.7707 5.20091 11.691 5.143C11.6113 5.08509 11.5209 5.04344 11.4251 5.02044C11.3293 4.99744 11.2299 4.99354 11.1326 5.00895C11.0353 5.02437 10.942 5.0588 10.858 5.11028C10.774 5.16176 10.7009 5.22929 10.643 5.309L7.16 10.099L5.28 8.219C5.21078 8.1474 5.128 8.0903 5.03647 8.05104C4.94495 8.01178 4.84653 7.99114 4.74694 7.99032C4.64736 7.9895 4.54861 8.00852 4.45646 8.04628C4.3643 8.08403 4.28059 8.13976 4.2102 8.21021C4.13982 8.28066 4.08417 8.36443 4.0465 8.45662C4.00883 8.54881 3.9899 8.64758 3.99081 8.74716C3.99173 8.84674 4.01246 8.94515 4.05181 9.03663C4.09116 9.12812 4.14834 9.21085 4.22 9.28L6.72 11.78C6.79663 11.8567 6.88896 11.9158 6.99065 11.9534C7.09233 11.9909 7.20094 12.006 7.30901 11.9975C7.41708 11.9891 7.52203 11.9573 7.61663 11.9044C7.71123 11.8515 7.79324 11.7787 7.857 11.691L11.857 6.191Z"
        fill="currentColor"
      />
    </svg>
    <p className="flex-1">{children}</p>
  </div>
);

const Section: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className="mb-12">
    <h2 className="text-2xl text-primary-500 font-bold mb-6">{title}</h2>
    {children}
  </div>
);

const Terms: React.FC = () => {
  useEffect(() => {
    // Scroll to top on component mount
    window.scrollTo(0, 0);

    // Optionally, you can add a cleanup function to scroll to top when the component unmounts
    return () => {
      window.scrollTo(0, 0);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <main className="bg-primary-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <h3 className="font-semibold text-primary-400 mb-4">Terms of Service</h3>
          <h1 className="font-bold text-4xl text-primary-600 mb-10">Terms of Service</h1>
          
          <div className="space-y-8 text-primary-900">
            <p className="text-xl">
              Welcome to Market.Organic! We believe that everyone should be able to access Organic food and goods.
            </p>
            
            <p>
              Market.Organic provides a platform where USDA Certified Organic Operations (the "Certified Operations") and everyone else (the "Buyers") can transact for the sale of goods. These Terms of Service (the "Terms") govern your use of the Market.Organic website and the services we offer (the "Services"). These Services are provided to you by DirectHarvest, Inc. Our Data and Privacy Policy explains how we collect and use your personal data to provide the Services described below, and it is incorporated by reference into these Terms.
            </p>

            <p className="font-bold">
              BY ACCESSING OR USING ANY OF THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THE TERMS. If you are accessing or using our Services on behalf of another party or entity, you represent that you have the legal authority to accept and be bound by the Terms on behalf the party or entity that you represent, and you hereby agree on that party or entity's behalf to be bound by these Terms. If you do not agree to the Terms, you are not authorized to use any Services.
            </p>

          <Section title="Our Services">
            <p>
              Market.Organic allows Certified Operations to advertise products on its website to Buyers who can contact the Certified Operations to buy their products. The actual contract for sale is directly between the Certified Operations and Buyers. Market.Organic is not a party to the transaction. This means that we are not responsible if the purchased items do not meet the Buyer's expectation. All requests for returns or exchanges must be directed to the Certified Operations. Any guidance we provide as part of our Services, such as pricing, shipping, listing, and sourcing is solely informational. We have no control over and do not guarantee the existence, quality, safety or legality of products advertised; the truth or accuracy of content or listings; the ability of Certified Operations to sell items; the ability of Buyers to pay for items; or that a Buyer or Certified Operation will actually complete a transaction.
            </p>
          </Section>

          <Section title="Using Our Services">
            <p className="mb-6">
              In order to buy or sell products through our Services, you will be required to create a Market.Organic account. In exchange for our Services, we need you to make the following commitments:
            </p>
            <div className="space-y-4">
              <BulletPoint>
                You will provide accurate, up-to-date information about yourself or your business (if you are a Certified Operation);
              </BulletPoint>
              <BulletPoint>
                You will be fully responsible for all uses of your account;
              </BulletPoint>
              <BulletPoint>
                You will pay all charges that may be incurred by you or on your behalf when placing an order through the Services, at the price(s) in effect when such charges are incurred, including, without limitation, all shipping and handling charges, taxes and fees;
              </BulletPoint>
              <BulletPoint>
                You will deliver items sold by you, unless you have a valid reason not to (for example, the Buyer fails to comply with the posted terms in your listing or you cannot contact the Buyer);
              </BulletPoint>
              <BulletPoint>
                You will obey all applicable local, state, federal, and international laws in regard to the receipt, possession, use, and sale of any item purchased through the Services;
              </BulletPoint>
              <BulletPoint>
                You will not share your password, give others access to your Market.Organic account, or transfer your account to anyone else (without our permission);
              </BulletPoint>
              <BulletPoint>
                You will not set up an account on behalf of another party or entity unless you are authorized to do so;
              </BulletPoint>
              <BulletPoint>
                You will not use our Services if you are under 18 years old, or if we previously disabled your account for violations of our Terms or policies, or if you are prohibited from using our Services under applicable law;
              </BulletPoint>
              <BulletPoint>
                You will not purchase alcohol unless you are at least 21 years of age;
              </BulletPoint>
              <BulletPoint>
                You will not use our Services to do or share anything that is unlawful, untrue, misleading, discriminatory, fraudulent, libelous, defamatory or that incites an unlawful act;
              </BulletPoint>
              <BulletPoint>
                You will not use our Services to do or share anything that infringes or violates our or someone else's rights, including their intellectual property rights, privacy, confidentiality, or other personal or proprietary rights;
              </BulletPoint>
              <BulletPoint>
                You will not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our website or Services;
              </BulletPoint>
              <BulletPoint>
                You will not access or collect data from our website using automated means (without our prior permission) or attempt to access data you do not have permission to access;
              </BulletPoint>
              <BulletPoint>
                You will not use our Services in ways which violate these Terms and policies.
              </BulletPoint>
            </div>
          </Section>

          <Section title="Policy Enforcement">
            <p>
              At our sole discretion, we may remove, restrict access to or delete your content (without notice) if it is in violation of these provisions. We can also remove, restrict access to or delete content or information if we determine that doing so is reasonably necessary to avoid or mitigate adverse legal or regulatory impacts to Market.Organic. We reserve the right to discontinue your account at any time for any reason or no reason at all.
            </p>
          </Section>

          <Section title="Fees">
            <p>
              Creating an account is always free. Information regarding the pricing of our Services can be found on our FAQs (the "Account Fees"). We reserve the right to adjust pricing for our Services in any manner and at any time. Any changes to pricing will take effect following an update to the FAQs. All fees paid to us are non-refundable without exception.
            </p>
          </Section>

          <Section title="Permissions You Give Us">
            <p>We need certain permissions from you to provide our services:</p>
            <ol className="list-decimal pl-5 space-y-4">
              <li>
                <strong>Permission to use information about your business (for Certified Operations only).</strong> When you create an account you give us permission to use information regarding your business, including but not limited to the phone number, location and agricultural goods which your business produces. Please note that the information we use about your business is made publicly available by the USDA Organic INTEGRITY Database. While we reserve the right to use publicly available information on our Services, you can reach out to us at contact@market.organic if you want your information deleted from our Services.
              </li>
              <li>
                <strong>Permission to use content you create (for Certified Operations only).</strong> Some content that you share may be protected by intellectual property laws. When you share content that is covered by intellectual property rights on or in connection with our Services, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, publicly perform or display, translate, and create derivative works of your content. This license will end when your content is deleted from our systems.
              </li>
              <li>
                <strong>Permission to Contact You.</strong> When you create an account with Market.Organic, you agree to receive communications from us, including (but not limited to) e-mail and text messages, at any of the e-mail addresses and/or telephone numbers provided. You agree that these communications may be initiated for any transactional, customer service, advertising, marketing, promotional, account administration or other purposes. If you wish to opt out of marketing emails or text messages from us, you agree to opt out by following any unsubscribe instructions provided to you in those communications. Standard text messaging and data charges will apply to text messages we may send. Please be advised that if you opt out of marketing communications, we may still send you communications about your account or your transactions.
              </li>
            </ol>
          </Section>

          <Section title="Deletion">
            <p>As a Certified Operation, you can delete content you post individually or all at once by deleting your account. When you delete content, it is no longer visible to other users, however it may continue to exist elsewhere on our systems where:</p>
            <BulletPoint>
              immediate deletion is not possible due to technical limitations, in which case your content will be deleted within a maximum of 90 days from when you delete it;
            </BulletPoint>
            <BulletPoint>
              immediate deletion would restrict our ability to investigate violations of our Terms or comply with a legal obligation, including preserving evidence and complying with requests from law enforcement or government agencies.
            </BulletPoint>
            <p>In each of the above cases, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).</p>
          </Section>

          <Section title="Account suspension or termination">
            <p>
              If we determine that you have breached our Terms or other policies we may suspend or permanently disable access to your account. We may also suspend or disable your account if we are required to do so for legal reasons. If we take such action we may (but are not required to) let you know and explain any options you have to request a review. Market.Organic reserves the right to discontinue your account at any time for any reason or no reason at all.
            </p>
          </Section>

          <Section title="Alcoholic Beverages">
            <p>
              While Certified Operations may use our Services to sell alcoholic beverages, Market.Organic acts only as a third-party marketer and is not a licensed producer, distributor, or retailer of alcoholic beverages. All orders of alcoholic beverages are made to, accepted by, processed by, and fulfilled by a third-party alcoholic beverage licensed vendor or supplier.
            </p>
          </Section>

          <Section title="Limits on Liability">
            <p>
              Our Services are provided "as is," and we make no guarantees that they always will be safe, secure, or error-free, or that they will function without disruptions, delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
            </p>
            <p>
              Our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the Market.Organic Services, even if we have been advised of the possibility of such damages. Our total liability arising out of or relating to these Terms or the Market.Organic Services will not exceed the greater of $100 or the amount you have transacted with us in the past twelve (12) months.
            </p>
            <p>
              To the fullest extent permitted by applicable law, Market.Organic is not responsible or liable whatsoever for any content (including false, offensive, indecent, obscene, unlawful, and other objectionable content) posted on or available through the Services. We do not control what people do or say, and therefore we are not responsible for your use of the Services or the conduct of other parties that use the Services.
            </p>
          </Section>

          <Section title="Indemnification">
            <p>
              If Market.Organic suffers damages as a result of your use of the Services or violation of these Terms, you agree to compensate us for the resulting damages and hold us harmless for any claims made against us.
            </p>
          </Section>

          <Section title="Limits on Using Our Intellectual Property">
            <p>
              We retain all rights to the content which we own and make available to you through our Services. You can only use our copyrights, trademarks or name (or any similar marks) with our prior written permission. You must obtain our written permission to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.
            </p>
          </Section>

          <Section title="Limits on Using Your Trademarks">
            <p>
              Any trademarks used on the Market.Organic website are the registered trademarks of their respective owners. Those marks are used for referential purposes only and are not intended to suggest or imply any affiliation with or endorsement of Market.Organic by their respective owners.
            </p>
          </Section>

          <Section title="Copyright">
            <p>
              Market.Organic prohibits users of the Services from submitting, uploading, posting, or otherwise transmitting any content on the Services that violates another party's proprietary rights. To report allegedly infringing content hosted on our website, please send a notice of infringement containing the following information to our address listed at the end of these Terms:
            </p>
            <BulletPoint>
              A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;
            </BulletPoint>
            <BulletPoint>
              Identification of works or materials being infringed;
            </BulletPoint>
            <BulletPoint>
              Identification of the material that is claimed to be infringing including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that Market.Organic is capable of finding and verifying its existence;
            </BulletPoint>
            <BulletPoint>
              Contact information about the notifier including address, telephone number, and email address;
            </BulletPoint>
            <BulletPoint>
              A statement that the notifier has a good faith belief that the material identified is not authorized by the copyright owner, its agent, or the law; and
            </BulletPoint>
            <BulletPoint>
              A statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.
            </BulletPoint>
            <p>Upon receipt of a proper notice of copyright infringement, we reserve the right to:</p>
            <BulletPoint>
              Remove or disable access to the infringing material;
            </BulletPoint>
            <BulletPoint>
              Notify the content provider who is accused of infringement that we have removed or disabled access to the applicable material; and
            </BulletPoint>
            <BulletPoint>
              Terminate such content provider's access to the Services if he or she is a repeat offender
            </BulletPoint>
          </Section>

          <Section title="Binding Arbitration">
            <p>
              You and Market.Organic agree (a) to waive your and Market.Organic's respective rights to have any and all disputes resolved in a court, and (b) to waive your and Market.Organic's respective rights to a jury trial. Instead, you and Market.Organic agree to arbitrate disputes through binding arbitration (which is the procedure in which a dispute is submitted to one or more persons charged with reviewing the dispute and making a binding resolution instead of going to court). This provision shall apply except for any disputes in which either party seeks to bring an individual action in small claims court or seeks injunctive or other type of equitable relief for the alleged unlawful use of intellectual property.
            </p>
            <p>
              Both parties agree that any arbitrable dispute must be commenced or filed by you or Market.Organic within one (1) year of the cause of action (that is, the date when the dispute arose), otherwise the underlying claim is permanently barred (which means that you and Market.Organic will no longer have the right to assert such claim regarding the dispute).
            </p>
            <p>
              You and Market.Organic agree that any arbitration will be conducted confidentially in accordance with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon the award rendered by the arbitrator may be entered in any court of competent jurisdiction. The parties consent to exclusive jurisdiction and venue in the state or federal courts located in, respectively, Skagit County, Washington, or the Western District of Washington. You and Market.Organic also agree that the laws of the State of Washington will govern these Terms and any claim.
            </p>
          </Section>

          <Section title="Right to Opt Out">
            <p>
              You have the right to opt out of binding arbitration within thirty (30) days of the date you first accepted the Terms by writing to the address stated at the end of these Terms. The opt out notice must include your full name and email address and clearly indicate your intent to opt out of binding arbitration.
            </p>
          </Section>

          <Section title="No Class Arbitrations, Class Actions or Representative Actions">
            <p>
              You and Market.Organic agree that any dispute arising out of or related to these Terms, the Services or products sold on the Services is personal to you and that such dispute will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action, or any other type of representative proceeding.
            </p>
          </Section>

          <Section title="Informal Dispute Resolution">
            <p>
              You agree to notify Market.Organic in writing of any arbitrable or small claims dispute within thirty (30) days of the date it arises, so that both parties can attempt in good faith to resolve the dispute informally. Notice to Market.Organic shall be sent by certified mail or courier to our General Counsel at the address stated at the end of these Terms. Your notice must include (a) your name, postal address, telephone number, the email address you use or used for your Market.Organic account and, if different, an email address at which you can be contacted; (b) a detailed description of the nature or basis of the dispute, and (c) the specific relief that you are seeking. If both parties cannot agree on how to resolve the dispute within thirty (30) days after the date notice is received by Market.Organic, then you may, as appropriate and in accordance with these Terms, commence an arbitration proceeding or file a claim in court.
            </p>
          </Section>

          <Section title="No agency relationship">
            <p>
              You agree that no joint venture, partnership, employment, or agency relationship exists between you and Market.Organic as a result of the Terms or from your use of any of the Services.
            </p>
          </Section>

          <Section title="Survival">
            <p>
              If you delete or we disable your account, these Terms shall terminate as an agreement between you and us, but the provisions regarding limits of liability, indemnification, arbitration and informal dispute resolution will continue to be enforceable. The license grants applicable to your content are also not impacted by the termination of the Terms. Your obligations under the provisions stated in this paragraph will survive for one year after termination.
            </p>
          </Section>

          <Section title="Updating our Terms">
            <p>
              From time to time, we may need to update our Terms and other policies to accurately reflect our Services. When this occurs, we will post updated Terms on our website and indicate the date of revision. We encourage you to check this page periodically for updates. Your use of the Services after we post updated Terms will constitute your acceptance of the changes. If you do not agree to our updated Terms and no longer want to use our Services, you can delete your account at any time.
            </p>
          </Section>

          <Section title="Miscellaneous">
            <p>
              These Terms and any policies incorporated by reference herein make up the entire agreement between you and Market.Organic regarding your use of our Services. They supersede any prior agreements. If any portion of these Terms is found to be unenforceable, the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. Any amendment to or waiver of these Terms must be made in writing and signed by us. You will not transfer any of your rights or obligations under these Terms to anyone else without our consent. These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise. We reserve all rights not expressly granted to you.
            </p>
            <p>
              We always appreciate your feedback and other suggestions about our Services. But you should know that we may use them without any restriction or obligation to compensate you, and we are under no obligation to keep them confidential.
            </p>
          </Section>

          <Section title="Contact Us">
              <p>You can contact us by email at <a href="mailto:contact@market.organic" className="text-blue-400 hover:text-blue-600">contact@market.organic</a>.</p>
              <p className="mt-4 text-primary-400">Date of Last Revision: {new Date().toLocaleDateString()}</p>
            </Section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Terms;
