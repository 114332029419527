import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function AccountIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M5.12104 17.8034C7.15267 16.655 9.4998 15.9996 12 15.9996C14.5002 15.9996 16.8473 16.655 18.879 17.8034M15 9.99963C15 11.6565 13.6569 12.9996 12 12.9996C10.3431 12.9996 9 11.6565 9 9.99963C9 8.34278 10.3431 6.99963 12 6.99963C13.6569 6.99963 15 8.34278 15 9.99963ZM21 11.9996C21 16.9702 16.9706 20.9996 12 20.9996C7.02944 20.9996 3 16.9702 3 11.9996C3 7.02907 7.02944 2.99963 12 2.99963C16.9706 2.99963 21 7.02907 21 11.9996Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            className="stroke-current"
          />
        </React.Fragment>
      )}
    />
  );
}

AccountIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

AccountIcon.defaultProps = {
  className: null,
};

export default AccountIcon;
