import React from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

interface Pagination {
  operationsData: any;
  operationsHandlersData: any;
  listingsData: any;
  asksData: any;
  showType: string[];
}

export const Pagination = ({
  listingsData,
  operationsData,
  operationsHandlersData,
  asksData,
  showType,
}: Pagination) => {
  if (showType.length !== 1) return null;

  const history = useNavigate();
  const [searchParams] = useSearchParams();
  const { query, page: pageStr } = useParams<{ query; page }>();
  const page: number = pageStr ? parseInt(pageStr) : 1;

  let length = 0;
  let totalResults = 0;
  let totalPages = 0;

  switch (showType[0]) {
    case "certified-organic-farms-handlers":
      length = operationsData?.fulltextSearchOperations?.operations.length;
      totalResults = operationsData?.fulltextSearchOperations?.totalResults;
      totalPages = operationsData?.fulltextSearchOperations?.totalPages;
      break;
    case "certified-organic-handlers":
      length =
        operationsHandlersData?.fulltextSearchOperations?.operations.length;
      totalResults =
        operationsHandlersData?.fulltextSearchOperations?.totalResults;
      totalPages = operationsHandlersData?.fulltextSearchOperations?.totalPages;
      break;
    case "for-sale":
      length = listingsData?.fulltextSearchListings?.listings.length;
      totalResults = listingsData?.fulltextSearchListings?.totalResults;
      totalPages = listingsData?.fulltextSearchListings?.totalPages;
      break;
    case "wanted":
      length = asksData?.fulltextSearchAsks?.asks.length;
      totalResults = asksData?.fulltextSearchAsks?.totalResults;
      totalPages = asksData?.fulltextSearchAsks?.totalPages;
      break;
    default:
      break;
  }

  const gotoPage = (page) => {
    if (page === 0) {
      page = 1;
    }

    document
      .getElementById("search-results")
      ?.scrollIntoView({ behavior: "smooth" });

    history(`/search/${query}/${page}?${searchParams}`);
  };

  if (!length) return;

  return (
    <div className="mt-4">
      <div className="text-sm text-gray-500">
        Showing {length} results of {totalResults}
      </div>
      <nav className="flex gap-2 text-sm text-slate-400">
        <div className="text-gray-500">
          Page {page} of {totalPages}
        </div>
      </nav>
      <div className="flex gap-2 mt-4">
        <button
          disabled={page < 2}
          className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 gap-1 disabled:pointer-events-none disabled:opacity-50"
          onClick={() => gotoPage(page - 1)}
        >
          <ArrowLeftIcon className="size-4" />
          Prev
        </button>
        <button
          disabled={page === totalPages}
          className="inline-flex items-center px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 gap-1 disabled:pointer-events-none disabled:opacity-50"
          onClick={() => gotoPage(page + 1)}
        >
          <ArrowRightIcon className="size-4" />
          Next
        </button>
      </div>
    </div>
  );
};
