import React, { useEffect } from "react";
import StepsNavigator from "../signup/StepsNavigator";
import { useLocation, useNavigate } from "react-router-dom";
import Step1 from "../signup/Step1";
import StepUserProfile from "../signup/StepUserProfile";
import StepBusinessProfile from "../signup/StepBusinessProfile";
import StepPlans from "../signup/StepPlans";
import StepVerification from "../signup/StepVerification";
import Complete from "../signup/Complete";
import scrollToTop from "../Utils/ScrollToTop";

const stepsData = [
  { name: "Email & password", route: "/signup", fields: ["email", "password"] },
  { name: "Email verification", route: "/signup/verification" },
  { name: "User profile", route: "/signup/user-profile" },
  { name: "Business profile", route: "/signup/business-profile" },
  { name: "Select a plan", route: "/signup/plans" },
];

function Signup() {
  const { pathname } = useLocation();
  const currentStep = stepsData.find((step) => step.route === pathname);

  const renderSteps = () => {
    switch (currentStep.route) {
      case "/signup":
        return <Step1 />;
      case "/signup/verification":
        return <StepVerification />;
      case "/signup/user-profile":
        return <StepUserProfile />;
      case "/signup/business-profile":
        return <StepBusinessProfile />;
      case "/signup/plans":
        return <StepPlans />;
      case "/signup/complete":
        return <Complete />;
      default:
        return "";
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [currentStep]);

  return (
    <main>
      <div className="container">
        <div className="max-w-[768px] mx-auto py-12 md:py-20">
          <div className="mb-12">
            <StepsNavigator stepsData={stepsData} />
          </div>
          <div>
            <>{renderSteps()}</>
          </div>
        </div>
      </div>
    </main>
  );
}

export { Signup, stepsData };
