import React from "react";
import { Variation } from "../../services/graphql-custom-types";
import { Listing } from "../../services/graphql-custom-types";
import { ListingDetails } from "./ListingDetails";

function ListingDetailsWrapper({
  listing,
  cartView = false,
  cartVariation,
  cartQuantity,
}: {
  listing: Listing;
  cartView?: boolean;
  cartVariation?: Variation;
  cartQuantity?: string;
}) {
  return (
    <div className="listing listing--details">
      <ListingDetails
        listing={listing}
        cartView={cartView}
        cartVariation={cartVariation}
        cartQuantity={cartQuantity}
      />
    </div>
  );
}

export { ListingDetailsWrapper };
