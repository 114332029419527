import React from "react";
import { Modal } from "../modals/Modal";
import { InputField } from "../forms/InputField";

function AddProductModal({
  selectedProduct,
  setSelectedProduct,
  showModal,
  handleClose,
  handleSubmit,
}) {
  if (!showModal) return null;
  return (
    <Modal
      title="Add Product"
      closeLabel="Cancel"
      submitLabel="Add"
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    >
      <InputField
        value={selectedProduct}
        handleChange={(e) => setSelectedProduct(e.target.value)}
      />
    </Modal>
  );
}

export { AddProductModal };
