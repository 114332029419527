import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function CompanyVerificationIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M9 12L11 14L15 10M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            stroke="currentColor"
          />
        </React.Fragment>
      )}
    />
  );
}

CompanyVerificationIcon.propTypes = {
  className: PropTypes.string,
};

CompanyVerificationIcon.defaultProps = {
  className: null,
};

export default CompanyVerificationIcon;
