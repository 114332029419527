import React, { useState } from "react";

import {
  Steps,
  ClaimOperationSearch,
  ClaimOperationInitialStep,
  ClaimOperationSuccessStep,
} from ".";
import { Operation } from "../../graphql/generated-types";

function ClaimOperation({
  operation: initialOperation,
}: {
  operation?: Operation;
}) {
  const startWith = initialOperation ? Steps.InitialStep : Steps.FindOperation;
  const [currentStep, setCurrentStep] = useState<Steps>(startWith);
  const [operation, setOperation] = useState(initialOperation);

  const handleFindOperationByName = (operation) => {
    setOperation(operation);
    setCurrentStep(Steps.InitialStep);
  };

  switch (currentStep) {
    case Steps.FindOperation:
      return (
        <ClaimOperationSearch
          handleFindOperationByName={handleFindOperationByName}
        />
      );
    case Steps.InitialStep:
      return (
        <ClaimOperationInitialStep
          operation={operation}
          setCurrentStep={setCurrentStep}
        />
      );
    case Steps.ClaimSuccessStep:
      return <ClaimOperationSuccessStep operation={operation} />;
    default:
      return (
        <ClaimOperationSearch
          handleFindOperationByName={handleFindOperationByName}
        />
      );
  }
}

export { ClaimOperation };
