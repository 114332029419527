import React, { useRef } from "react";
import PropTypes from "prop-types";
import { OldCreateOrEditListingInput } from "../../services/graphql-custom-types";
import { InputField } from "../forms/InputField";
import { ChipSelect } from "../ChipSelect";

function TermsStep({
  listing,
  handleChange,
}: {
  listing: OldCreateOrEditListingInput;
  handleChange: any;
}) {
  // Prepare data for ChipSelect:
  // Default data and previously saved data is a raw number in mins, convert it back to weeks and store back into an object
  // If a new chip gets selected then we have an object with the key value in weeks and can use that formatting as is
  let derivedExpirationPeriod;
  if (Object.keys(listing.expirationPeriod)?.length > 0) {
    derivedExpirationPeriod = listing.expirationPeriod;
  } else {
    derivedExpirationPeriod = {
      [listing.expirationPeriod / 10080]: true,
    };
  }
  // Default data and previously saved data is a string, reformat it back into an object
  // If a new chip gets selected then we have an object and can use that formatting as is
  let derivedAvailability;
  if (typeof listing.availability === "string") {
    derivedAvailability = {
      wholesale: listing.availability.includes("wholesale"),
      contract: listing.availability.includes("contract"),
      consumer: listing.availability.includes("consumer"),
    };
  } else {
    derivedAvailability = listing.availability;
  }

  const expiryOtherRef = useRef(null);
  const handleChipSelect = (name, value) => {
    handleChange({
      target: {
        name: name,
        value: value,
      },
    });
    if (name === "expirationPeriod") expiryOtherRef.current.value = null;
  };

  const handleExpiryOther = (e) => {
    const formatExpiryOther = {
      [parseInt(e.target.value, 10)]: true,
    };
    handleChange({
      target: {
        name: "expirationPeriod",
        value: formatExpiryOther,
      },
    });
  };

  return (
    <>
      <div className="create-listing--terms-step create-active-listing__fields">
        <h1 className="create-listing__title interface-text--heading">
          {listing.title}
        </h1>
        <fieldset className="create-listing__fieldset">
          <h2 className="create-listing__label interface-text--label">
            Expiration
          </h2>
          <ChipSelect
            single={{
              2: "2 Weeks",
              4: "4 Weeks",
              8: "8 Weeks",
            }}
            className="create-listing__chip-select"
            selected={derivedExpirationPeriod}
            handleChange={(value) =>
              handleChipSelect("expirationPeriod", value)
            }
          />
          <InputField
            ref={expiryOtherRef}
            name="expirationPeriodOther"
            placeholder="Other (in weeks)"
            handleChange={handleExpiryOther}
            inputType="number"
            autoComplete="tel"
          />
        </fieldset>
        <fieldset>
          <h2 className="create-listing__label interface-text--label">
            Availability
          </h2>
          <ChipSelect
            multiple={{
              wholesale: "Wholesale",
              consumer: "Consumer",
              contract: "Contract",
            }}
            selected={derivedAvailability}
            handleChange={(value) => handleChipSelect("availability", value)}
          />
        </fieldset>
      </div>
    </>
  );
}

export { TermsStep };
