import React from "react";
import classNames from "classnames";

interface ISelectFieldProps {
  options: any;
  className?: string;
  name?: string;
  value?: string;
  handleChange?: any;
  required?: boolean;
}

function SelectField({
  options,
  className,
  name,
  value,
  handleChange,
  required,
}: ISelectFieldProps) {
  const classes = classNames(className, {
    "select-wrapper": true,
  });
  return (
    <div className={classes}>
      <select
        className="select-field"
        name={name}
        value={value}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}
        required={required}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export { SelectField };
