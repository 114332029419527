import React from "react";

function LogoLandscape({ className = "fill-primary-600" }) {
  return (
    <>
      <svg
        width="17"
        height="33"
        viewBox="0 0 17 33"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.55551 13.9211L3.4428 10.7284C1.2693 9.04122 0 6.44509 0 3.69551V0.5L4.11203 3.69208C6.28416 5.37855 7.55551 7.97468 7.55551 10.7236V13.9211Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29004 13.9211L13.4007 10.7284C15.5742 9.04122 16.8435 6.44509 16.8435 3.69551V0.5L12.7328 3.69208C10.5593 5.37855 9.29004 7.97468 9.29004 10.7236V13.9211Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.55551 23.2094L3.4428 20.018C1.2693 18.3316 0 15.7354 0 12.9852V9.78827L4.11203 12.9831C6.28416 14.6689 7.55551 17.2643 7.55551 20.0153V23.2094Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29004 23.2094L13.4007 20.018C15.5742 18.3316 16.8435 15.7354 16.8435 12.9852V9.78827L12.7328 12.9831C10.5593 14.6689 9.29004 17.2643 9.29004 20.0153V23.2094Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.55551 32.5L3.4428 29.3079C1.2693 27.6201 0 25.0246 0 22.2757V19.0789L4.11203 22.2716C6.28416 23.9581 7.55551 26.5542 7.55551 29.3038V32.5Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29004 32.5L13.4007 29.3079C15.5742 27.6201 16.8435 25.0246 16.8435 22.2757V19.0789L12.7328 22.2716C10.5593 23.9581 9.29004 26.5542 9.29004 29.3038V32.5Z"
        />
      </svg>
    </>
  );
}

export default LogoLandscape;
