import { PlanData, planData } from "../data";
import { useGetStripeProductsQuery } from "../graphql/generated-types";

export const useProductList = () => {
  const { data: stripeProductsData, loading: stripeProductsLoading } =
    useGetStripeProductsQuery();

  const stripePremiumPlanData = stripeProductsData?.stripeProducts[0];
  const price = stripePremiumPlanData?.unitAmount
    ? Number(stripePremiumPlanData.unitAmount) / 100
    : undefined;
  const priceId = stripePremiumPlanData?.priceId || undefined;

  const plans: PlanData = {
    ...planData,
    premium: {
      ...planData.premium,
      price,
      recurring: stripePremiumPlanData?.recurring,
      priceId,
    },
  };

  return { plans, loading: stripeProductsLoading };
};
