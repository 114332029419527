import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function SellingIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M7 7H7.01M7 3H12C12.5119 2.99999 13.0237 3.19525 13.4142 3.58579L20.4143 10.5858C21.1953 11.3668 21.1953 12.6332 20.4143 13.4142L13.4142 20.4142C12.6332 21.1953 11.3668 21.1953 10.5858 20.4142L3.58579 13.4142C3.19526 13.0237 3 12.5118 3 12V7C3 4.79086 4.79086 3 7 3Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
          />
        </React.Fragment>
      )}
    />
  );
}

SellingIcon.propTypes = {
  className: PropTypes.string,
};

SellingIcon.defaultProps = {
  className: null,
};

export default SellingIcon;
