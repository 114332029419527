import React, { useState, useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { OperationHeader } from ".";
import { Listings } from "../Listings";
import { ClaimOperation } from "../ClaimOperation";
import { Modal, ModalSizes } from "../modals/Modal";

function OperationPublicView({
  operation,
  userOwnsOperation,
  isSquareLinked = false,
}) {
  const [showClaimModal, setShowClaimModal] = useState(false);
  // const { isAuthenticated, loginWithRedirect } = useAuth0();
  const isAuthenticated = true; // TODO: Change me

  useEffect(() => {
    if (location.search.match(/authRedirect/)) {
      setShowClaimModal(true);
    }
  }, []);

  const handleToggleClaimModal = () => {
    if (isAuthenticated) {
      setShowClaimModal(!showClaimModal);
    } else {
      // loginWithRedirect({
      //   redirectUri: `${window.location.origin}/authorize?redirectUrl=/?authRedirect`,
      // });
    }
  };

  if (!operation) {
    return (
      <p className="interface-text--supporting">
        Sorry, we couldn&apos;t find that operation. Please go back and try
        again.
      </p>
    );
  }

  const renderClaimModal = () => {
    if (!showClaimModal) return null;
    return (
      <Modal
        size={ModalSizes.LARGE}
        className="claim-operation"
        handleClose={() => setShowClaimModal(false)}
        title="Claim Your Market.Organic Profile"
        preventOffclick
        useCloseIcon
      >
        <ClaimOperation operation={operation} />
      </Modal>
    );
  };

  return (
    <>
      <main className="operation-view operation-public-view main">
        <OperationHeader
          operation={operation}
          userOwnsOperation={userOwnsOperation}
        />

        {renderClaimModal()}

        <div className="operation-listings gutters hidden">
          <Listings
            className="operation-listings__listings"
            operation={operation}
            isSquareLinked={isSquareLinked}
            toggleClaimModal={handleToggleClaimModal}
          />
        </div>
      </main>
    </>
  );
}

export { OperationPublicView };
