import React, { useState } from "react";
import { Chip } from "../Chip";
import { Button, ButtonSizes, ButtonStyleVariations } from "../Button";
import { EditProductModal, AddProductModal } from ".";

function OperationProductsAdmin({ operation }) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [products, setProducts] = useState(operation.products);

  const handleAddProductSubmit = () => {
    setProducts([
      ...products,
      {
        name: selectedProduct,
      },
    ]);
    closeModal("add");
  };

  const handleEditProduct = (product) => {
    openModal("edit");
    setSelectedProduct(product.name);
  };

  const openModal = (mode) => {
    if (mode === "add") {
      setAddModalOpen(true);
    } else {
      setEditModalOpen(true);
    }
  };

  const closeModal = (mode) => {
    setSelectedProduct(null);
    if (mode === "add") {
      setAddModalOpen(false);
    } else {
      setEditModalOpen(false);
    }
  };

  const handleEditProductSubmit = (editedProduct, isRemove) => {
    if (isRemove) {
      setProducts(
        products.filter((product) => product.name !== selectedProduct),
      );
    } else {
      setProducts(
        products.map((product) => {
          if (product.name === selectedProduct) {
            return { name: editedProduct };
          }
          return product;
        }),
      );
    }
    closeModal("edit");
  };

  return (
    <>
      <h2 className="operation-edit__heading interface-text--heading--small">
        Edit your product list
      </h2>
      <ul className="operation-edit__product-list chip-select">
        {products.map((product) => (
          <Chip onClick={() => handleEditProduct(product)} key={product.name}>
            {product.name}
          </Chip>
        ))}
        <Button
          name="add-product"
          label="Add Product"
          styleVariation={ButtonStyleVariations.DESELECTED}
          size={ButtonSizes.SMALL}
          onClick={() => openModal("add")}
        />
      </ul>
      <AddProductModal
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        showModal={addModalOpen}
        handleClose={() => closeModal("add")}
        handleSubmit={handleAddProductSubmit}
      />
      <EditProductModal
        selectedProduct={selectedProduct}
        showModal={editModalOpen}
        handleSubmit={handleEditProductSubmit}
        handleClose={() => closeModal("edit")}
      />
      <Button
        onClick={() => alert("Saving product changes")}
        label="Confirm and Publish"
        name="save-products"
        styleVariation={ButtonStyleVariations.PRIMARY}
      />
    </>
  );
}

export { OperationProductsAdmin };
