import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Plan } from "../data";

type HomeProductCardProps = {
  plan: Plan;
  handleClick: () => void;
  loading: boolean;
  cardStyle: "left" | "middle" | "right";
};

export const HomeProductCard: React.FC<HomeProductCardProps> = ({
  plan,
  handleClick,
  loading,
  cardStyle,
}) => {
  const {
    price,
    recurring,
    name,
    description,
    features,
    subscribeButtonText = "Subscribe",
  } = plan;

  // Corrected price formatting
  const formattedPrice = React.useMemo(() => {
    if (price === null || price === undefined) return "Free";
    const numericPrice = typeof price === "string" ? parseFloat(price) : price;
    return numericPrice === 0 ? "Free" : `$${Math.round(numericPrice)}`;
  }, [price]);

  return (
    <div
      className={classNames("p-10 rounded-3xl flex flex-col justify-between", {
        "lg:rounded-tr-none lg:rounded-br-none mt-8 bg-primary-50":
          cardStyle === "left",
        "bg-primary-700 text-white -mb-8": cardStyle === "middle",
        "lg:rounded-tl-none lg:rounded-bl-none mt-8 bg-primary-50":
          cardStyle === "right",
      })}
    >
      <div>
        <h3
          className={classNames("text-lg mb-4 font-semibold", {
            "text-primary-600": cardStyle !== "middle",
          })}
        >
          {name}
        </h3>
        <p className="text-sm mb-6">{description}</p>
        <div
          className={classNames(
            "flex items-baseline mb-10 gap-x-1 font-semibold",
            { "text-primary-600": cardStyle !== "middle" }
          )}
        >
          <span className="text-5xl font-general">{formattedPrice}</span>
          {recurring && <span>/{recurring}</span>}
        </div>
        <div>
          <ul className="flex flex-col gap-3 ">
            {features?.map((feature, index) => (
              <li key={index} className="flex gap-x-3 text-sm leading-relaxed">
                <CheckIcon
                  className="h-6 w-5 flex-none text-green-600"
                  aria-hidden="true"
                />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <button
        disabled={loading}
        onClick={handleClick}
        className="border border-primary-300 bg-white hover:bg-primary-50 rounded-full font-semibold px-4 py-3 block w-full text-center shadow-sm mt-16 text-primary-700 disabled:opacity-40"
      >
        {loading ? "Loading..." : subscribeButtonText}
      </button>
    </div>
  );
};