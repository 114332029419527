import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function FavoriteIcon(props) {
  return (
    <Icon
      name="favorite"
      size={20}
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          {props.selected ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5128 5.85887L18.97 6.67985C19.2944 6.73185 19.5508 6.88785 19.7374 7.14884C19.924 7.40883 20.0121 7.69483 19.9987 8.00882C19.9843 8.31982 19.8676 8.59381 19.6427 8.8278L15.6812 12.8517L16.6151 18.5546C16.6649 18.8676 16.6094 19.1586 16.4486 19.4316C16.285 19.7056 16.0544 19.8826 15.7558 19.9606C15.4563 20.0396 15.1702 19.9996 14.8956 19.8436L9.99913 17.1876L5.1027 19.8436C4.82807 19.9996 4.54196 20.0396 4.24341 19.9606C3.9439 19.8826 3.71425 19.7056 3.55157 19.4316C3.3889 19.1586 3.3334 18.8676 3.38316 18.5546L4.31805 12.8517L0.355548 8.8278C0.131635 8.59381 0.0139367 8.31982 0.00149708 8.00882C-0.0128563 7.69483 0.0761348 7.40883 0.262729 7.14884C0.45028 6.88785 0.703857 6.73185 1.02824 6.67985L6.48541 5.85887L8.91497 0.702984C9.0652 0.390991 9.28433 0.181996 9.57044 0.0779983C9.85464 -0.0259994 10.1436 -0.0259994 10.4297 0.0779983C10.7149 0.181996 10.934 0.390991 11.0833 0.702984L13.5128 5.85887Z"
              fill={color}
            />
          ) : (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.0952 7.48914L12.1626 6.57794L9.50517 1L6.84867 6.57794L0.952381 7.48914L5.21061 11.8538L4.22758 18L9.50517 15.0779L14.8191 18L13.8007 11.8538L18.0952 7.48914ZM13.5128 5.85887L18.97 6.67985C19.2944 6.73185 19.5508 6.88785 19.7374 7.14884C19.924 7.40883 20.0121 7.69483 19.9987 8.00882C19.9843 8.31982 19.8676 8.59381 19.6427 8.8278L15.6812 12.8517L16.6151 18.5546C16.6649 18.8676 16.6094 19.1586 16.4486 19.4316C16.285 19.7056 16.0544 19.8826 15.7558 19.9606C15.4563 20.0396 15.1702 19.9996 14.8956 19.8436L9.99913 17.1876L5.1027 19.8436C4.82807 19.9996 4.54196 20.0396 4.24341 19.9606C3.9439 19.8826 3.71425 19.7056 3.55157 19.4316C3.3889 19.1586 3.3334 18.8676 3.38316 18.5546L4.31805 12.8517L0.355548 8.8278C0.131635 8.59381 0.0139367 8.31982 0.00149708 8.00882C-0.0128563 7.69483 0.0761348 7.40883 0.262729 7.14884C0.45028 6.88785 0.703857 6.73185 1.02824 6.67985L6.48541 5.85887L8.91497 0.702984C9.0652 0.390991 9.28433 0.181996 9.57044 0.0779983C9.85464 -0.0259994 10.1436 -0.0259994 10.4297 0.0779983C10.7149 0.181996 10.934 0.390991 11.0833 0.702984L13.5128 5.85887Z"
              fill={color}
            />
          )}
        </React.Fragment>
      )}
    />
  );
}

FavoriteIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  selected: PropTypes.bool,
};

FavoriteIcon.defaultProps = {
  className: null,
};

export default FavoriteIcon;
