import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function SearchIcon(props) {
  return (
    <Icon
      name="search"
      size={20}
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5809 14.0816C12.6359 13.4716 13.4684 12.6374 14.0809 11.5816C14.6934 10.5274 15.0001 9.37493 15.0001 8.12493C15.0001 6.87492 14.6934 5.72159 14.0809 4.66825C13.4684 3.61241 12.6359 2.77991 11.5809 2.16824C10.5259 1.5574 9.37503 1.2499 8.12503 1.2499C6.87502 1.2499 5.72169 1.5574 4.66668 2.16824C3.61168 2.77991 2.77917 3.61241 2.16667 4.66825C1.55417 5.72159 1.25 6.87492 1.25 8.12493C1.25 9.37493 1.55417 10.5274 2.16667 11.5816C2.77917 12.6374 3.61168 13.4716 4.66668 14.0816C5.72169 14.6941 6.87502 15 8.12503 15C9.37503 15 10.5259 14.6941 11.5809 14.0816M20.0001 19.1408C20.0001 19.27 19.9609 19.3883 19.8826 19.4916L19.4917 19.8433C19.3859 19.9483 19.2709 20 19.1401 20C19.0076 20 18.9059 19.9483 18.8276 19.8433L13.7884 14.805C13.6826 14.7258 13.6326 14.6233 13.6326 14.4916V14.1008C12.9009 14.78 12.0617 15.3066 11.1125 15.6858C10.1617 16.0608 9.16421 16.25 8.12503 16.25C6.64003 16.25 5.27919 15.885 4.04168 15.1558C2.80418 14.4275 1.82001 13.4441 1.09334 12.2058C0.364168 10.9716 0 9.60994 0 8.12493C0 6.64076 0.364168 5.27992 1.09334 4.04325C1.82001 2.80658 2.80418 1.82241 4.04168 1.09324C5.27919 0.364902 6.64003 -9.91821e-05 8.12503 -9.91821e-05C9.60921 -9.91821e-05 10.9684 0.364902 12.2059 1.09324C13.4434 1.82241 14.4267 2.80658 15.1559 4.04325C15.8826 5.27992 16.2501 6.64076 16.2501 8.12493C16.2501 9.16577 16.0601 10.1633 15.6826 11.1124C15.3042 12.065 14.7759 12.905 14.1009 13.6325H14.4917C14.6201 13.6325 14.7392 13.6858 14.8434 13.7891L19.8826 18.8275C19.9609 18.9066 20.0001 19.0108 20.0001 19.1408"
            fill={color}
          />
        </React.Fragment>
      )}
    />
  );
}

SearchIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

SearchIcon.defaultProps = {
  className: null,
};

export default SearchIcon;
