import { useUser } from "@clerk/clerk-react";
import React from "react";
import { Link } from "react-router-dom";

export const NoResultSection = () => {
  const { isSignedIn } = useUser();

  return (
    <div className="px-8 py-6 bg-primary-50 flex flex-col md:flex-row items-start md:items-center justify-between gap-6 rounded-3xl">
      <h3 className="text-primary-600 md:max-w-[400px] w-full">
        Create a listing to let thousands of Certified Organic farms and
        businesses find you.
      </h3>
      <Link
        to={isSignedIn ? "/marketplace/create" : "/signin"}
        className="bg-white hover:bg-primary-50 text-primary-700 px-4 pr-6 py-2 rounded-full inline-flex items-center gap-2 font-medium border-primary-300 border"
      >
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 5V10M10 10V15M10 10H15M10 10L5 10"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div>Create Listing</div>
      </Link>
    </div>
  );
};
