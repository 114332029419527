/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import classNames from "classnames";
import { Textarea } from "./Textarea";

interface ITextareaFieldProps {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  inputClass?: string;
  labelClass?: string;
  readOnly?: boolean;
  disabled?: boolean;
  handleChange?: (any) => void;
  onKeyUp?: (any) => void;
  required?: boolean;
  autoComplete?: string;
  minLength?: number;
  maxLength?: number;
  children?: React.ReactNode;
}

const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaFieldProps
>((props, ref) => {
  const {
    name,
    label,
    placeholder,
    value,
    inputClass,
    labelClass,
    readOnly = false,
    disabled,
    handleChange,
    onKeyUp,
    required,
    autoComplete,
    minLength,
    maxLength,
  } = props;

  const inputClasses = classNames(inputClass, {
    "input-field__input": true,
    "input-field": !label,
  });
  const labelClasses = classNames(labelClass, {
    "input-field__label": true,
    "interface-text--label": true,
  });

  const inputElement = (
    <Textarea
      name={name}
      ref={ref}
      className={inputClasses}
      placeholder={placeholder}
      value={value}
      handleChange={handleChange}
      onKeyUp={onKeyUp}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      autoComplete={autoComplete}
      minLength={minLength}
      maxLength={maxLength}
    />
  );

  const inputArea = inputElement;

  if (label) {
    return (
      <label className="input-field">
        <span className={labelClasses}>{label}</span>
        {inputArea}
      </label>
    );
  }
  return inputArea;
});

export { TextareaField };
