/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import classNames from "classnames";

interface IInputProps {
  name?: string;
  label?: string;
  inputType?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  labelClass?: string;
  readOnly?: boolean;
  disabled?: boolean;
  hasInlineAction?: boolean;
  inlineAction?: (any) => void;
  actionLabel?: string;
  handleChange?: (any) => void;
  onKeyUp?: (any) => void;
  required?: boolean;
  autoComplete?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
}

const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const {
    name,
    inputType = "text",
    placeholder,
    value,
    className,
    readOnly = false,
    disabled = false,
    handleChange,
    onKeyUp,
    required,
    autoComplete,
    min,
    max,
    minLength,
    maxLength,
  } = props;

  const classes = classNames(className, {
    "input-element": true,
  });

  return (
    <input
      name={name}
      ref={ref}
      className={classes}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyUp={onKeyUp}
      type={inputType}
      readOnly={readOnly}
      disabled={disabled}
      required={required}
      autoComplete={autoComplete}
      min={min}
      max={max}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
});

export { Input };
