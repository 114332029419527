import React from "react";
import PropTypes from "prop-types";

function ProductList({ operation }) {
  if (!operation.products) return null;

  return operation.products.map((p, i) => (
    <span className="interface-text--list--small" key={p.id || i}>
      {operation.products.length - 1 === i ? p.name : `${p.name}, `}
    </span>
  ));
}

ProductList.propTypes = {
  operation: PropTypes.object.isRequired,
};

export { ProductList };
