import React from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

interface StepsNavigatorProps {
  stepsData: {
    route: string;
    name: string;
  }[];
}

const StepsNavigator = ({ stepsData }: StepsNavigatorProps) => {
  const { pathname } = useLocation();
  const currentIndex = stepsData.findIndex((step) => step.route === pathname);

  return (
    <>
      <nav aria-label="Progress">
        <ol role="list" className="flex items-center justify-center">
          {stepsData.map((step, index) => {
            const rootClass = classNames({
              relative: true,
              "pr-8 md:pr-20": index + 1 !== stepsData.length,
            });

            const linkClass = classNames({
              "rounded-full w-8 h-8 flex relative items-center justify-center border-primary-300 border-2 hover:border-primary-500 group outline-green-900":
                true,
              "border-primary-600 [&_.rounded-indicator]:bg-primary-600 [&_.rounded-indicator]:hover:bg-primary-600":
                index === currentIndex,
              "bg-primary-600 border-primary-600": index < currentIndex,
              "bg-white": index >= currentIndex,
              "pointer-events-none":
                index >= currentIndex || step.route === "/signup/verification",
            });

            return (
              <li className={rootClass} key={index}>
                <div className="absolute flex items-center inset-0 ">
                  <div className="bg-primary-200 w-full h-0.5"></div>
                </div>
                <a
                  href={index >= currentIndex ? null : step.route}
                  className={linkClass}
                >
                  {index < currentIndex ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                      className="w-5 h-5 text-white"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  ) : (
                    <span
                      className="bg-transparent rounded-full w-2.5 h-2.5 group-hover:bg-primary-300 rounded-indicator"
                      aria-hidden="true"
                    />
                  )}
                  <span className="sr-only">{step.name}</span>
                </a>
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default StepsNavigator;
