import React, { Fragment, useEffect, useState } from "react";
import { Portal } from "react-portal";
import { Dialog, Transition } from "@headlessui/react";

enum ModalSizes {
  SMALL,
  MEDIUM,
  LARGE,
}

enum ModalPositions {
  CENTER,
  ANCHOR_TOP,
}

interface IModalProps {
  size?: ModalSizes;
  position?: ModalPositions;
  title?: string;
  children: React.ReactNode;
  className?: string;
  handleSubmit?: (any) => void;
  handleClose?: (any) => void;
  closeLabel?: string;
  submitLabel?: string;
  closeVisible?: boolean;
  submitDisabled?: boolean;
  preventOffclick?: boolean;
  useCloseIcon?: boolean;
}

// The Modal makes use of react-portal in order to render modal content outside
// the component tree in which the modal is invoked from
function Modal({
  size = ModalSizes.SMALL,
  position = ModalPositions.CENTER,
  title,
  children,
  className,
  handleSubmit,
  handleClose,
  closeLabel = "Close",
  submitLabel = "Confirm",
  submitDisabled = false,
  preventOffclick = false,
  useCloseIcon = false,
}: IModalProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    document.body.classList.add("modal-prevent-overflow");
    return () => document.body.classList.remove("modal-prevent-overflow");
  }, []);

  // const modalClasses = classNames(className, {
  //   modal: true,
  //   "modal--small": size === ModalSizes.SMALL,
  //   "modal--medium": size === ModalSizes.MEDIUM,
  //   "modal--large": size === ModalSizes.LARGE,
  //   "modal--anchor-top": position === ModalPositions.ANCHOR_TOP,
  // });
  // const modalOverlayClasses = classNames({
  //   "modal-overlay": true,
  //   "modal-overlay--center": position !== ModalPositions.ANCHOR_TOP,
  //   "modal-overlay--anchor-top": position === ModalPositions.ANCHOR_TOP,
  // });

  // TODO: Update to new <dialog>, along with functionality and styling per spec
  //       https://morganic.atlassian.net/browse/MOS-137

  return (
    <Portal>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={preventOffclick ? undefined : handleClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full max-w-[512px]">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start relative">
                      <div className="w-full">
                        {useCloseIcon && handleClose && (
                          <button
                            type="button"
                            className="text-gray-400 absolute right-0"
                            onClick={handleClose}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 18L18 6M6 6L18 18"
                                stroke="#9CA3AF"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                        {title && (
                          <Dialog.Title
                            as="h3"
                            className="text-xl font-semibold leading-6 "
                          >
                            {title}
                          </Dialog.Title>
                        )}
                        <div className="mt-5">{children}</div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Portal>
  );
}

export { Modal, ModalSizes, ModalPositions };
