import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import { gql, useMutation } from "@apollo/client";
import { InputField } from "../forms/InputField";
import { Button, ButtonStyleVariations } from "../Button";

import { ClaimOperationStepParams } from ".";
import { useRecoilValue } from "recoil";
import { enteredOperationEmailState, enteredOperationPhoneState } from "../../store/recoil";

const VERIFY_CODE = gql`
  mutation Verify2faCode(
    $phoneOrEmail: String!
    $code: String!
    $operationId: Int!
  ) {
    verify2faCode(
      input: {
        phoneOrEmail: $phoneOrEmail
        code: $code
        operationId: $operationId
      }
    )
  }
`;

function ClaimOperationSuccessStep({ operation }: ClaimOperationStepParams) {
  const enteredPhone = useRecoilValue(enteredOperationPhoneState);
  const enteredEmail = useRecoilValue(enteredOperationEmailState);
  const [code, setCode] = useState("");
  // const { isAuthenticated, loginWithRedirect } = useAuth0();
  const isAuthenticated = true; // TODO: Change me
  const navigate = useNavigate();

  const [sendVerifyCode, { loading, error }] = useMutation(VERIFY_CODE, {
    variables: {
      code,
      phoneOrEmail: enteredPhone || enteredEmail,
      operationId: operation.id,
    },
    onCompleted: (data) => {
      // data: {verify2faCode: "approved"} => "success"
      if (!isAuthenticated) {
        // loginWithRedirect({
        //   redirectUri: `${window.location.origin}/authorize?redirectUrl=/?authRedirect`,
        // });
      } else {
        // this doesn't seem to be redirecting here
        navigate(`/operations/${operation.id}`);
      }
    },
  });

  return (
    <>
      <h2 className="interface-text--heading--small">Almost there!</h2>
      <p className="claim-operation__description interface-text--body">
        We sent you a code, please check your email or phone to confirm your
        account.
      </p>
      <InputField
        placeholder="Code"
        inputType={"text"}
        value={code}
        handleChange={(e) => setCode(e.target.value)}
      />
      <div className="modal__controls claim-success__buttons claim-operation__buttons">
        <Button
          name="find-operation"
          label="Next"
          styleVariation={ButtonStyleVariations.PRIMARY}
          onClick={() => sendVerifyCode()}
          disabled={loading}
        />
      </div>
      {error && (
        <div>Wrong code, please call +1-877-216-7426 for assistance.</div>
      )}
    </>
  );
}

export { ClaimOperationSuccessStep };
