import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function PlansIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M21 7.5L12 2.25L3 7.5M21 7.5L12 12.75M21 7.5V16.5L12 21.75M3 7.5L12 12.75M3 7.5V16.5L12 21.75M12 12.75V21.75"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            stroke="currentColor"
          />
        </React.Fragment>
      )}
    />
  );
}

PlansIcon.propTypes = {
  className: PropTypes.string,
};

PlansIcon.defaultProps = {
  className: null,
};

export default PlansIcon;
