import React from "react";
import { Link, useLocation } from "react-router-dom";
import LogoLandscape from "./assets/LogoLandscape";
import {
  SignedIn,
  SignedOut,
  OrganizationSwitcher,
  UserButton as ClerkUserButton,
} from "@clerk/clerk-react";
import { UserButton } from "./account/UserButton";

interface IHeaderProps {
  isHome?: boolean;
}

function MainHeader({ isHome }: IHeaderProps) {
  const location = useLocation();
  const shouldHideMainContent =
    location.pathname.startsWith('/signup/user-profile') ||
    location.pathname === '/signup/business-profile' ||
    location.pathname === '/signup/plans';
  const isCompletePage = location.pathname === '/signup/complete';

  return (
    <header className="header shadow-sm relative h-[64px]">
      <div className="container h-full">
        <div className="py-3 flex items-center justify-between h-full">
          <Link to="/">
            <LogoLandscape />
          </Link>
          {(!shouldHideMainContent || isCompletePage) && (
            <nav className="flex items-center gap-x-2 md:gap-x-4">
              <Link
                to="/search"
                className="text-primary-500 p-2 font-medium hover:text-primary-400 text-lg"
              >
                <span className="hidden md:inline">Search</span>
                <span className="md:hidden">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </Link>
              <SignedIn>
                <Link
                  to="/marketplace"
                  className="text-primary-500 p-2 font-medium hover:text-primary-400 text-lg"
                >
                  <span className="hidden md:inline">My Listings</span>
                  <span className="md:hidden">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <Link
                  to="/account"
                  className="text-primary-500 p-2 font-medium hover:text-primary-400 hidden md:inline text-lg"
                >
                  My Account
                </Link>
                <OrganizationSwitcher
                  hidePersonal
                  appearance={{
                    elements: {
                      rootBox: "flex-shrink-0",
                      organizationSwitcherTrigger: "hidden md:flex text-lg"
                    }
                  }}
                />
                <UserButton />
              </SignedIn>
              <SignedOut>
                <Link
                  to="/signup"
                  className="text-primary-500 p-2 font-medium hover:text-primary-400 text-lg"
                >
                  <span className="hidden md:inline">Sign up</span>
                  <span className="md:inline lg:hidden">Sign up</span>
                </Link>
                <Link
                  to="/signin"
                  className="text-primary-500 p-2 font-medium hover:text-primary-400 text-lg"
                >
                  Sign in
                </Link>
              </SignedOut>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
}

export { MainHeader };