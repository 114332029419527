import React, { useEffect, useState } from "react";
import InputField from "../marketplace/InputField";
import LogoLandscape from "../assets/LogoLandscape";
import { useSignIn, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

type SignInInputs = {
  emailAddress: string;
  password: string;
};

function Signin() {
  const { isLoaded: isClerkLoaded, signIn, setActive } = useSignIn();
  const { isSignedIn } = useUser();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [clerkError, setClerkError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInputs>();

  const onSubmit: SubmitHandler<SignInInputs> = async ({
    emailAddress,
    password,
  }) => {
    setLoading(true);
    setClerkError("");

    try {
      const result = await signIn.create({
        identifier: emailAddress,
        password,
      });
      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });
        navigate("/account");
      } else {
        console.warn(result);
      }
    } catch (err: any) {
      console.warn(JSON.stringify(err, null, 2));
      setClerkError(err.errors[0].message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isSignedIn) navigate("/account");
  }, [isSignedIn]);

  return (
    <main>
      <section className="bg-gray-50 py-14 pb-32 justify-center flex flex-col">
        <div className="text-center">
          <div className="flex items-center justify-center text-primary-600">
            <LogoLandscape className="h-[48px] w-[25px]" />
          </div>
          <h2 className="text-gray-900 font-semibold text-3xl mt-6">
            Sign in to your account
          </h2>
        </div>

        <div className="max-w-[480px] w-full mx-auto shadow-md shadow-black/5 bg-white mt-10 rounded-lg">
          <div className="py-8 px-4 md:p-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-5">
                <InputField
                  label="Email address"
                  name="emailAddress"
                  type="email"
                  required
                  register={register("emailAddress", {
                    required: true,
                  })}
                />
                <InputField
                  label="Password"
                  type="password"
                  name="password"
                  required
                  register={register("password", {
                    required: true,
                  })}
                />
              </div>

              {clerkError && (
                <div className="bg-red-50 px-3 py-2 rounded-md ring-1 ring-red-100 mt-4">
                  <p className="text-red-500 text-xs font-medium">
                    {clerkError}
                  </p>
                </div>
              )}

              <div className="flex justify-between items-center mt-6">
                <div className="flex items-center">
                  {/* 
                  <input id="remember-me" name="remember-me" type="checkbox" />
                  <label htmlFor="remember-me" className="ml-3 text-sm">
                    Remember me
                  </label>*/}
                </div>
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="text-primary-500 p-2 font-medium hover:text-primary-400 link"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={!isClerkLoaded || loading}
                  className="rounded-full px-4 py-2 block w-full text-center shadow-sm bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium disabled:bg-gray-400 disabled:pointer-events-none"
                >
                  {loading ? "loading" : "Sign in"}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* <SignIn /> */}
      </section>
    </main>
  );
}

const SocialConnect = () => {
  return (
    <div>
      <div className="mt-4 md:mt-10 relative">
        <div
          aria-hidden="true"
          className="flex items-center inset-0 absolute pointer-events-none"
        >
          <div className="border-t border-gray-300 w-full" />
        </div>
        <div className="flex relative justify-center">
          <span className="px-6 bg-white text-primary-500">
            Or continue with
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-6">
        <button className="flex item-center justify-center rounded-md border border-primary-300 px-4 py-2 shadow-sm hover:bg-gray-50 text-primary-500">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.3334 10C20.3334 4.477 15.8564 0 10.3334 0C4.81037 0 0.333374 4.477 0.333374 10C0.333374 14.991 3.99037 19.128 8.77137 19.878V12.891H6.23137V10H8.77137V7.797C8.77137 5.291 10.2634 3.907 12.5484 3.907C13.6424 3.907 14.7864 4.102 14.7864 4.102V6.562H13.5264C12.2834 6.562 11.8964 7.333 11.8964 8.124V10H14.6694L14.2264 12.89H11.8964V19.878C16.6764 19.128 20.3334 14.991 20.3334 10Z"
              fill="currentColor"
            />
          </svg>
        </button>
        <button className="flex item-center justify-center rounded-md border border-primary-300 px-4 py-2 shadow-sm hover:bg-gray-50 text-primary-500">
          <svg
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.29 16.251C13.837 16.251 17.965 9.998 17.965 4.576C17.965 4.398 17.965 4.221 17.953 4.046C18.7562 3.46453 19.4493 2.74462 20 1.92C19.2511 2.25201 18.4566 2.46976 17.643 2.566C18.4996 2.05306 19.1408 1.24635 19.447 0.296003C18.6417 0.773889 17.7607 1.11073 16.842 1.292C16.2234 0.633768 15.405 0.197837 14.5136 0.0517074C13.6222 -0.0944221 12.7075 0.057402 11.9111 0.483672C11.1147 0.909941 10.4811 1.58688 10.1083 2.40966C9.7355 3.23245 9.64437 4.15519 9.849 5.035C8.21759 4.95324 6.6216 4.52933 5.16465 3.79077C3.70769 3.05221 2.42233 2.01553 1.392 0.748002C0.867318 1.65121 0.706589 2.72042 0.942536 3.73797C1.17848 4.75551 1.79337 5.64488 2.662 6.225C2.00926 6.20607 1.37065 6.03047 0.8 5.713V5.765C0.800389 6.7123 1.1284 7.63031 1.7284 8.36336C2.3284 9.09641 3.16347 9.59939 4.092 9.787C3.48781 9.95172 2.85389 9.97566 2.239 9.857C2.50116 10.6725 3.01168 11.3856 3.69913 11.8966C4.38658 12.4076 5.21657 12.691 6.073 12.707C5.22212 13.3756 4.24779 13.8698 3.20573 14.1615C2.16367 14.4532 1.07432 14.5367 0 14.407C1.8766 15.6113 4.06019 16.2501 6.29 16.247" />
          </svg>
        </button>
        <button className="flex item-center justify-center rounded-md border border-primary-300 px-4 py-2 shadow-sm hover:bg-gray-50 text-primary-500">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6666 0C5.14363 0 0.666626 4.484 0.666626 10.017C0.666626 14.442 3.53163 18.197 7.50563 19.521C8.00563 19.613 8.18763 19.304 8.18763 19.038C8.18763 18.801 8.17963 18.17 8.17463 17.335C5.39263 17.94 4.80563 15.992 4.80563 15.992C4.35163 14.834 3.69563 14.526 3.69563 14.526C2.78763 13.906 3.76463 13.918 3.76463 13.918C4.76763 13.988 5.29563 14.95 5.29563 14.95C6.18763 16.48 7.63663 16.038 8.20563 15.782C8.29763 15.135 8.55563 14.694 8.84163 14.444C6.62163 14.191 4.28663 13.331 4.28663 9.493C4.28663 8.4 4.67663 7.505 5.31563 6.805C5.21263 6.552 4.86963 5.533 5.41363 4.155C5.41363 4.155 6.25363 3.885 8.16363 5.181C8.97939 4.95851 9.82106 4.84519 10.6666 4.844C11.5166 4.848 12.3716 4.959 13.1706 5.181C15.0796 3.885 15.9176 4.154 15.9176 4.154C16.4636 5.533 16.1206 6.552 16.0176 6.805C16.6576 7.505 17.0456 8.4 17.0456 9.493C17.0456 13.341 14.7066 14.188 12.4796 14.435C12.8386 14.745 13.1576 15.356 13.1576 16.291C13.1576 17.629 13.1456 18.71 13.1456 19.038C13.1456 19.306 13.3256 19.618 13.8336 19.52C15.8249 18.8521 17.556 17.5753 18.7821 15.87C20.0082 14.1648 20.6675 12.1173 20.6666 10.017C20.6666 4.484 16.1886 0 10.6666 0Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export { Signin, SocialConnect };
