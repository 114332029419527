import React from "react";
import classNames from "classnames";

enum ButtonStyleVariations {
  UNSTYLED,
  BASE,
  BASE_BROWN,
  OVERLAY,
  OVERLAY_LIGHT,
  PRIMARY,
  PRIMARY_GREEN,
  SECONDARY,
  SECONDARY_INVERSE,
  TAG,
  TAG_INVERSE,
  DESELECTED,
  GREEN,
  KHAKI,
  BROWN,
  CLAY,
  PAPER,
  DESTROY,
  DESTROY_TEXT,
}

enum ButtonSizes {
  ICON,
  SMALL,
  DEFAULT,
  SMALL_FULL_WIDTH,
  DEFAULT_FULL_WIDTH,
}

interface IButtonProps {
  name: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  label?: string;
  children?: React.ReactNode;
  onClick?: (Event) => void;
  loading?: boolean;
  styleVariation?: ButtonStyleVariations;
  size?: ButtonSizes;
  className?: string;
}

function Button({
  name,
  type = "button",
  disabled = false,
  label,
  children,
  onClick,
  loading = false,
  styleVariation = ButtonStyleVariations.BASE,
  size = ButtonSizes.DEFAULT,
  className,
}: IButtonProps) {
  const handleClick = (e) => {
    if (onClick && !loading) onClick(e);
  };

  const buttonClasses = classNames(className, {
    "button--base": styleVariation !== ButtonStyleVariations.UNSTYLED,
    "button--unstyled": styleVariation === ButtonStyleVariations.UNSTYLED,
    "button--base-brown": styleVariation === ButtonStyleVariations.BASE_BROWN,
    "button--overlay": styleVariation === ButtonStyleVariations.OVERLAY,
    "button--overlay-light":
      styleVariation === ButtonStyleVariations.OVERLAY_LIGHT,
    "button--primary": styleVariation === ButtonStyleVariations.PRIMARY,
    "button--primary-green":
      styleVariation === ButtonStyleVariations.PRIMARY_GREEN,
    "button--secondary": styleVariation === ButtonStyleVariations.SECONDARY,
    "button--secondary-inverse":
      styleVariation === ButtonStyleVariations.SECONDARY_INVERSE,
    "button--tag":
      styleVariation === ButtonStyleVariations.TAG ||
      styleVariation === ButtonStyleVariations.TAG_INVERSE,
    "button--tag--inverse":
      styleVariation === ButtonStyleVariations.TAG_INVERSE,
    "button--deselected": styleVariation === ButtonStyleVariations.DESELECTED,
    "button--full-width": size === ButtonSizes.DEFAULT_FULL_WIDTH,
    "button--small":
      size === ButtonSizes.SMALL || size === ButtonSizes.SMALL_FULL_WIDTH,
    "button--small--full-width": size === ButtonSizes.SMALL_FULL_WIDTH,
    "button--icon": size === ButtonSizes.ICON,
    "button--green": styleVariation === ButtonStyleVariations.GREEN,
    "button--brown": styleVariation === ButtonStyleVariations.BROWN,
    "button--clay": styleVariation === ButtonStyleVariations.CLAY,
    "button--khaki": styleVariation === ButtonStyleVariations.KHAKI,
    "button--paper": styleVariation === ButtonStyleVariations.PAPER,
    "button--destroy": styleVariation === ButtonStyleVariations.DESTROY,
    "button--destroy-text":
      styleVariation === ButtonStyleVariations.DESTROY_TEXT,
  });

  return (
    <button
      name={name}
      type={type}
      className={buttonClasses}
      disabled={disabled || loading}
      onClick={handleClick}
    >
      {label || children}
    </button>
  );
}

export { Button, ButtonStyleVariations, ButtonSizes };
