import React, { useState } from "react";
import { OperationHeader } from ".";
import { Listings } from "../Listings";
import { ClaimOperation } from "../ClaimOperation";
import { Modal, ModalSizes } from "../modals/Modal";
import { Operation } from "../../graphql/generated-types";
interface OperationProfileInfoParams {
  operation: Operation;
  isSquareLinked?: boolean;
  userOwnsOperation?: boolean;
}

function OperationOwnerView({
  operation,
  isSquareLinked = false,
  userOwnsOperation,
}: OperationProfileInfoParams) {
  const [showClaimModal, setShowClaimModal] = useState(false);

  if (!operation) {
    return (
      <p className="interface-text--supporting">
        Sorry, we couldn't find that operation. Please go back and try again.
      </p>
    );
  }

  const handleToggleClaimModal = () => {
    setShowClaimModal(!showClaimModal);
  };

  const renderClaimModal = () => {
    if (!showClaimModal) return null;
    return (
      <Modal
        size={ModalSizes.LARGE}
        className="claim-operation"
        handleClose={handleToggleClaimModal}
        preventOffclick
        useCloseIcon
      >
        <ClaimOperation operation={operation as Operation} />
      </Modal>
    );
  };

  return (
    <>
      <main className="operation-view operation-owner-view main">
        <OperationHeader
          operation={operation}
          userOwnsOperation={userOwnsOperation}
        />

        {renderClaimModal()}

        <div className="operation-listings gutters hidden">
          <Listings
            className="operation-listings__listings"
            isSquareLinked={isSquareLinked}
            operation={operation as Operation}
            userOwnsOperation={userOwnsOperation}
            toggleClaimModal={handleToggleClaimModal}
          />
        </div>
      </main>
    </>
  );
}

export { OperationOwnerView };
