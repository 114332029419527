import React from "react";
import { Portal } from "react-portal";
import classNames from "classnames";

function Toast({
  children,
  handleClose,
  className,
}: {
  children: React.ReactNode;
  handleClose?: (any) => void;
  className?: string;
}) {
  const classes = classNames(className, {
    toast: true,
  });

  return (
    <Portal>
      <div className={classes} onClick={handleClose}>
        <p className="toast__message interface-text--body--large">{children}</p>
      </div>
    </Portal>
  );
}

export { Toast };
