import React, { useState } from "react";
import Step1 from "../marketplace/Step1";
import Step2 from "../marketplace/Step2";
import Sidebar from "../Sidebar";
import { marketplaceSidebarLinks } from "./Marketplace";
import { useSearchParams } from "react-router-dom";

export type listingType = "buying" | "selling";

export const MarketplaceCreateOrEdit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const listingTypeURL = searchParams.get("listingType") as listingType;
  const listingId = Number(searchParams.get("id")) as number;
  const [listingType, setListingType] = useState(listingTypeURL || "selling");

  const onChangeListing = (listingType: listingType) => {
    setListingType(listingType);
  };

  return (
    <div className="pb-20 md:pt-20">
      <div className="container">
        <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
          <aside className="max-md:border-b max-md:-ml-4 max-md:px-4 max-md:w-[calc(100%_+_2rem)] md:w-48 lg:w-64">
            <Sidebar links={marketplaceSidebarLinks} />
          </aside>
          <main className="flex-1">
            {!listingTypeURL ? (
              <Step1
                listingType={listingType}
                onChangeListing={onChangeListing}
              />
            ) : (
              <Step2 listingType={listingType} listingId={listingId} />
            )}
          </main>
        </div>
      </div>
    </div>
  );
};
