import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputField from "./InputField";
import { SubmitHandler, useForm } from "react-hook-form";
import classNames from "classnames";
import { listingType } from "../views/MarketplaceCreateOrEdit";
import { notifyUpdated } from "../Utils/notifyUpdated";
import {
  AsksDocument,
  ListingsDocument,
  useCreateOrEditAskMutation,
  useCreateOrEditListingMutation,
  useFullAskQuery,
  useFullListingQuery,
  useGetMeQuery,
} from "../../graphql/generated-types";

interface StepProp {
  listingType: listingType;
  listingId?: number;
}

type CreateListingInput = {
  id: null;
  title?: string;
  description?: string;
  minQty?: string;
  availability?: string[];
  expirationPeriod?: number;
  status?: string;
};

const Step2 = ({ listingType, listingId }: StepProp) => {
  const navigate = useNavigate();
  const { data: meData } = useGetMeQuery();

  const organizationId = meData?.me?.organizations?.[0]?.id || "";
  const operationId = meData?.me?.organizations?.[0]?.operations?.[0]?.id || "";

  const [defaultValues, setDefaultValues] = useState({
    title: "",
    description: "",
    minQty: null,
    availability: "",
  });

  const [createListing, { loading: loadingCreateListing }] =
    useCreateOrEditListingMutation({
      onCompleted: (data) => {
        const listing = data.createOrEditListing;
        navigate(`/marketplace/list/selling`);
        notifyUpdated({
          text: listingId ? "Listing updated" : "Listing created",
        });
      },
      refetchQueries: [
        {
          query: ListingsDocument,
          variables: {
            operationId: operationId,
          },
        },
      ],
    });

  const [createAsk, { loading: loadingCreateAsk }] = useCreateOrEditAskMutation(
    {
      onCompleted: (data) => {
        const listing = data.createOrEditAsk;
        navigate(`/marketplace/list/buying`);
        notifyUpdated({
          text: listingId ? "Listing updated" : "Listing created",
        });
      },
      refetchQueries: [
        {
          query: AsksDocument,
          variables: {
            organizationId: organizationId,
          },
        },
      ],
    },
  );

  const {
    loading: loadingListing,
    error: errorListing,
    data: dataListing,
  } = useFullListingQuery({
    variables: { id: listingId },
    skip: !listingId || listingType === "buying",
    onCompleted: (data) => {
      setDefaultValues({
        title: data.listing.title,
        description: data.listing.description,
        minQty: data.listing.minQty,
        availability: data.listing.availability,
      });
    },
  });

  const {
    loading: loadingAsk,
    error: errorAsk,
    data: dataAsk,
  } = useFullAskQuery({
    variables: { id: listingId },
    skip: !listingId || listingType === "selling",
    onCompleted: (data) => {
      setDefaultValues({
        title: data.ask.title,
        description: data.ask.description,
        minQty: null,
        availability: "",
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateListingInput>();

  const onSubmit: SubmitHandler<CreateListingInput> = async (input) => {
    try {
      if (listingType === "selling") {
        const { availability, ...inputData } = input;
        const availabilityValue = availability?.join(", ") || "";

        const result = await createListing({
          variables: {
            ...inputData,
            minQty: inputData.minQty?.toString(),
            availability: availabilityValue,
            id: listingId ? listingId : null,
          },
        });
      } else {
        const result = await createAsk({
          variables: { ...input, id: listingId ? listingId : null },
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getFieldTitle = (field: string) => {
    const titles = {
      selling: {
        title: "Product or service name",
        description: "Product or service description",
        minQty: "Minimum order quantity",
      },
      buying: {
        title: "What are you looking for?",
        description: "Describe what you need",
      },
    };
    return titles[listingType][field] || field;
  };

  const getFieldPlaceholder = (field: string) => {
    const placeholders = {
      selling: {
        title: "e.g., Organic Apples",
        description: "Describe your product or service in detail...",
        minQty: "e.g., 100 lbs",
      },
      buying: {
        title: "e.g., Organic Strawberries",
        description: "Provide details about what you're looking for...",
      },
    };
    return placeholders[listingType][field] || "";
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classNames({
        "pointer-events-none opacity-50":
          loadingCreateListing || loadingCreateAsk,
      })}
    >
      <div className="mt-4 mb-6"></div>
      <div className="pb-4 mb-2 flex justify-between items-center">
        <div>
          <h1 className="text-gray-900 font-semibold text-lg">
            {listingType === "selling"
              ? "Goods or services for sale"
              : "Goods or services wanted"}
          </h1>
          <p className="mt-0.5 text-sm">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>

      {listingType === "selling" && (
        <div className="p-4 rounded-lg mb-6 bg-blue-50 flex items-center justify-between gap-4">
          <div className="flex items-start gap-4">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="fill-blue-400"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z"
              />
            </svg>

            <p className="max-w-[450px] text-blue-800 font-medium text-sm">
              Your Certified Organic Goods sell listings will be visible on
              Market.Organic once you verify your farm or business.
            </p>
          </div>
          <div>
            <Link
              to="/"
              className="text-blue-700 p-1.5 hover:text-blue-900 font-medium text-sm"
            >
              Verify your farm or business →
            </Link>
          </div>
        </div>
      )}

      <div>
        <div className="flex flex-col gap-6">
          <InputField
            label={getFieldTitle("title")}
            register={register("title", {
              required: true,
            })}
            defaultValue={defaultValues.title}
            disabled={loadingAsk || loadingListing}
            placeholder={getFieldPlaceholder("title")}
          />
          <InputField
            label={getFieldTitle("description")}
            type="textarea"
            register={register("description", {
              required: true,
            })}
            defaultValue={defaultValues.description}
            disabled={loadingAsk || loadingListing}
            placeholder={getFieldPlaceholder("description")}
          />

          {listingType === "selling" && (
            <>
              <InputField
                label={getFieldTitle("minQty")}
                register={register("minQty")}
                defaultValue={defaultValues.minQty}
                disabled={loadingAsk || loadingListing}
                placeholder={getFieldPlaceholder("minQty")}
              />
              <div className="border-t border-b py-6 mt-2">
                <label className="font-medium mb-3 block">Availability</label>
                <div className="flex flex-col gap-2">
                  <label className="text-sm font-medium">
                    <input
                      type="checkbox"
                      className="mr-1"
                      name="availability"
                      value="Wholesale"
                      {...register("availability")}
                      defaultChecked={defaultValues.availability.includes(
                        "Wholesale",
                      )}
                    />
                    Wholesale
                  </label>
                  <label className="text-sm font-medium">
                    <input
                      type="checkbox"
                      className="mr-1"
                      name="availability"
                      value="Consumer"
                      {...register("availability")}
                      defaultChecked={defaultValues.availability.includes(
                        "Consumer",
                      )}
                    />
                    Consumer
                  </label>
                  <label className="text-sm font-medium">
                    <input
                      type="checkbox"
                      className="mr-1"
                      name="availability"
                      value="Contract"
                      {...register("availability")}
                      defaultChecked={defaultValues.availability.includes(
                        "Contract",
                      )}
                    />
                    Contract
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex justify-end mt-6 gap-6">
        <Link
          to="/marketplace"
          className="border border-primary-300 hover:bg-gray-50 px-6 py-3 rounded-full font-semibold shadow-sm"
        >
          Cancel
        </Link>

        <button
          disabled={loadingCreateListing || loadingCreateAsk}
          type="submit"
          className="bg-primary-600 hover:bg-primary-700 text-white px-6 py-3 rounded-full font-semibold shadow-sm disabled:bg-gray-400 disabled:pointer-events-none"
        >
          Publish Listing
        </button>
      </div>
    </form>
  );
};

export default Step2;
