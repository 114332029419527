import React from "react";
import Sidebar, { LinkType } from "../Sidebar";
import AccountIcon from "../icons/Account";
import CompanyInformationIcon from "../icons/CompanyInformation";
import CompanyVerificationIcon from "../icons/CompanyVerification";
import PlansIcon from "../icons/Plans";

const menuLinks: LinkType[] = [
  {
    icon: <AccountIcon />,
    route: "/account",
    label: "Account",
  },
  {
    icon: <CompanyInformationIcon />,
    route: "/account/company-information",
    label: "Organization Information",
  },
  {
    icon: <CompanyVerificationIcon />,
    route: "/account/company-verification",
    label: "Organic Verification",
  },
  {
    icon: <PlansIcon />,
    route: "/account/plans",
    label: "Plans",
  },
];

const AccountContainer = ({ children }) => {
  return (
    <>
      <div className="pb-20 md:pt-20">
        <div className="container">
          <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
            <aside className="max-md:border-b max-md:-ml-4 max-md:px-4 max-md:w-[calc(100%_+_2rem)] md:w-48 lg:w-64">
              <Sidebar links={menuLinks} />
            </aside>
            <main className="flex-1">{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountContainer };
