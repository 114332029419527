import React, { useState } from "react";
import { Listing } from "../../services/graphql-custom-types";
import classNames from "classnames";
import Styles from "../../config/Styles";
import { Chip, ChipActiveStyles } from "../Chip";
import FavoriteIcon from "../icons/Favorite";
import { ListingDetails } from "./ListingDetails";
import { friendlyDateFormat } from "../Utils/DateFormat";
import { Button } from "../Button";
import { Modal, ModalSizes } from "../modals/Modal";
import { CreateOrEditListing } from ".";
import { Operation } from "../../graphql/generated-types";

function ListingCompact({
  operation,
  listing,
  isPrivate = false,
}: {
  operation: Operation;
  listing: Listing;
  isPrivate: boolean;
}) {
  const [selected, setSelected] = useState(false);
  const [expandDetails, setExpandDetails] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleToggleListingDetails = () => {
    setExpandDetails(!expandDetails);
  };

  const handleToggleEditModal = (e) => {
    e && e.stopPropagation();
    setShowEditModal(!showEditModal);
  };

  const handleExpandDetailsClick = () => {
    !expandDetails ? handleToggleListingDetails() : undefined;
  };

  const handleEditListingCallback = () => {
    setShowEditModal(!showEditModal);
    location.reload(); // Hack until we're able to reload parent components when graphQl is updated
  };

  const renderEditModal = () => {
    if (!showEditModal) return null;
    return (
      <Modal
        size={ModalSizes.LARGE}
        className="create-listing"
        handleClose={handleToggleEditModal}
      >
        <CreateOrEditListing
          listingToEdit={listing}
          operation={operation}
          callback={handleEditListingCallback}
          handleClose={(e) => handleToggleEditModal(e)}
        />
      </Modal>
    );
  };

  const listingClass = classNames({
    listing: true,
    "listing--compact": !expandDetails,
    "listing--details": expandDetails,
    "listing--compact--public": !isPrivate,
  });

  return (
    <div className={listingClass} onClick={handleExpandDetailsClick}>
      {renderEditModal()}

      {expandDetails ? (
        <ListingDetails
          listing={listing}
          isPrivate={isPrivate}
          handleToggleListingDetails={handleToggleListingDetails}
          handleToggleEditModal={handleToggleEditModal}
        />
      ) : (
        <>
          <div className="listing__date-row">
            {!isPrivate && (
              <div
                className="listing__favorite"
                onClick={() => setSelected(!selected)}
              >
                <FavoriteIcon
                  color={Styles.COLORS.viridian}
                  selected={selected}
                />
              </div>
            )}
            <p className="listing__date interface-text--list--small">
              {friendlyDateFormat(listing.createdAt)}
            </p>
          </div>
          <h2 className="listing--compact__name interface-text--list--small">
            {listing.title}
            {listing.description && (
              <span className="listing--compact__description">
                &nbsp;|&nbsp;{listing.description}
              </span>
            )}
          </h2>
          <div className="listing--compact__chips listing__chips">
            <Chip
              active={Boolean(listing.availability?.includes("contract"))}
              activeStyle={ChipActiveStyles.BROWN}
            >
              Contract
            </Chip>
            <Chip
              active={Boolean(listing.availability?.includes("wholesale"))}
              activeStyle={ChipActiveStyles.KHAKI}
            >
              Wholesale
            </Chip>
            <Chip
              active={Boolean(listing.availability?.includes("consumer"))}
              activeStyle={ChipActiveStyles.CLAY}
            >
              Consumer
            </Chip>
            {isPrivate && (
              <Button
                label="Edit"
                name="edit-listing"
                className="listing--compact__edit"
                onClick={(e) => handleToggleEditModal(e)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export { ListingCompact };
