import React, { useEffect } from "react";

import { useOrganization, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const IsLoggedInWrapper = ({ children }) => {
  const { isSignedIn, isLoaded: isUserLoaded } = useUser();
  const { organization, isLoaded: isOrganizationLoaded } = useOrganization();
  const navigate = useNavigate();

  if (!isUserLoaded || !isOrganizationLoaded) return <AccountSkeleton />;

  if (!isSignedIn) {
    navigate("/signin");
  } else if (!organization) {
    navigate("/signup/business-profile");
  }

  return children;
};

export const AccountSkeleton = () => {
  return (
    <div className="pb-20 md:pt-20">
      <div className="container">
        <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
          <aside className="max-md:border-b max-md:-ml-4 max-md:px-4 max-md:w-[calc(100%_+_2rem)] md:w-48 lg:w-64">
            <Skeleton count={4} height={36} style={{ marginBottom: 8 }} />
          </aside>
          <main className="flex-1">
            <Skeleton count={5} height={45} style={{ marginBottom: 28 }} />

            <div className="mt-20"></div>
            <Skeleton count={5} height={45} style={{ marginBottom: 28 }} />
          </main>
        </div>
      </div>
    </div>
  );
};

export { IsLoggedInWrapper };
