import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function CompanyInformationIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M19 21V5C19 3.89543 18.1046 3 17 3H7C5.89543 3 5 3.89543 5 5V21M19 21L21 21M19 21H14M5 21L3 21M5 21H10M9 6.99998H10M9 11H10M14 6.99998H15M14 11H15M10 21V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V21M10 21H14"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
            stroke="currentColor"
          />
        </React.Fragment>
      )}
    />
  );
}

CompanyInformationIcon.propTypes = {
  className: PropTypes.string,
};

CompanyInformationIcon.defaultProps = {
  className: null,
};

export default CompanyInformationIcon;
