import React, { useEffect } from "react";
import { Link } from "../Link";

const PolicySection = ({ title, content }) => (
  <div className="mb-12">
    <h2 className="text-2xl text-primary-500 font-bold mb-6">{title}</h2>
    {content}
  </div>
);

const BulletPoint = ({ children }) => (
  <div className="flex items-start space-x-3 mb-4">
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-primary-400 flex-shrink-0 mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.857 6.191C11.9149 6.11129 11.9566 6.02095 11.9796 5.92514C12.0026 5.82933 12.0065 5.72994 11.991 5.63262C11.9756 5.5353 11.9412 5.44198 11.8897 5.35797C11.8382 5.27396 11.7707 5.20091 11.691 5.143C11.6113 5.08509 11.5209 5.04344 11.4251 5.02044C11.3293 4.99744 11.2299 4.99354 11.1326 5.00895C11.0353 5.02437 10.942 5.0588 10.858 5.11028C10.774 5.16176 10.7009 5.22929 10.643 5.309L7.16 10.099L5.28 8.219C5.21078 8.1474 5.128 8.0903 5.03647 8.05104C4.94495 8.01178 4.84653 7.99114 4.74694 7.99032C4.64736 7.9895 4.54861 8.00852 4.45646 8.04628C4.3643 8.08403 4.28059 8.13976 4.2102 8.21021C4.13982 8.28066 4.08417 8.36443 4.0465 8.45662C4.00883 8.54881 3.9899 8.64758 3.99081 8.74716C3.99173 8.84674 4.01246 8.94515 4.05181 9.03663C4.09116 9.12812 4.14834 9.21085 4.22 9.28L6.72 11.78C6.79663 11.8567 6.88896 11.9158 6.99065 11.9534C7.09233 11.9909 7.20094 12.006 7.30901 11.9975C7.41708 11.9891 7.52203 11.9573 7.61663 11.9044C7.71123 11.8515 7.79324 11.7787 7.857 11.691L11.857 6.191Z"
        fill="currentColor"
      />
    </svg>
    <p className="flex-1">{children}</p>
  </div>
);

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-primary-50">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <h3 className="font-semibold text-primary-400 mb-4">
            Privacy Policy
          </h3>
          <h1 className="font-bold text-4xl text-primary-600 mb-10">
            Data and Privacy Policy
          </h1>
          <div className="space-y-8 text-primary-900">
            <p className="text-xl">
              Market.Organic believes digital privacy is very important. We do not sell any of your
              personal information to anyone. This Data and Privacy Policy (the "Policy") describes
              the information we process to support Market.Organic, our websites and services we
              offer (the "Services").
            </p>
            
            <p>
              The Policy covers our treatment of personally identifiable information ("Personal Information")
              that we process when you are accessing or using our Services. It applies to all Market.Organic users,
              including Certified Organic Operations (the "Certified Operations") and everyone else ("Buyers"),
              as well as job applicants. Please remember that your use of the Services is also subject to our Terms of Use.
            </p>

            <p>
            This Policy does not apply to the practices of third parties we do not own or control, unless
              specifically stated. Our California Consumer Privacy Act Disclosure explains certain rights
              for California residents, and our GDPR Compliance Statement outlines additional rights for
              individuals in the European Union. Both are incorporated by reference into this Policy.
              DirectHarvest, Inc. is the Data Controller of any information collected from you by Market.Organic.
            </p>

            <PolicySection
              title="Legal Bases for Processing"
              content={
                <div className="space-y-4">
                  <p>We process your personal data on the following legal bases:</p>
                  <BulletPoint>
                    Consent: Where you have given us explicit consent to process your personal data for a specific purpose.
                  </BulletPoint>
                  <BulletPoint>
                    Contract: Where processing is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into a contract.
                  </BulletPoint>
                  <BulletPoint>
                    Legal Obligation: Where processing is necessary for compliance with a legal obligation to which we are subject.
                  </BulletPoint>
                  <BulletPoint>
                    Legitimate Interests: Where processing is necessary for the purposes of the legitimate interests pursued by us or by a third party, except where such interests are overridden by your interests or fundamental rights and freedoms.
                  </BulletPoint>
                </div>
              }
            />

            <PolicySection
              title="Types of Information We Collect"
              content={
                <div className="space-y-4">
                  <p>When you use our Services, we collect and receive information in various ways:</p>
                  <BulletPoint>
                    Public information: Market.Organic obtains public information about Certified
                    Operations from the USDA Organic Integrity Database.
                  </BulletPoint>
                  <BulletPoint>
                    Information and content you provide: We collect the content, communications
                    and other information you provide when you use our Services.
                  </BulletPoint>
                  <BulletPoint>
                    Information about Your usage: We automatically collect technical information
                    when you use our Services, including through cookies and related technologies.
                  </BulletPoint>
                  <BulletPoint>
                    Information provided by your device: We collect information from the computers,
                    phones, and other web-connected devices you use that integrate with our Services.
                  </BulletPoint>
                  <BulletPoint>
                    Information about transactions: If you use our Services for purchases, our
                    third-party payment processor, Square, collects information about the transaction.
                  </BulletPoint>
                </div>
              }
            />

            <PolicySection
              title="How We Use Your Information"
              content={
                <div className="space-y-4">
                  <p>We use the information we collect to provide and support the Market.Organic Services. Here's how:</p>
                  <BulletPoint>
                    Promote safety, integrity and security: We use the information we have to verify accounts
                    and activity, combat harmful conduct, detect and prevent spam and other bad experiences.
                  </BulletPoint>
                  <BulletPoint>
                    Communicate with you: We use the information we have to send you marketing communications,
                    communicate with you about our Services, and let you know about our Terms and policies.
                  </BulletPoint>
                  <BulletPoint>
                    Product research and development: We use the information we have to develop, test and improve our Services.
                  </BulletPoint>
                </div>
              }
            />

            <PolicySection
              title="How Your Information is Shared"
              content={
                <div className="space-y-4">
                  <p>We do not sell any of your personal information to anyone. We also do not share personal information
                  with third parties for their own direct marketing purposes without obtaining your consent.</p>
                  <p>Your information is shared with others in the following ways:</p>
                  <BulletPoint>
                    People and parties you sell to: As a Certified Operation, you can use our Services to create
                    posts which advertise your products. These posts become public information.
                  </BulletPoint>
                  <BulletPoint>
                    Certified Operations you buy from: When you buy something through our Services, the Certified
                    Operation can receive information needed to complete the transaction.
                  </BulletPoint>
                  <BulletPoint>
                    In connection with a sale, merger or acquisition: If the ownership or control of all or part
                    of our Services changes, we may transfer your information to the new owner.
                  </BulletPoint>
                  <BulletPoint>
                    Third-Party Vendors and Service Providers: We provide information to vendors and service
                    providers who support our business.
                  </BulletPoint>
                  <BulletPoint>
                    Law enforcement or legal requests: We share information when necessary to comply with legal
                    obligations or prevent harm.
                  </BulletPoint>
                </div>
              }
            />

            <PolicySection
              title="How Your Information is Stored"
              content={
                <div className="space-y-4">
                  <p>We retain your Personal Information where we have an ongoing legitimate business need to do so.
                  When we have no ongoing legitimate business need to process your Personal Information, we will either
                  delete or anonymize it.</p>
                  <p>We endeavor to protect the privacy of your account and other Personal Information we hold in our records.
                  We take certain measures to protect your Personal Information, including using security measures to monitor
                  data security events occurring on our systems.</p>
                </div>
              }
            />

            <PolicySection
              title="Your Rights"
              content={
                <div className="space-y-4">
                  <p>Market.Organic would like to make sure you are fully aware of all of your data protection rights under
                  EU 2016/679 (GDPR) and the California Consumer Privacy Act (CCPA). Every user of our Services is entitled
                  to the following:</p>
                  <BulletPoint>
                    The right to access – You have the right to request copies of your personal data.
                  </BulletPoint>
                  <BulletPoint>
                    The right to rectification – You have the right to request correction of any inaccurate information.
                  </BulletPoint>
                  <BulletPoint>
                    The right to erasure – You have the right to request erasure of your personal data, under certain conditions.
                  </BulletPoint>
                  <BulletPoint>
                    The right to restrict processing – You have the right to request restriction of processing of your personal data.
                  </BulletPoint>
                  <BulletPoint>
                    The right to data portability – You have the right to request the transfer of your data to another organization.
                  </BulletPoint>
                  <BulletPoint>
                    The right to object – You have the right to object to our processing of your personal data.
                  </BulletPoint>
                  <BulletPoint>
                    The right to withdraw consent – You have the right to withdraw your consent at any time.
                  </BulletPoint>
                  <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
                  please contact us at our email: <a href="mailto:contact@market.organic" className="text-blue-400 hover:text-blue-600">contact@market.organic</a>.</p>
                </div>
              }
            />

            <PolicySection
              title="International Users"
              content={
                <p>By accessing the Market.Organic Services, you allow us to use and disclose data we collect as described
                in this Policy and our Terms, transmit it outside your resident jurisdiction, and store it on servers in the
                United States. We ensure appropriate safeguards are in place to protect your data and comply with applicable
                data protection laws when transferring your personal data internationally.</p>
              }
            />

            <PolicySection
              title="Updates to the Policy"
              content={
                <p>From time to time, we may need to update this Policy to accurately reflect our Services. When this occurs,
                we will post the updated Policy on our website and indicate the date of revision. We encourage you to check
                this page periodically for updates. Your use of the Services after we post the updated Policy will constitute
                your acceptance of the changes.</p>
              }
            />

<PolicySection
              title="California Consumer Privacy Act Disclosure"
              content={
                <div className="space-y-4">
                  <p>This California Consumer Privacy Act Disclosure is for California residents and supplements our Data and Privacy Policy.
                  It explains how to exercise your rights under the California Consumer Privacy Act (the "CCPA") for the Personal Information
                  which Market.Organic collects.</p>
                  <p>Categories of Personal Information Collected and Disclosed:</p>
                  <BulletPoint>
                    Identifiers such as your name, address, phone numbers, or IP address
                  </BulletPoint>
                  <BulletPoint>
                    Personal information, such as a credit card number
                  </BulletPoint>
                  <BulletPoint>
                    Commercial information, such as purchase activity
                  </BulletPoint>
                  <BulletPoint>
                    Internet or other electronic network activity information
                  </BulletPoint>
                  <p>Your Rights Under the CCPA:</p>
                  <BulletPoint>
                    Right to know about personal information collected, disclosed, or sold
                  </BulletPoint>
                  <BulletPoint>
                    Right to request deletion of personal information
                  </BulletPoint>
                  <BulletPoint>
                    Right to opt-out of the sale of personal information (Note: We do not sell personal information)
                  </BulletPoint>
                  <BulletPoint>
                    Right to non-discrimination for exercising your CCPA rights
                  </BulletPoint>
                  <p>To exercise these rights, please contact us at <a href="mailto:contact@market.organic" className="text-blue-400 hover:text-blue-600">contact@market.organic</a>. You may designate an authorized
                  agent to make a request on your behalf. The agent must provide proof of your written permission, and we
                  may require you to verify your identity directly with us.</p>
                </div>
              }
            />

            <div className="mt-12 space-y-4">
              <p>
                For more detailed information about our privacy practices or if you have any questions,
                please contact us at <a href="mailto:contact@market.organic" className="text-blue-400 hover:text-blue-600">contact@market.organic</a>.
              </p>

              <p>
                Data Protection Officer: Colin Stewart, <a href="mailto:colin@market.organic" className="text-blue-400 hover:text-blue-600">colin@market.organic</a>
              </p>

              <p className="text-primary-400">
                Date of Last Revision: {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export { PrivacyPolicy };