import React, { useEffect } from "react";
import { MainHeader } from "../MainHeader";
import { MainFooter } from "../MainFooter";
import scrollToTop from "../Utils/ScrollToTop";
import { Toaster } from "react-hot-toast";

interface IProps {
  children?: React.ReactNode;
  isHome?: boolean;
}

function ViewContainer({ children, isHome }: IProps) {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <MainHeader isHome={isHome} />
      {children}
      <Toaster
        toastOptions={{
          style: {
            maxWidth: "390px",
          },
        }}
      />
      <MainFooter />
    </>
  );
}

export { ViewContainer };
