import React, { useState } from "react";

import classNames from "classnames";
import { Button, ButtonStyleVariations } from "../Button";
import { Modal, ModalSizes } from "../modals/Modal";
import { CreateOrEditListing, ViewListings } from ".";

import { Operation, useListingsQuery } from "../../graphql/generated-types";
import { Listing } from "../../services/graphql-custom-types";
// import { GET_MERCHANT_CATALOG } from "../../store/SquareMerchant"
// import { mapSquareProductToListing } from "../Utils"

const squareOAuthURL = `https://connect.squareupsandbox.com/oauth2/authorize?client_id=${process.env.SQUARE_APP_ID}&scope=MERCHANT_PROFILE_READ+ITEMS_READ+PAYMENTS_WRITE+PAYMENTS_READ+ORDERS_WRITE+INVENTORY_READ`;

interface IListingsProps {
  className?: string;
  operation: Operation;
  isSquareLinked: boolean;
  userOwnsOperation?: boolean;
  toggleClaimModal?: () => void;
}

function Listings({
  className = "",
  operation,
  isSquareLinked,
  userOwnsOperation,
  toggleClaimModal,
}: IListingsProps) {
  const [nativeListings, setNativeListings] = useState<Listing[]>([]);
  const [squareListings, setSquareListings] = useState<Listing[]>([]);

  useListingsQuery({
    variables: { operationId: operation.id },
    fetchPolicy: "network-only",
    onCompleted: (data) => setNativeListings(data.listings as Listing[]),
  });

  // const { loading: isLoadingSquareCatalog } = useQuery(GET_MERCHANT_CATALOG, {
  //   variables: { operationId: operation.id },
  //   onCompleted: (res) => {
  //     const products = res.squareMerchantCatalog.products
  //     const inventory = res.squareMerchantCatalog.inventory
  //     const listings = products.map((product) => {
  //       return mapSquareProductToListing(operation, product.itemData, inventory)
  //     })

  //     setSquareListings(listings)
  //   },
  // })

  async function obtainAuthToken() {
    localStorage.setItem(
      "square-link-merchant-redirect-operation-id",
      operation.id.toString(),
    );
    open(squareOAuthURL, "_self");
  }

  // const hasListings = nativeListings?.length > 0 || squareListings?.length > 0
  const hasListings = nativeListings?.length > 0;

  // TODO: Check if the Operation is owned by an Organization
  // const hasAnyoneClaimedOperation = operation.organization.users.length > 0;
  const hasAnyoneClaimedOperation = false;
  const isMyOperation = false;

  // let isMyOperation = userOwnsOperation || false;
  // if (!isMyOperation && data) {
  //   isMyOperation = !!operation.users.find(
  //     (user) => user.auth0Id === data.me?.auth0Id,
  //   );
  // }

  const classes = classNames(className, {
    listings: true,
    "listings--has-listings": hasListings,
    "listings--no-listings-cta": !hasListings,
    "listings--claimed": isMyOperation,
  });

  const [showCreateModal, setShowCreateModal] = useState(false);

  const handleToggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const handleCreateListing = (newListing: Listing) => {
    handleToggleCreateModal();
    setNativeListings([...nativeListings, newListing]);
  };

  const renderCreateModal = () => {
    if (!showCreateModal) return null;
    return (
      <Modal
        size={ModalSizes.LARGE}
        className="create-listing"
        handleClose={handleToggleCreateModal}
      >
        <CreateOrEditListing
          operation={operation}
          callback={handleCreateListing}
        />
      </Modal>
    );
  };

  return (
    <section className={classes}>
      <h1 className="interface-text--heading listings-heading">
        Active Listings
      </h1>
      {hasListings ? (
        <>
          {squareListings && squareListings.length > 0 && (
            <ViewListings
              operation={operation}
              isPrivate={isMyOperation}
              listings={squareListings}
            />
          )}
          <ViewListings
            operation={operation}
            isPrivate={isMyOperation}
            listings={nativeListings}
          />
          {isMyOperation && (
            <Button
              name="new-listing"
              onClick={handleToggleCreateModal}
              label="New listing"
              className="listings__cta"
              styleVariation={ButtonStyleVariations.PRIMARY}
            />
          )}
        </>
      ) : isMyOperation ? (
        <>
          <p className="interface-text--body">
            You haven’t posted any listings yet. Let buyers know what’s
            currently available.
          </p>
          <Button
            name="create-listing"
            onClick={handleToggleCreateModal}
            label="Create listing"
            className="listings__cta"
            styleVariation={ButtonStyleVariations.SECONDARY_INVERSE}
          />
        </>
      ) : (
        <>
          {hasAnyoneClaimedOperation && (
            <p className="interface-text--body">
              {operation.name} has not claimed their profile yet
            </p>
          )}
          <p className="interface-text--body">
            If this is your operation, claim your profile to start adding
            listings
          </p>
          <Button
            name="claim-operation-from-listings-cta"
            onClick={toggleClaimModal}
            className="listings__cta"
            styleVariation={ButtonStyleVariations.SECONDARY_INVERSE}
          >
            Claim profile
          </Button>
        </>
      )}

      {/* Hide Square link */}
      {false && isMyOperation && !isSquareLinked && (
        <Button
          name="link-square"
          onClick={obtainAuthToken}
          label="Link Square Account"
          className="listings__cta"
          styleVariation={ButtonStyleVariations.PRIMARY}
        />
      )}

      {renderCreateModal()}
    </section>
  );
}

export { Listings };
