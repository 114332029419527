import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  OperationOwnerView,
  OperationPublicView,
  OperationEditProfileView,
} from ".";
import { Loading } from "../Loading";
import {
  useGetMeQuery,
  useOperationQuery,
  Operation as OperationType,
} from "../../graphql/generated-types";

function Operation({ isEditProfile = false }) {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useOperationQuery({
    variables: { id: parseInt(id ?? '0') },
  });
  const { loading: meLoading, error: meError, data: meData } = useGetMeQuery();
  const location = useLocation();

  if (loading || meLoading) {
    return <Loading isView type="operation" />;
  }

  if (error || meError) {
    return (
      <p className="interface-text--supporting">
        There was an error loading the operation, please try again.
      </p>
    );
  }

  if (!data || !data.operation) {
    return (
      <p className="interface-text--supporting">
        Operation not found.
      </p>
    );
  }

  const { operation } = data;
  const me = meData?.me;

  const userOwnsOperation = me
    ? me.organizations.some(org => org.operations.some(op => op.id === operation.id))
    : false;

  const forcePublic = location.search.includes('showPublic');

  if (isEditProfile && userOwnsOperation && !forcePublic) {
    return (
      <OperationEditProfileView
        operation={operation}
        userOwnsOperation={userOwnsOperation}
      />
    );
  }

  if (userOwnsOperation && !forcePublic) {
    return (
      <OperationOwnerView
        operation={operation as OperationType}
        userOwnsOperation={userOwnsOperation}
      />
    );
  } else {
    return (
      <OperationPublicView
        operation={operation}
        userOwnsOperation={userOwnsOperation}
      />
    );
  }
}

export { Operation };