import { atom } from "recoil";

export const enteredOperationPhoneState = atom<string>({
  key: "enteredOperationPhoneState",
  default: "",
});

export const enteredOperationEmailState = atom<string>({
  key: "enteredOperationEmailState",
  default: "",
});
