import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import classNames from "classnames";

// Config
import Keys from "../../config/Keys";

// Components
import { InputField } from "./InputField";
import { Loading } from "../Loading";
import { useFindOperationsQuery } from "../../graphql/generated-types";

interface IProps {
  selectedOperation: null;
  setSelectedOperation(operation: any): void; // TODO: generate Operation type from GraphQL schema
}

// TODO: Why is submitting the first option with enter keyDown not submitting, while any other option submits as expected?
// Submitting with click still works for any option.

const OperationSearchField = (props: IProps) => {
  const [operationSearch, setOperationSearch] = useState("");
  const [selectedGlobalIndex, setSelectedGlobalIndex] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const { loading, data } = useFindOperationsQuery({
    skip: operationSearch == "",
    variables: { name: operationSearch },
  });

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setShowLoading(true);
      }, 1000); // Delay loading indicator
      return () => clearTimeout(timer);
    }
    setShowLoading(false);
  }, [loading]);

  const handleKeyDown = (e) => {
    const operations = data?.findOperations;
    switch (e.keyCode) {
      case Keys.KEY_CODES.UP: {
        let newIndex;
        if (selectedGlobalIndex === null || selectedGlobalIndex - 1 < 0) {
          newIndex = operations.length - 1;
        } else {
          newIndex = selectedGlobalIndex - 1;
        }
        setSelectedGlobalIndex(newIndex);
        break;
      }
      case Keys.KEY_CODES.DOWN: {
        let newIndex;
        if (
          selectedGlobalIndex === null ||
          selectedGlobalIndex + 1 >= operations.length
        ) {
          newIndex = 0;
        } else {
          newIndex = selectedGlobalIndex + 1;
        }
        setSelectedGlobalIndex(newIndex);
        break;
      }
      case Keys.KEY_CODES.ENTER: {
        if (selectedGlobalIndex !== null) {
          const operation = operations[selectedGlobalIndex];
          if (!operation) return null;
          setOperationSearch(operation.name);
          props.setSelectedOperation(operation);
          e.preventDefault();
        }
        break;
      }
    }
  };

  useEffect(() => {
    window && window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  let globalIndex = -1;

  const delayedLoading = () => {
    setTimeout(() => {
      loading && <Loading />;
    }, 1000);
  };

  return (
    <div className="operation-search-field">
      <InputField
        label="Find your operation"
        value={operationSearch}
        handleChange={(e) => {
          setOperationSearch(e.target.value);
          setSelectedGlobalIndex(null);
        }}
      />
      {showLoading && <Loading />}
      {!props.selectedOperation &&
        data?.findOperations &&
        data?.findOperations.length > 0 && (
          <ul className="search-dropdown">
            {data?.findOperations.map((item) => {
              globalIndex = globalIndex + 1;
              const isSelected = selectedGlobalIndex === globalIndex;
              const menuClasses = classNames({
                "search-dropdown__item": true,
                "interface-text--list--small": true,
                "search-dropdown__item--selected": isSelected,
              });
              return (
                <li
                  className={menuClasses}
                  key={item.id}
                  onClick={() => {
                    setOperationSearch(item.name);
                    props.setSelectedOperation(item);
                  }}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        )}
    </div>
  );
};

export { OperationSearchField };
