import React, { useState, Fragment } from "react";
import classNames from "classnames";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Menu, Transition } from "@headlessui/react";
import { format, parseISO } from 'date-fns';
import notify from "../marketplace/NotifyRenew";
import DialogDelete from "../marketplace/DialogDelete";
import SellingIcon from "../icons/Selling";
import ShareListingButton from './ShareListingButton';
import BuyingIcon from "../icons/Buying";
import Sidebar from "../Sidebar";
import { AccountSkeleton } from "./IsLoggedInWrapper";
import {
  AsksDocument,
  FullListingDocument,
  ListingsDocument,
  useAsksQuery,
  useDeleteAskMutation,
  useDeleteListingMutation,
  useGetMeQuery,
  useListingsQuery,
} from "../../graphql/generated-types";
import { notifyUpdated } from "../Utils/notifyUpdated";

const editOptionsData = ["renew", "delete"];

export const marketplaceSidebarLinks = [
  {
    id: "selling",
    icon: <SellingIcon />,
    route: "/marketplace/list/selling",
    label: "Selling",
  },
  {
    id: "buying",
    icon: <BuyingIcon />,
    route: "/marketplace/list/buying",
    label: "Buying",
  },
];

const Marketplace = () => {
  const { type = "selling" } = useParams<{ type: string }>();
  const validatedType: "buying" | "selling" = type === "buying" ? "buying" : "selling";
  const [openDialogDelete, setOpenDialogDelete] = useState({
    show: false,
    id: null,
    listingType: "",
  });

  const { loading: meLoading, error, data: meData } = useGetMeQuery();

  const organizationId = meData?.me?.organizations?.[0]?.id;
  const operationId = meData?.me?.organizations?.[0]?.operations?.[0]?.id;

  const {
    loading: loadingListing,
    error: errorListing,
    data: listingData,
  } = useListingsQuery({
    skip: !operationId,
    variables: { operationId: operationId },
  });

  const {
    loading: loadingAsk,
    error: errorAsk,
    data: askData,
  } = useAsksQuery({
    skip: !organizationId,
    variables: { organizationId: organizationId },
  });

  const [deleteAsk] = useDeleteAskMutation({
    onCompleted: () => {
      notifyUpdated({ text: "Listing deleted" });
    },
    refetchQueries: [
      {
        query: AsksDocument,
        variables: { organizationId: organizationId },
      },
    ],
  });

  const [deleteListing] = useDeleteListingMutation({
    onCompleted: () => {
      notifyUpdated({ text: "Listing deleted" });
    },
    refetchQueries: [
      {
        query: ListingsDocument,
        variables: { operationId: operationId },
      },
    ],
  });

  const handleCancel = () => {
    setOpenDialogDelete({
      ...openDialogDelete,
      show: false,
    });
  };

  const handleDelete = () => {
    setOpenDialogDelete({
      ...openDialogDelete,
      show: false,
    });

    if (openDialogDelete.listingType === "selling") {
      deleteListing({
        variables: { id: openDialogDelete.id },
      });
    } else {
      deleteAsk({
        variables: { id: openDialogDelete.id },
      });
    }
  };

  const pageTitle =
    marketplaceSidebarLinks?.find((link) => link.id === type)?.label ||
    marketplaceSidebarLinks[0].label;

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'MMM d, yyyy h:mm a'); // Format: "Sep 20, 2024 8:12 PM"
  };

  if (meLoading || loadingListing || loadingAsk) {
    return <AccountSkeleton />;
  }

  const listings =
    type === "selling" ? listingData?.listings || [] : askData?.asks || [];

  return (
    <>
      <div className="pb-20 md:pt-20">
        <div className="container">
          <div className="flex flex-col md:flex-row gap-8 lg:gap-16">
            <aside className="max-md:border-b max-md:-ml-4 max-md:px-4 max-md:w-[calc(100%_+_2rem)] md:w-48 lg:w-64">
              <Sidebar links={marketplaceSidebarLinks} />
            </aside>
            <main className="flex-1">
              <div className="border-b pb-4 flex flex-col md:flex-row justify-between md:items-center gap-2">
                <h1 className="font-semibold">{pageTitle}</h1>
                <div>
                  <Link
                    to={`/marketplace/create`}
                    className="bg-primary-600 hover:bg-primary-700 text-white px-4 pr-6 py-2 rounded-full inline-flex items-center gap-2 font-medium"
                  >
                    <div className="text-white">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 5V10M10 10V15M10 10H15M10 10L5 10"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div>Create Listing</div>
                  </Link>
                </div>
              </div>
              <div>
                {listings.length ? (
                  <div className="grid grid-cols-1 divide-y divide-gray-100">
                    {listings.map((listing) => {
                      const statusClass = classNames({
                        "bg-gray-100 rounded-full inline-block text-xs py-0.5 px-2":
                          true,
                        "bg-slate-200 text-blue-950":
                          listing?.status === "pending",
                        "bg-green-50 text-green-800":
                          listing?.status === "active",
                        "bg-orange-50 text-yellow-600":
                          listing?.status === "expired",
                      });

                      const listingUrl =
                        type === "buying"
                          ? `/asks/${listing.id}`
                          : `/listings/${listing.id}`;

                      return (
                        <div
                          key={listing.id}
                          className="flex gap-4 item-center py-6 justify-between"
                        >
                          <div className="flex gap-4 item-center">
                            <div className="rounded-md flex items-center justify-center group-hover:opacity-75 overflow-hidden bg-gray-200 text-primary-400 w-10 h-10 md:h-[72px] md:w-[72px]">
                              <EmptyImage />
                            </div>
                            <div className="flex flex-col justify-center flex-1">
                              <div className="text-sm mb-2 inline-flex gap-4">
                                <a
                                  href={listingUrl}
                                  className="font-semibold line-clamp-1 md:line-clamp-none"
                                >
                                  {listing?.title}
                                </a>

                                {listing?.status ? (
                                  <div>
                                    <span className={statusClass}>
                                      <FormattedMessage
                                        id={`marketplace.listing.status.${listing.status}`}
                                      />
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                              <div className="text-xs flex gap-1 md:gap-2 text-primary-700">
                                <span>Organic Listings</span>
                                <span>·</span>
                                <span>{formatDate(listing?.createdAt)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-4">
                            <ShareListingButton listingId={listing.id} listingTitle={listing.title} listingType={validatedType} />
                            <a
                              href={`/marketplace/edit?id=${listing.id}&listingType=${type}`}
                              className="hidden md:block text-sm text-primary-700 px-2.5 py-1.5 rounded-full border border-primary-300 shadow-sm font-medium hover:bg-gray-50"
                            >
                              Edit
                            </a>

                            <Menu
                              as="div"
                              className="relative inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="cursor-pointer text-primary-400 hover:text-primary-900 block">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10 3C10.3978 3 10.7794 3.15804 11.0607 3.43934C11.342 3.72064 11.5 4.10218 11.5 4.5C11.5 4.89782 11.342 5.27936 11.0607 5.56066C10.7794 5.84196 10.3978 6 10 6C9.60218 6 9.22064 5.84196 8.93934 5.56066C8.65804 5.27936 8.5 4.89782 8.5 4.5C8.5 4.10218 8.65804 3.72064 8.93934 3.43934C9.22064 3.15804 9.60218 3 10 3ZM10 8.5C10.3978 8.5 10.7794 8.65804 11.0607 8.93934C11.342 9.22064 11.5 9.60218 11.5 10C11.5 10.3978 11.342 10.7794 11.0607 11.0607C10.7794 11.342 10.3978 11.5 10 11.5C9.60218 11.5 9.22064 11.342 8.93934 11.0607C8.65804 10.7794 8.5 10.3978 8.5 10C8.5 9.60218 8.65804 9.22064 8.93934 8.93934C9.22064 8.65804 9.60218 8.5 10 8.5ZM11.5 15.5C11.5 15.1022 11.342 14.7206 11.0607 14.4393C10.7794 14.158 10.3978 14 10 14C9.60218 14 9.22064 14.158 8.93934 14.4393C8.65804 14.7206 8.5 15.1022 8.5 15.5C8.5 15.8978 8.65804 16.2794 8.93934 16.5607C9.22064 16.842 9.60218 17 10 17C10.3978 17 10.7794 16.842 11.0607 16.5607C11.342 16.2794 11.5 15.8978 11.5 15.5Z" />
                                  </svg>
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <div className="block md:hidden">
                                      <Menu.Item>
                                        <a
                                          href={`/marketplace/edit?id=${listing.id}&listingType=${validatedType}`}
                                          className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                                        >
                                          <FormattedMessage
                                            id={`marketplace.listing.menu.edit`}
                                          />
                                        </a>
                                      </Menu.Item>
                                    </div>
                                    {editOptionsData.map((option) => {
                                      const handleClick = () => {
                                        if (option === "renew") {
                                          notify();
                                        } else if (option === "delete") {
                                          setOpenDialogDelete({
                                            show: true,
                                            id: listing.id,
                                            listingType: type,
                                          });
                                        } else {
                                          return null;
                                        }
                                      };

                                      return (
                                        <Menu.Item key={option}>
                                          <button
                                            className="block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left"
                                            onClick={handleClick}
                                          >
                                            <FormattedMessage
                                              id={`marketplace.listing.menu.${option}`}
                                            />
                                          </button>
                                        </Menu.Item>
                                      );
                                    })}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="py-10">
                    You haven't created any listings yet!
                  </div>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
      <DialogDelete
        open={openDialogDelete.show}
        handleCancel={handleCancel}
        handleConfirm={handleDelete}
      />
    </>
  );
};

const EmptyImage = () => {
  return (
    <svg
      width="60"
      height="111"
      viewBox="0 0 60 111"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6 md:w-8 md:h-8"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 46.555L12.8227 35.4826C5.33612 29.6308 0.958008 20.628 0.958008 11.092V0L15.1334 11.0773C22.6248 16.9291 27.0078 25.9318 27.0078 35.4679V46.555Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 46.555L47.1714 35.4826C54.6629 29.6308 59.041 20.628 59.041 11.092V0L44.8656 11.0773C37.3742 16.9291 32.9912 25.9318 32.9912 35.4679V46.555Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 78.7838L12.8227 67.7016C5.33612 61.8547 0.958008 52.8519 0.958008 43.311V32.2239L15.1334 43.3012C22.6248 49.1481 27.0078 58.1557 27.0078 67.6967V78.7838Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 78.7838L47.1714 67.7016C54.6629 61.8547 59.041 52.8519 59.041 43.311V32.2239L44.8656 43.3012C37.3742 49.1481 32.9912 58.1557 32.9912 67.6967V78.7838Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0078 111L12.8227 99.9228C5.33612 94.0808 0.958008 85.0731 0.958008 75.537V64.4451L15.1334 75.5224C22.6248 81.3693 27.0078 90.3769 27.0078 99.913V111Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9912 111L47.1714 99.9228C54.6629 94.0808 59.041 85.0731 59.041 75.537V64.4451L44.8656 75.5224C37.3742 81.3693 32.9912 90.3769 32.9912 99.913V111Z"
      />
    </svg>
  );
};

export { Marketplace };
