import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";

function BuyingIcon(props) {
  return (
    <Icon
      name="account"
      {...props}
      renderComponent={({ color }) => (
        <React.Fragment>
          <path
            d="M16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11M5 9H19L20 21H4L5 9Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="transparent"
          />
        </React.Fragment>
      )}
    />
  );
}

BuyingIcon.propTypes = {
  className: PropTypes.string,
};

BuyingIcon.defaultProps = {
  className: null,
};

export default BuyingIcon;
