import React from "react";
import { Plans } from "../account/Plans";

const StepPlans = () => {
  return (
    <>
      <div className="mb-4 flex flex-col">
        <h1 className="text-lg font-medium text-gray-900">Select a plan</h1>
      </div>
      <Plans />
    </>
  );
};

export default StepPlans;
