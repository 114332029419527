import React from "react";
import InputField from "../marketplace/InputField";

function Contact() {
  return (
    <main>
      <div className="grid grid-cols-1 md:grid-cols-2 max-md:divide-y md:divide-x">
        <div className="flex flex-col gap-10 px-6 md:px-16 py-12 md:py-36">
          <h1 className="font-bold text-primary-900 text-3xl">Get in touch</h1>
          <p className="text-xl">
            Proin volutpat consequat porttitor cras nullam gravida at. Orci
            molestie a eu arcu. Sed ut tincidunt integer elementum id sem. Arcu
            sed malesuada et magna.
          </p>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-400"
              >
                <path
                  d="M1.25 19.5H20.75M2.75 1.5V19.5M13.25 1.5V19.5M19.25 6V19.5M5.75 5.25H6.5M5.75 8.25H6.5M5.75 11.25H6.5M9.5 5.25H10.25M9.5 8.25H10.25M9.5 11.25H10.25M5.75 19.5V16.125C5.75 15.504 6.254 15 6.875 15H9.125C9.746 15 10.25 15.504 10.25 16.125V19.5M2 1.5H14M13.25 6H20M16.25 9.75H16.258V9.758H16.25V9.75ZM16.25 12.75H16.258V12.758H16.25V12.75ZM16.25 15.75H16.258V15.758H16.25V15.75Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>545 Mavis Island Chicago, IL 99191</p>
            </div>
            <div className="flex gap-4">
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-400"
              >
                <path
                  d="M1.25 5.25C1.25 13.534 7.966 20.25 16.25 20.25H18.5C19.0967 20.25 19.669 20.0129 20.091 19.591C20.5129 19.169 20.75 18.5967 20.75 18V16.628C20.75 16.112 20.399 15.662 19.898 15.537L15.475 14.431C15.035 14.321 14.573 14.486 14.302 14.848L13.332 16.141C13.05 16.517 12.563 16.683 12.122 16.521C10.4849 15.9191 8.99815 14.9686 7.76478 13.7352C6.53141 12.5018 5.58087 11.0151 4.979 9.378C4.817 8.937 4.983 8.45 5.359 8.168L6.652 7.198C7.015 6.927 7.179 6.464 7.069 6.025L5.963 1.602C5.90214 1.35869 5.76172 1.1427 5.56405 0.988337C5.36638 0.833971 5.1228 0.750083 4.872 0.75H3.5C2.90326 0.75 2.33097 0.987053 1.90901 1.40901C1.48705 1.83097 1.25 2.40326 1.25 3V5.25Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>+1 (555) 234-5678</p>
            </div>
            <div className="flex gap-4">
              <svg
                width="24"
                height="29"
                viewBox="0 0 24 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-400"
              >
                <path
                  d="M21.75 9.25V19.75C21.75 20.3467 21.5129 20.919 21.091 21.341C20.669 21.7629 20.0967 22 19.5 22H4.5C3.90326 22 3.33097 21.7629 2.90901 21.341C2.48705 20.919 2.25 20.3467 2.25 19.75V9.25M21.75 9.25C21.75 8.65326 21.5129 8.08097 21.091 7.65901C20.669 7.23705 20.0967 7 19.5 7H4.5C3.90326 7 3.33097 7.23705 2.90901 7.65901C2.48705 8.08097 2.25 8.65326 2.25 9.25M21.75 9.25V9.493C21.75 9.87715 21.6517 10.2549 21.4644 10.5903C21.2771 10.9257 21.0071 11.2075 20.68 11.409L13.18 16.024C12.8252 16.2425 12.4167 16.3582 12 16.3582C11.5833 16.3582 11.1748 16.2425 10.82 16.024L3.32 11.41C2.99292 11.2085 2.72287 10.9267 2.53557 10.5913C2.34827 10.2559 2.24996 9.87815 2.25 9.494V9.25"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>hello@example.com</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 px-6 md:px-16 py-12 md:py-36">
          <form>
            <div className="flex flex-col gap-6">
              <div className="flex gap-6">
                <InputField label="First name" />
                <InputField label="Last name" />
              </div>

              <InputField label="Email" />
              <InputField label="Phone number" />
              <InputField label="Message" type="textarea" />
              <div className="flex justify-end">
                <button className="bg-primary-600 hover:bg-primary-700 text-white px-6 py-3 rounded-full font-semibold shadow-sm">
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export { Contact };
